<template>
   <div class="search-name filter-diver hidden-sm" v-bind:class="{ active: openFilter }">
      <div class="container-title" @click="moboSearchClose">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="17" viewBox="0 0 18 17">
          <image width="18" height="17" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAABNklEQVQ4jZXUOUteURSF4cch4ABiE7W2FTsLKzsRsUsjxFZFxEoLBxQRTTSEoJLC6WfY29haKFZ2FhYKIjjF4BDDgS18yOfl3tUc2Bzes/bei1PRsfgoQ58wgC6s4+ijq9UZkFoM4xeqsJ8Fqvyg3oAZrOEF29jNsl7OURPmMYoH/MYcMmfwHtSK7+jHDZaxkgUoB2rHT/TgErPYygMpBXViNc5DTGAvL+QN1B2QtpjDccnaK3Jy7lKOztEchX/4i7oibtJmk6MD9EXhCScBSzl6zQFJrq8TaAQ/8DWCdxwZqs/I2Xv9SaAzjOMeQ+jFaWQpt95evMBUDP0zprFRZFal1q/CxVJsbTBgjUVBSbf4hsmIRorADlqKgpLSxlKLYxGHL1hATVFQUorBZrT3HPfSL1Be+A8Dtj9ZPVYq6AAAAABJRU5ErkJggg=="/>
        </svg>
        <h2 class="hidden-lg"> {{ placeholdersearchname }} </h2>
      </div>
      <div class="container-filter">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22">
          <defs>
            <clipPath id="clip_0">
              <rect x="-1424" y="-28" width="1600" height="3441" clip-rule="evenodd" />
            </clipPath>
          </defs>
          <g clip-path="url(#clip_0)">
            <path fill="rgb(255,255,255)" stroke="none" d="M17.4692 14.5254L21.394 18.4168C22.202 19.2259 22.202 20.5744 21.394 21.3835C20.9707 21.8074 20.432 22 19.8933 22C19.3546 22 18.8159 21.8074 18.3927 21.3835L14.5063 17.4921C13.0442 18.4168 11.3126 18.9562 9.46568 18.9562C4.23262 18.9562 0 14.718 0 9.47811C0 4.23818 4.23262 0 9.46568 0C14.6987 0 18.9314 4.23818 18.9314 9.47811C18.9314 11.3275 18.3927 13.0613 17.4692 14.5254ZM2.22213 9.43958C2.22213 13.4632 5.50023 16.6926 9.46568 16.6926C13.484 16.6926 16.7092 13.4632 16.7092 9.43958C16.7092 5.41598 13.484 2.18651 9.46568 2.18651C5.44736 2.18651 2.22213 5.41598 2.22213 9.43958Z"
              fill-rule="evenodd" />
          </g>
        </svg>
        <input v-model="search" :placeholder="placeholdersearchname" @keyup="buscarNome" />
      </div>
    </div>
</template>
<script>

export default {
  name: 'new-bdays-search-name',
  props: ['placeholdersearchname', 'openFilter'],
  data() {
    return {
      search: '',
      openSearchName: false
    }
  },
  methods: {
    buscarNome() {
      if (this.search.length === 0) {
        this.$emit('pesquisa', this.search)
      }
      if (this.search.length > 3) {
        this.$emit('pesquisa', this.search)
      }
    },
    moboSearchClose() {
      this.openFilter = false
      this.$emit('closeFilter', this.openFilter)
    },
  }
}
</script>