/* eslint-disable */
//https://github.com/halshing/Playground/blob/master/ArrayOfObjectsFilter/Filter.js
Array.prototype.flexFilter = function(info) {
  let matchesFilter, matches = [], count;
  matchesFilter = function(item) {
    count = 0
    for (var n = 0; n < info.length; n++) {
      if (info[n]["Values"].indexOf(item[info[n]["Field"]]) > -1) {
        count++;
      }
    }
    return count == info.length;
  }
  for (var i = 0; i < this.length; i++) {
    if (matchesFilter(this[i])) {
      matches.push(this[i]);
    }
  }
  return matches;
}