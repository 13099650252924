import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/intranet/phones'
  },
  phones(urlBase, keyword) {
    return Axios.get(`${urlBase || this.options.urlBase}?filter=${keyword}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.phones()', error)
    })
  }
}

export default API
