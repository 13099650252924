import Vue from 'vue'
import Vuex from 'vuex'

import tabsStore from './tabs-store'
import profileStore from './profile-store'
import msalStore from './msal-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tabsStore,
    profile: profileStore,
    msalStore
  }
})
