<template>
  <ul>
    <li class="topnav-system__welcome">
      <a class="topnav-system__link" href="/profile">
        <span class="topnav-system__image" :style="{ 'background-image': 'url(' + image + ')' }"></span>
        <span class="topnav-system__text">{{ lochello }} <b>{{ firstname }}</b></span>
      </a>
    </li>
    <li v-for="item in items" :key="item.Codigo">
      <a v-bind:href="item.URL" class="topnav-system__link">
        <span class="topnav-system__image" v-bind:style="{ 'background-image': 'url(' + images + 'launcher/' + item.Imagem.replace('.PNG', '.svg') + ')' }"></span>
        <span class="topnav-system__text">{{ item.Nome }}</span>
        <span class="topnav-system__arrow"><i class="fa fa-chevron-right"></i></span>
      </a>
    </li>
    <li v-if="false">
      <a :href="logout" class="topnav-system__link">
        <span class="topnav-system__image" v-bind:style="{ 'background-image': 'url(' + images + 'launcher/logout.svg)' }"></span>
        <span class="topnav-system__text">{{ loclogout }}</span>
        <span class="topnav-system__arrow"><i class="fa fa-chevron-right"></i></span>
      </a>
    </li>
  </ul>
</template>
<script>
import API from './api'
import { mapGetters } from 'vuex'

export default {
  name: 'launcher',
  props: ['url', 'images', 'firstname', 'pic', 'loclogout', 'logout', 'lochello'],
  data() {
    return {
      items: [],
      image: '/assets/intranet/Images/Profile.svg',
      user: this.$msal.account
    }
  },
  created() {
    this.getMenuItems()
  },
  watch: {
    profile() {
      this.getUserAvatar()
    }
  },
  computed: {
    ...mapGetters('profile', ['profile'])
  },
  methods: {
    getMenuItems() {
      if (!this.items.length) {
        API.launcher(this.url).then(resp => {
          this.items = resp
        })
      }
    },
    getUserAvatar() {
      if (this.user && this.user.userName) {
        const uri = `_api/web/GetFileByServerRelativeUrl('/User%20Photos/Profile Pictures/${this.user.userName.replace(/[|.@]/gi, '_')}_LThumb.jpg')/$value`
        this.$msalAPI.getFileAPI(uri, { encoding: null, responseType: 'arraybuffer' })
          .then(resp => {
            this.image = `data:image/jpeg;base64,${new Buffer(resp.data).toString('base64')}`
          })
          .catch(() => {})
      }
    }
  }
}
</script>