<template>
  <div class="container-fluid gallery__wrapper">
  <div class="container gallery">
    <div v-if="showfilters">
      <div class="row">
        <div class="col filter-title">
          {{ filtertitle }}
        </div>
      </div>
      <div class="row">        
        <div class="col col-lg-4 col-12">
          <input type="text" :placeholder="searchfieldtext" class="filter-control" v-model="filterText">
        </div>
        <div class="col col-lg-2 col-6">
          <datepicker v-model="startDate" :full-month-name="true" :language="language" :placeholder="initialdatetext" input-class="filter-control" @selected="selectStartDate" :disabledDates="disabledStartDates"></datepicker>
        </div>
        <div class="col col-lg-2 col-6">
          <datepicker v-model="endDate" :full-month-name="true" :language="language" :placeholder="enddatetext" input-class="filter-control" @selected="selectEndDate" :disabledDates="disabledEndDates"></datepicker>
        </div>
        <div class="col col-lg-2 col-6">
          <button class="button button-filter" @click="filter()">{{ searchbuttontext }}</button>
        </div>
        <div class="col col-lg-2 col-6">
          <button class="button button-filter" @click="cleanFilters()">{{ cleanbuttontext || 'Limpar' }}</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="gallery__container" v-if="!currentItem">
          <div :class="`gallery__item gallery__${item.TemplateName.replace(' ', '').toLowerCase()}`" v-for="item in items" :key="item.ID" @click="previewItem(item)">
            <div class="gallery__video" v-if="item.TemplateName === 'Movie'">
              <video :src="item.Url" style="pointer-events: none" :poster="item.VideoImg"></video>
              <div class="gallery__content">
                <h2>{{ item.Name }}</h2>
              </div>
            </div>
            <div class="gallery__thumb gallery__album lazyload" v-else-if="item.TemplateName === 'Media folder'" :data-bgset="item.Albums[0].Url">
              <div class="gallery__content">
                <h2>{{ item.Name }}</h2>
              </div>
            </div>
            <div class="gallery__thumb lazyload" v-else :data-bgset="item.Url">
              <div class="gallery__content">
                <h2>{{ item.Name }}</h2>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="gallery__preview">
          <div class="container">
            <div class="row justify-content-center align-items-center">
              <div class="col">
                <h1>{{ currentItem.Name }}</h1>
              </div>
              <div class="col-auto">
                <a @click="closePreview" class="gallery__close"><i class="fa fa-close"></i></a>
              </div>
            </div>
          </div>
          <div class="gallery__area" v-if="currentItem.TemplateName === 'Movie'">
            <video autoplay controls :src="currentItem.Url"></video>
          </div>
          <div class="" v-else-if="currentItem.TemplateName === 'Media folder'">
            <div class="swiper-container gallery__slider">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="album in currentItem.Albums" :key="album.ID">
                  <div class="gallery__area">
                    <v-lazy-image :src="album.Url" />
                  </div>
                  <div class="gallery__content">
                    <h2>{{ album.Name }}</h2>
                  </div>
                </div>
              </div>
              
              <!-- Add Pagination -->
              <div class="swiper-pagination"></div>

              <!-- Add Arrows -->
              <div class="swiper-button-next">
                  <svg width="17px" height="32px" viewBox="0 0 17 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="HOME-3" transform="translate(-1517.000000, -2237.000000)" fill="#FFFFFF">
                        <g id="ANIVERSARIANTES" transform="translate(0.000000, 1950.000000)">
                          <path d="M1531.94735,318.643003 L1517.3456,303.843128 C1516.8848,303.376132 1516.8848,302.624138 1517.3456,302.157142 L1531.94735,287.357267 C1532.41315,286.884271 1533.17283,286.881271 1533.64363,287.346267 C1534.11443,287.812263 1534.11942,288.571256 1533.65462,289.043252 L1519.88552,303.000135 L1533.65462,316.957017 C1534.11942,317.429013 1534.11443,318.188007 1533.64363,318.654003 C1533.40973,318.885001 1533.10586,319 1532.80099,319 C1532.49112,319 1532.18225,318.881001 1531.94735,318.643003 Z"
                            id="Icon" transform="translate(1525.500000, 303.000000) scale(-1, 1) translate(-1525.500000, -303.000000) "></path>
                        </g>
                      </g>
                    </g>
                  </svg>
              </div>
              <div class="swiper-button-prev">
                  <svg width="17px" height="32px" viewBox="0 0 17 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="HOME-3" transform="translate(-66.000000, -2237.000000)" fill="#FFFFFF">
                        <g id="ANIVERSARIANTES" transform="translate(0.000000, 1950.000000)">
                          <path d="M80.9473528,318.643003 L66.3456021,303.843128 C65.8847993,303.376132 65.8847993,302.624138 66.3456021,302.157142 L80.9473528,287.357267 C81.4131535,286.884271 82.1728284,286.881271 82.6436269,287.346267 C83.1144254,287.812263 83.1194232,288.571256 82.6546222,289.043252 L68.8855151,303.000135 L82.6546222,316.957017 C83.1194232,317.429013 83.1144254,318.188007 82.6436269,318.654003 C82.409727,318.885001 82.105857,319 81.8009875,319 C81.4911201,319 81.1822523,318.881001 80.9473528,318.643003 Z"
                            id="Icon"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
              </div>
            </div>
          </div>
          <div class="gallery__area" v-else>
            <v-lazy-image :src="currentItem.Url"/>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-more-itens" v-if="this.nItensAPI > this.nitens && this.nitens != '' && this.nitens != '0' ">
      <slot name="labelBtnMoreGallery"></slot>
    </div>
  </div>
</div>
</template>
<script>
import API from './api'
import Swiper from 'swiper'
import Datepicker from 'vuejs-datepicker'

const moment = require('moment')

const Language = function (language, months, monthsAbbr, days) {
  this.language = language
  this.months = months
  this.monthsAbbr = monthsAbbr
  this.days = days
  this.rtl = false
  this.ymd = false
  this.yearSuffix = ''
}
const pt = new Language(
  'Brazilian',
  ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
)

const enUs = new Language(
  'English',
  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
)

export default {
  name: 'gallery',
  props: [
    'baseurl',
    'datasource',
    'nitens',
    'filtertitle',
    'showfilters',
    'searchfieldtext',
    'initialdatetext',
    'enddatetext',
    'searchbuttontext',
    'cleanbuttontext'
  ],
  data() {
    return {
      items: [],
      isItemsLoading: false,
      currentItem: null,
      nItensAPI: null,
      filterText: null,
      startDate: null,
      endDate: null,
      ptBR: pt,
      en: enUs,
      language: pt,
      disabledStartDates: { },
      disabledEndDates: { }
    }
  },
  mounted() {
    this.doRequest(this.baseurl, this.datasource)
  },
  components: {
    Datepicker
  },
  updated() {
    if (this.currentItem && this.currentItem.TemplateName === 'Media folder') {
      window.gallerySlider = new Swiper('.gallery__slider.swiper-container', {
        initialSlide: 0,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: false,
        autoplay: {
          delay: 5000,
        },
        threshold: 10,
        pagination: {
          el: '.gallery__slider .swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.gallery__slider .swiper-button-next',
          prevEl: '.gallery__slider .swiper-button-prev',
        }
      })

      window.gallerySlider.el.addEventListener('mouseenter', function () {
        window.gallerySlider.autoplay.stop()
      }, false)

      window.gallerySlider.el.addEventListener('mouseleave', function () {
        window.gallerySlider.autoplay.start()
      }, false)
    }
  },
  methods: {
    previewItem(item) {
      document.querySelector('html').classList.add('gallery__open')
      this.currentItem = item
    },
    closePreview() {
      document.querySelector('html').classList.remove('gallery__open')
      this.currentItem = null
    },
    filter() {
      const initialDate = moment(this.startDate).format('YYYY-MM-DDTHH:mm:ss')
      const endDate = moment(this.endDate).format('YYYY-MM-DDTHH:mm:ss')
      this.doRequest(this.baseurl, this.datasource, this.filterText, initialDate, endDate)
    },
    cleanFilters() {
      this.filterText = null
      this.startDate = null
      this.endDate = null
      this.doRequest(this.baseurl, this.datasource)
    },
    selectStartDate(date) {
      this.$set(this.disabledEndDates, 'to', new Date(date))
    },
    selectEndDate(date) {
      this.$set(this.disabledStartDates, 'from', new Date(date))
    },
    doRequest(baseurl, datasource, filtertext, initialdate, enddate) {
      this.isItemsLoading = true
      API.gallery(baseurl, datasource, filtertext, initialdate, enddate).then(resp => {
        this.isItemsLoading = false
        this.items = this.nitens > 0 ? resp.slice(0, this.nitens) : resp
        this.nItensAPI = resp.length
      })
    }
  }
}
</script>