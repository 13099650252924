import Bdays from './bdays/bdays.vue'
import NewBdays from './new-bdays/new-bdays.vue'
import Polls from './poll/poll.vue'
import News from './news/news.vue'
import Events from './events/events.vue'
import TopNavMenu from './topnav-menu/topnav-menu.vue'
import TopNavNavigationGroup from './topnav-menu/topnav-navigation-group.vue'
import TopNavAnchor from './topnav-menu/topnav-anchor.vue'
import PhoneSearch from './phone-search/phone-search.vue'
import Directory from './directory/directory.vue'
import Launcher from './launcher/launcher.vue'
import UserButton from './userbutton/userbutton.vue'
import Profile from './profile/profile.vue'
import Clippings from './clippings/clippings.vue'
import Search from './search/search.vue'
import Gallery from './gallery/gallery.vue'
import Jobs from './jobs/jobs.vue'
import Wall from './wall/wall.vue'
import PeopleEvents from './peopleevents/peopleevents.vue'
import Classifieds from './classifieds/classifieds.vue'
import LikeButton from './like-button/like-button.vue'
import Pesquisa from './pesquisa/pesquisa.vue'
import FormInput from './form-input/form-input.vue'
import FormCheckbox from './form-checkbox/form-checkbox.vue'
import FormRadio from './form-radio/form-radio.vue'
import FormSubmit from './form-submit/form-submit.vue'
import Textarea from './form-textarea/form-textarea.vue'
import VTooltip from 'v-tooltip'
import TalkToPresident from './talk-to-president/talk-to-president.vue'
import DirectConnection from './direct-connection/direct-connection.vue'
import LostAndFound from './lost-and-found/lost-and-found.vue'

export default [
  Bdays,
  NewBdays,
  Polls,
  News,
  Events,
  TopNavMenu,
  TopNavNavigationGroup,
  TopNavAnchor,
  PhoneSearch,
  Directory,
  Launcher,
  UserButton,
  Profile,
  Clippings,
  Search,
  Gallery,
  Jobs,
  LikeButton,
  Pesquisa,
  FormInput,
  FormCheckbox,
  FormRadio,
  FormSubmit,
  Textarea,
  Classifieds,
  Wall,
  PeopleEvents,
  TalkToPresident,
  DirectConnection,
  LostAndFound,
  VTooltip,
]
