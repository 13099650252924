import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/lostandfound'
  },
  get(urlBase, canEdit, id, title, description, type, ocurrenceDate, approved, author) {
    return Axios.get(`${urlBase || this.options.urlBase}/get?canEdit=${canEdit}&id=${id || ''}&title=${title || ''}&description=${description || ''}&type=${type || ''}&ocurrenceDate=${ocurrenceDate || ''}&approved=${approved === undefined ? '' : approved}&author=${author || ''}`)
      .then(response => {
        return response.data
      }).catch((error) => {
        console.error('API.lostAndFound.get()', error)
      })
  },
  add(urlBase, formdata) {
    return Axios.post(`${urlBase || this.options.urlBase}/add`, formdata).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.lostAndFound.add()', error)
    })
  },
  delete(urlBase, id, pageId) {
    return Axios.delete(`${urlBase || this.options.urlBase}/delete?id=${id}&pageId=${pageId}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.lostAndFound.delete()', error)
    })
  },
  approve(urlBase, id, pageId) {
    return Axios.put(`${urlBase || this.options.urlBase}/approve?id=${id}&pageId=${pageId}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.lostAndFound.approve()', error)
    })
  }
}

export default API
