function toggleItem(el, toShow) {
  if (toShow) {
    el.classList.add('active')
  } else {
    el.classList.remove('active')
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector('.topnav [rel="favorites"]')

  if (!element) return

  element.addEventListener('click', (evt) => {
    const { currentTarget } = evt
    toggleItem(currentTarget, !currentTarget.classList.contains('active'))
  })

  element.addEventListener('mouseenter', (evt) => {
    const { currentTarget } = evt
    toggleItem(currentTarget, true)
  })

  element.addEventListener('mouseleave', (evt) => {
    const { currentTarget } = evt
    toggleItem(currentTarget, false)
  })
})
