<template>
  <div class="carousel-birthdays-item">
    <div class="carousel-birthdays-item__profile">
      <div class="carousel-birthdays-item__photo">
        <div
          class="carousel-birthdays-item__date"
          v-if="Mes == bMes + 1 && Dia === bDia"
        >
          <div class="carousel-birthdays-item__day">
            <v-lazy-image src="/assets/intranet/Images/cake.svg" />
          </div>
          <div class="carousel-birthdays-item__month">
            {{ Today }}
          </div>
        </div>
        <div class="carousel-birthdays-item__date" v-else>
          <div class="carousel-birthdays-item__day">{{ Dia }}</div>
          <div class="carousel-birthdays-item__month">
            {{ $utils.months(lang || 'en')[Mes - 1].substr(0, 3) }}
          </div>
        </div>
        <v-lazy-image
          :src="picture || Image || '/assets/intranet/Images/Profile.svg'"
        />
      </div>
      <div class="carousel-birthdays-item__info">
        <div>
          <a v-if="UsuarioAD" :href="'./profile?id=' + UsuarioAD"
            ><b>{{ Nome }}</b></a
          >
          <b v-else>{{ Nome }}</b>
        </div>
        <div class="carousel-birthdays-item__secondary">{{ Area }}</div>
        <div class="carousel-birthdays-item__secondary">{{ Empresa }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'new-bdays-item',
    props: [
      'Mes',
      'Today',
      'Imagem',
      'Dia',
      'Nome',
      'Area',
      'Empresa',
      'UsuarioAD',
      'lang',
    ],
    data() {
      return {
        bDia: '',
        bMes: '',
        pictureUrl: null,
        pictureRef: null,
      }
    },
    created() {
      const date = new Date()
      this.bMes = date.getMonth()
      this.bDia = date.getDate()
    },
    methods: {
      getProfilePicture(user) {
        this.pictureRef = user
        this.$msalAPI
          .getFileAPI(
            `_api/web/GetFileByServerRelativeUrl('/User%20Photos/Profile Pictures/${user.replace(
              /\./gi,
              '_'
            )}_${this.$msal.account.userName
              .split('@')[1]
              .replace(/\./gi, '_')}_LThumb.jpg')/$value`,
            { encoding: null, responseType: 'arraybuffer' }
          )
          .then((result) => {
            const image = `data:image/jpeg;base64,${new Buffer(
              result.data
            ).toString('base64')}`
            this.pictureUrl = image
          })
          .catch(() => {
            this.pictureUrl = null
          })
      },
    },
    computed: {
      picture() {
        if (this.UsuarioAD) {
          if (this.UsuarioAD !== this.pictureRef) {
            this.getProfilePicture(this.UsuarioAD)
          }
          return this.pictureUrl
        }
        return null
      },
    },
  }
</script>
