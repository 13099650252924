<template>

<div class="container jobs__wrapper">

  <div class="row">
    <div class="col jobs">
      <div :class="currentId ? 'jobs__detail' : 'jobs__container'">
        <div class="job" v-for="item in items.filter(item => !currentId || item.ID === currentId)" :key="item.ID">
          <div v-if="currentId" class="jobs__title">{{ item.Name }}</div>
          <a v-else class="jobs__title" href="#" @click.prevent="goToJob(item.ID)">{{ item.Name }}</a>
          <div v-html="item.Summary" class="jobs__summary"></div>
          <div v-html="item.Description" v-if="currentId" class="jobs__content"></div>
          <div class="jobs__bar">
           <div v-if="item.Internal && item.External" class="jobs__external">
              <i class="fa fa-info-circle"></i> {{ locexternalandinternal }}
            </div>
            <div v-else class="jobs__external">
              <i class="fa fa-info-circle"></i> {{ item.External ? locexternal : locinternal }}
            </div>
            <div v-if="item.Link && item.Link.Url" class="jobs__website">
              <i class="fa fa-globe"></i> <a :href="item.Link.Url" target="_blank">{{ item.Link.Title || 'Website' }}</a>
            </div>
          </div>
          <div class="btn-back"><a href="#" class="button button--default" @click="goBack()">voltar</a></div>
          <div v-if="cantidatar">
            <form method="post" enctype="multipart/form-data" novalidate v-if="!applied">
              <h2>{{ locapply }}<span v-if="invalidFile" class="invalid">*</span></h2>
              <div>
                <input type="file" ref="upload" name="files" :disabled="isSaving" class="input-file" />
              </div>
              <div class="jobs__actions">
                <a class="button" href="#" @click.prevent="applyToJob(item.ID, item.Name)" >{{ locapply }}</a>
              </div>
            </form>
            <div class="applied" v-else>
              <i class="fa fa-check-circle"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>
<script>
import API from './api'
import Masonry from 'masonry-layout'

export default {
  name: 'jobs',
  props: {
    baseurl: {
      type: String
    },
    locexternal: {
      type: String
    },
    locinternal: {
      type: String
    },
    locexternalandinternal: {
      type: String
    },
    locapply: {
      type: String
    },
    cantidatar: {
      type: Boolean,
      require: 'true'
    }
  },
  data() {
    return {
      items: [],
      isItemsLoading: false,
      currentId: this.$route.query ? this.$route.query.id : null,
      applied: false,
      invalidFile: false
    }
  },
  mounted() {
    this.isItemsLoading = true
    API.jobs(this.baseurl, this.datasource).then(resp => {
      this.isItemsLoading = false
      this.items = resp
    })
  },
  watch: {
    $route(to) {
      this.currentId = to.query ? to.query.id : null
    }
  },
  updated() {
    // masonry
    if (this.currentId && (!this.$route.query || !this.$route.query.id)) {
      this.currentId = null
    }

    if (document.querySelector('.jobs') !== null && document.querySelectorAll('.job').length > 1) {
      window.jobsBoards = new Masonry('.jobs__container', { })
    }
    else if (window.jobsBoards) {
      window.jobsBoards.destroy()
    }
  },

  methods: {
    goToJob(id) {
      window.scrollTo(0, 0)
      this.currentId = id
      this.$router.push({ query: { id } })
    },
    goBack() {
      window.history.back()
    },
    applyToJob(id, title) {
      const formData = new FormData()
      const file = this.$refs.upload[0].files[0]

      if (!file) {
        this.invalidFile = true
        return
      }

      this.invalidFile = false
      formData.append('file', file, file.name)
      API.apply(this.baseurl, formData, id, title)
      this.applied = true
      // window.location = 'mailto:apply@cbmm.com?subject=' + name
    }
  }
}

</script>