import Axios from 'axios'

const API = {
  options: {
    url: 'https://api.myjson.com/bins/10ck7c'
  },
  launcher(url) {
    return Axios.get(url || this.options.url).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.launcher()', error)
    })
  }
}

export default API
