import Axios from 'axios'

const API = {
  options: {
    urlPostBase: '/assets/intranet/api/sitecore/intranet/directconnection/'
  },
  send(urlPostBase, messageChannel, message, file, username, badge, CPF, bdDay, bdMonth, bdYear) {
    const formData = new FormData()
    formData.append('messageChannelId', messageChannel)
    formData.append('message', message)
    formData.append('usernameSender', username)
    formData.append('file', file)
    formData.append('kioskAuthBadge', badge)
    formData.append('kioskAuthCPF', CPF)
    formData.append('kioskAuthBirthdateDay', bdDay)
    formData.append('kioskAuthBirthdateMonth', bdMonth)
    formData.append('kioskAuthBirthdateYear', bdYear)

    return Axios.post(`${urlPostBase || this.options.urlPostBase}/send`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      return { Success: true }
    }).catch((error) => {
      console.error('API.send()', error)
      return { Success: false, Message: error.response.statusText }
    })
  },
  checkValidatedBadge(urlPostBase, badge) {
    const formData = new FormData()
    formData.append('badge', badge)

    return Axios.post(`${urlPostBase || this.options.urlPostBase}/checkvalidatedbadge`, formData).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.checkValidatedBadge()', error)
    })
  },
  checkAuthenticationBadge(urlPostBase, badge, CPF, day, month, year) {
    const formData = new FormData()
    formData.append('badge', badge)
    formData.append('CPF', CPF)
    formData.append('day', day)
    formData.append('month', month)
    formData.append('year', year)

    return Axios.post(`${urlPostBase || this.options.urlPostBase}/checkauthenticationbadge`, formData).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.checkAuthenticationBadge()', error)
    })
  }
}

export default API
