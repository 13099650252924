<template>
  <component
    :is="elTag"
    class="topnav-menu__anchor"
    :class="{
      'topnav-menu__anchor--expandable': children.length > 0,
      'topnav-menu__anchor--no-link': !url,
    }"
  >
    <div
      class="topnav-menu__anchor--wrapper"
      :class="{ 'topnav-menu__anchor--expanded': expandSubmenu }"
      @click.stop="!url && toggleSubmenu()"
    >
      <span class="topnav-menu__anchor--title">
        <a href="#" @click.prevent="favorite(id, $event)" v-if="url">
          <star-icon :filled="isFavorite" />
        </a>
        <component :href="url" :target="target" :is="url ? 'a' : 'span'">{{
          title
        }}</component>
        <small v-if="isNew" class="topnav-menu__new">New</small>
      </span>
      <i class="topnav-menu__anchor--chevron fa fa-chevron-right"></i>
    </div>
    <div
      class="topnav-menu__submenu--wrapper"
      :class="{ 'topnav-menu__submenu--expanded': expandSubmenu }"
      v-if="children.length > 0"
    >
      <topnav-navigation-group
        class="topnav-menu__submenu"
        :items="children"
        :favorite-fn="favoriteFn"
        ref="submenu"
      />
    </div>
  </component>
</template>

<script>
  export default {
    name: 'topnav-anchor',
    props: {
      elTag: { type: String, default: 'div' },
      url: { type: String },
      id: { type: String, required: true },
      target: { type: String },
      title: { type: String },
      isFavorite: { type: Boolean, default: false },
      isNew: { type: Boolean, default: false },
      children: { type: Array, default: () => [] },
      favoriteFn: { type: Function },
    },
    data() {
      return {
        expandSubmenu: false,
      }
    },
    methods: {
      favorite(id, evt) {
        this.$emit('favorite', id, evt)
        if (this.favoriteFn) this.favoriteFn(id, evt)
      },
      toggleSubmenu(value) {
        const newValue = value !== undefined ? value : !this.expandSubmenu
        if (newValue) this.$emit('open', this.id)
        else if (this.$refs.submenu) this.$refs.submenu.closeAll()
        this.expandSubmenu = newValue
      },
    },
  }
</script>
