import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/intranet/classifieds'
  },
  classifieds(urlBase, pageId) {
    return Axios.get(`${urlBase || this.options.urlBase}?pageId=${pageId}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.classifieds()', error)
    })
  },
  add(urlBase, formdata) {
    return Axios.post(`${urlBase || this.options.urlBase}/add`, formdata).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.classifieds.add()', error)
    })
  },
  delete(urlBase, id, pageId) {
    return Axios.get(`${urlBase || this.options.urlBase}/delete?id=${id}&pageId=${pageId}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.classifieds.delete()', error)
    })
  },
  approve(urlBase, id, pageId) {
    return Axios.get(`${urlBase || this.options.urlBase}/approve?id=${id}&pageId=${pageId}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.classifieds.approve()', error)
    })
  }
}

export default API
