import Util from '../utils/utils'

/**
 * Generates the "$validators" getters for each group
 *
 * @param {Object} context
 * @param {Object} groups
 * @returns {Object}
 */
function setValidatorsGetters(context, groups) {
  Util.forEach(groups, (validators, name) => {
    if (!Util.get(context.$validators, name)) {
      Util.set(context.$validators, name, {})
    }

    Util.forEach(validators, (validator, key) => {
      Object.defineProperty(Util.get(context.$validators, name), key, {
        enumerable: true,
        get() {
          return validator(Util.get(context, name))
        }
      })
    })
  })
}

/**
 * Generates the "$valid" getters for each group
 *
 * @param {Object} context
 * @param {Object} groups
 * @returns {Object}
 */
function setValidGetters(context, groups) {
  Util.forEach(groups, (group, name) => {
    const names = name.split('.')
    const key = names.pop()
    const target = names.join('.')

    if (!Util.get(context.$valid, target)) {
      Util.set(context.$valid, target, {})
    }

    Object.defineProperty(Util.get(context.$valid, target), key, {
      enumerable: true,
      get() {
        return Util.reduce(group, (state, validator) => {
          return state ? validator(Util.get(context, name)) : false
        }, true)
      }
    })
  })
}

export default {
  install(Vue) {
    Vue.mixin({
      created() {
        this.initGroupProperties()
      },
      methods: {
        initGroupProperties(groups) {
          Object.assign(this, {
            $dirty: {},
            $valid: {},
            $validators: {}
          })

          if (!groups) {
            groups = this.$options.validators
          } else {
            groups = Object.assign({}, groups, this.$options.validators)
          }

          if (!groups) {
            return
          }

          // Initialize the properties of "$dirty".
          Object.keys(groups).forEach(name => Util.set(this.$dirty, name, false))

          // Initialize the getters of "$validators".
          setValidatorsGetters(this, groups)

          // Initialize the getters of "$valid".
          setValidGetters(this, groups)

          // Observes and verifies whether the model property has been modified.
          Object.keys(groups).forEach((name) => {
            const unwatch = this.$watch(name, () => {
              Util.set(this.$dirty, name, true)
              unwatch()
            })
          })
        }
      }
    })
  }
}
