/* eslint-disable */
import axios from 'axios'

// const deleteAllCookies = () => {
//   var cookies = document.cookie.split(";");

//   for (var i = 0; i < cookies.length; i++) {
//       var cookie = cookies[i];
//       var eqPos = cookie.indexOf("=");
//       var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
//       document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
//   }
// }

// axios.interceptors.response.use(
//   response => response,
//   error => {
//     const { status } = error.response

//     if (status === 401 && window.$msal.isAuthenticated()) {
//       deleteAllCookies()

//       for (var i = 0; i < localStorage.length; i++){
//         if (window.localStorage.key(i).indexOf('msal') != -1) {
//           window.localStorage.removeItem(window.localStorage.key(i))
//         }
//       }

//       window.$msal.acquireToken({
//         scopes: [`${window.adalInfo.mysite}/.default`]
//       }).then((response) => {
//         window.localStorage.setItem('msal-token', response.accessToken)
        
//         setTimeout(() => {
//           window.location.reload()
//         }, 0);
//       })
//     }
//     return Promise.reject(error)
//   }
// );

const msalAPI = {
  state: {
    endPointAPI: window.adalInfo.mysite,
    defaultHeaders: {
      'Content-Type': 'application/json;odata=minimalmetadata;streaming=true;charset=utf-8',
      'Accept': 'application/json;odata=verbose',
      'Authorization': null
    }
  },
  getFileAPI(endPoint) {
    return new Promise((resolve, reject) => {
      let customHeaders = this.state.defaultHeaders
      let interval = null

      const doRequest = () => {
        let storageToken = window.localStorage.getItem('msal-token')
        if (storageToken && storageToken !== 'undefined') {
          customHeaders.Authorization = `Bearer ${storageToken}`
          axios.get(`${this.state.endPointAPI}/${endPoint}`, {
            encoding: null,
            responseType: 'arraybuffer',
            headers: customHeaders
          }).then((data) => {
            resolve(data)
          }).then((data) => {
            reject(data)
          }).catch((error) => console.log('getFileAPI error: ', error))
          clearInterval(interval)
        }
      }
      interval = setInterval(() => {
        doRequest()
      }, 5000);
    });
  },
  getDataAPI(endPoint, headers) {
    return new Promise((resolve, reject) => {
      let customHeaders = this.state.defaultHeaders

      if (headers) { customHeaders = headers }
      let interval = null;      

      const doRequest = () => {
        let storageToken = window.localStorage.getItem('msal-token')
        if (storageToken && storageToken !== 'undefined') {
          customHeaders.Authorization = `Bearer ${storageToken}`
          axios.get(`${this.state.endPointAPI}/${endPoint}`, {
            headers: customHeaders
          }).then((data) => {
            resolve(data)
          }).then((data) => {
            reject(data)
          }).catch((error) => console.log('getDataAPI error: ', error))
          clearInterval(interval)
        }
      }
      interval = setInterval(() => {
          doRequest();
      }, 5000);
    });
  },
  postFileAPI(endPoint, data, headers) {
    return new Promise((resolve, reject) => {
      let customHeaders = this.state.defaultHeaders

      if (headers) { customHeaders = headers }

      customHeaders.Authorization = `Bearer ${window.localStorage.getItem('msal-token')}`

      axios.post(`${this.state.endPointAPI}/${endPoint}`, data, { headers: customHeaders }).then((data) => {
        resolve(data)
      }).then((data) => {
        reject(data)
      })
    });
  }
}

export default msalAPI
