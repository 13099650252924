<template>
  
  <div>

    <div v-for="(item, i) in items" :key="item.Question" v-if="!isItemsLoading && items.length > 0">
      
      <h3>{{ item.Question }}</h3>

      <div v-if="!showResults && !item.UserHasVoted">

          <div class="poll__items">

            <div class="poll__item" v-for="(answer, j) in item.AllAnswers" :key="`answer_${i}_${j}`">
              <input :disabled="loginRequired" type="radio" :id="`opt_${i}_${j}`" :name="'poll' + i" :data-index="j" />
              <label :for="`opt_${i}_${j}`">{{ answer.Text }}</label>
            </div>

            <br /> 
            
          <button v-if="!loginRequired" class="button" @click="vote" :data-id="item.Id" :data-poll="'poll' + i">{{ locbutton }}</button>
          <button v-if="loginRequired" class="button" @click="login" :data-id="item.Id" :data-poll="'poll' + i">{{ loclogin }}</button>

        </div>

      </div>

      <div class="poll__results" v-else>

        <div class="poll__result" v-for="(answer, j) in item.AllAnswers" :key="`answer_${i}_${j}`">
            {{ answer.Text }}
          <span class="poll__bar" :style="`width: ${item.TotalVotes === 0 ? 0 : ((answer.Votes / item.TotalVotes) * 100).toFixed(2)}%`"></span>
          <div class="poll__item">
            <span class="poll__votes">{{ item.TotalVotes === 0 ? 0 : ((answer.Votes / item.TotalVotes) * 100).toFixed(2) }}% ({{ answer.Votes }} {{ locvote }}{{ answer.Votes !== 1 ? "s" : "" }})</span>
          </div>
        </div>

      </div>

      <hr class="line" v-if="i < (items.length - 1)" />

    </div>
    
    <div v-if="!isItemsLoading && items.length === 0">
      {{ empty }}
    </div>
    
    <div v-if="isItemsLoading">
      <div class="loading loading--invert"><div></div><div></div><div></div></div>
    </div>

</div>

</template>
<script>
import API from './api'

export default {
  name: 'poll',
  props: ['baseurl', 'locvote', 'locbutton', 'loclogin', 'container', 'empty', 'account'],
  data() {
    return {
      isItemsLoading: true,
      items: [],
      showResults: false,
      loginRequired: false
    }
  },
  mounted() {
    if (this.$msal && this.$msal.getAccount()) {
      this.loginRequired = this.$msal.account.userName.toLowerCase().includes(this.account.toLowerCase())

      API.poll(this.baseurl, this.container).then(resp => {
        this.isItemsLoading = false
        this.items = resp.map(r => {
          r.TotalVotes = r.AllAnswers.map(x => x.Votes).reduce((sum, x) => sum + x)
          return r
        })
      })
    } else {
      this.isItemsLoading = false
    }
  },
  methods: {
    vote(el) {
      const id = el.target.getAttribute('data-id')
      const poll = el.target.getAttribute('data-poll')

      if (!document.querySelectorAll(`[name=${poll}]:checked`).length) {
        return
      }

      const index = document.querySelectorAll(`[name=${poll}]:checked`)[0].getAttribute('data-index')
      API.vote(this.baseurl, id, index).then(resp => {
        this.items = resp.map(r => {
          r.TotalVotes = r.AllAnswers.map(x => x.Votes).reduce((sum, x) => sum + x)
          return r
        })
      })
    },
    login() {
      localStorage.removeItem('msal.image')
      this.$msal.signOut()
      // window.location.href = '/intranet/logout'
    }
  }
}

</script>