import Axios from 'axios'
import 'url-search-params-polyfill'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/Header'
  },
  menu(urlBase, token) {
    return Axios.get(`${urlBase || this.options.urlBase}/GetMenu?token=${token}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.menu()', error)
    })
  },
  favorite(urlBase, id, token) {
    return Axios.get(`${urlBase || this.options.urlBase}/Favorite?id=${id}&token=${token}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.favorite()', error)
    })
  }
}

export default API
