/* eslint-disable */
export function required(value) {
  if (!value) {
    return false
  }
  return !!value.trim()
}

export function emptyArray(arr) {
  return arr.length
}

export function number(value) {
  return (/^[0-9\-\(\)\/\s]*$/g).test(value)
}

export function email(value) {
  return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
}

export function cpf(strCPF) {
  if (strCPF.length < 11) {
    return false
  }

  if (strCPF === '00000000000' || strCPF === '11111111111') {
    return false
  }

  let Soma = 0
  let Resto
  for (let i = 1; i <= 9; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) {
    Resto = 0
  }
  if (Resto !== parseInt(strCPF.substring(9, 10))) {
    return false
  }

  Soma = 0
  for (let i = 1; i <= 10; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) {
    Resto = 0
  }
  if (Resto !== parseInt(strCPF.substring(10, 11))) {
    return false
  }
  return true
}

export function cnpj(value) {
  if (!value || value.length < 14) {
    return false
  }

  value = value.replace(/[^\d]+/g, '')
  if (value === '00000000000000' ||
      value === '11111111111111' ||
      value === '22222222222222' ||
      value === '33333333333333' ||
      value === '44444444444444' ||
      value === '55555555555555' ||
      value === '66666666666666' ||
      value === '77777777777777' ||
      value === '88888888888888' ||
      value === '99999999999999') {
    return false
  }

  let tamanho = value.length - 2
  let numeros = value.substring(0, tamanho)
  const digitos = value.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== parseInt(digitos.charAt(0))) {
    return false
  }

  tamanho += 1
  numeros = value.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== parseInt(digitos.charAt(1))) {
    return false
  }
  return true
}

export function brZip(value) {
  return (/\d{8}/).test(value)
}

export function brDate(value) {
  const regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
  const date = new Date(value.split('/')[2], value.split('/')[1] - 1, value.split('/')[0])
  return (regex.test(value)) && (date < new Date())
}

export function phone(value) {
  return value.length >= 10
}

export default {
  required,
  emptyArray,
  number,
  email,
  cpf,
  cnpj,
  brDate,
  phone,
  brZip
}
