export default {
  namespaced: true,
  state: {
    token: null
  },
  mutations: {
    changeToken(state, payload) {
      state.token = payload
    }
  },
  actions: {
    changeToken({ commit }, payload) {
      commit('changeToken', payload)
    }
  },
  getters: {
    token: state => state.token
  }
}
