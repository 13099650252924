import Axios from 'axios'

const API = {
  options: {
    url: '/assets/intranet/api/sitecore/Birthdays/GetBirthdays'
  },
  bdays(url) {
    return Axios.get(url || this.options.url).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.bdays()', error)
    })
  }
}

export default API
