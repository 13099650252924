import forEach from 'lodash/forEach'

function onClickElement() {
  if (this.classList.contains('disabled')) {
    event.preventDefault()
    return
  }

  this.classList.add('disabled')
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('[data-preventclick]')

  if (!elements) return

  forEach(elements, (element) => {
    element.addEventListener('click', onClickElement)
  })
})
