<template>
<div class="container new-bdays-container" :id="name">
  <div class="container alingCenter">
    <div class="loading loading--invert" v-if="isLoader"> <div></div><div></div><div></div> </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-3 bg-color">
      <div class="container-filters">
        <div class="row container-filter-mobo">
          <div class="col-5 item-menu">
            <div @click="moboSearchOpen" v-bind:class="{ active: openSearchName }">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
                <path class="cls-1" d="M12.707,12.293s0.688,1.312.357,1.643c-0.373.373-1.771-.229-1.771-0.229L8.651,11.065a3.306,3.306,0,0,0-1.292-.418c-0.147.053-.282,0.124-0.436,0.165a5.56,5.56,0,1,1,3.1-2.224A1.319,1.319,0,0,0,10.1,9.682ZM5.5,2A3.5,3.5,0,1,0,9,5.5,3.5,3.5,0,0,0,5.5,2Z"/>
              </svg>
              {{ placeholdersearchname }}
            </div>
          </div>
          <div class="col-4 item-menu">
            <div @click="moboOrdernarOpen" v-bind:class="{ active: openOrdernar }">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
                <path id="Shape_1_copy" data-name="Shape 1 copy" class="cls-1" d="M7.27,7.646L8.01,8.354,5.24,11H3.76L0.99,8.354l0.74-.707L3.989,9.8C4,9.814,4.009,1.538,4.019,1H5c0.01,0.544-.016,8.831-0.007,8.821Z"/>
                <path id="Shape_1_copy_2" data-name="Shape 1 copy 2" class="cls-1" d="M8.73,4.354L7.99,3.646,10.76,1h1.48l2.77,2.646-0.74.707L12.011,2.2c-0.01-.01-0.02,8.267-0.03,8.8H11c-0.01-.544.017-8.831,0.007-8.821Z"/>
              </svg>

              <slot name="title-mobile-ordenar"></slot>
            </div>
          </div>
          <div class="col-3 item-menu">
            <div @click="moboFiltrarOpen" class="last-filter" v-bind:class="{ active: openFiltrar }">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12"> 
                <rect id="Rectangle_1_copy_5" data-name="Rectangle 1 copy 5" class="cls-1" y="1.844" width="8.938" height="1.031"/>
                <rect id="Rectangle_1_copy_5-2" data-name="Rectangle 1 copy 5" class="cls-1" x="12.625" y="1.844" width="2.375" height="1.031"/>
                <path id="Ellipse_1_copy_4" data-name="Ellipse 1 copy 4" class="cls-2" d="M10.781,0A2.359,2.359,0,1,1,8.406,2.359,2.367,2.367,0,0,1,10.781,0Zm0,1.063a1.3,1.3,0,1,1-1.313,1.3A1.3,1.3,0,0,1,10.781,1.063Z"/>
                <rect id="Rectangle_1_copy_5-3" data-name="Rectangle 1 copy 5" class="cls-1" y="9.125" width="2.625" height="1.031"/>
                <rect id="Rectangle_1_copy_5-4" data-name="Rectangle 1 copy 5" class="cls-1" x="6.313" y="9.125" width="8.688" height="1.031"/>
                <path id="Ellipse_1_copy_4-2" data-name="Ellipse 1 copy 4" class="cls-2" d="M4.469,7.281A2.359,2.359,0,1,1,2.094,9.641,2.367,2.367,0,0,1,4.469,7.281Zm0,1.063a1.3,1.3,0,1,1-1.312,1.3A1.3,1.3,0,0,1,4.469,8.344Z"/>
              </svg>
              <slot name="title-mobile-filtrar"></slot>
            </div>
          </div>

          <new-bdays-filter-mirror
          :peopleResult="groupPeopleResult"
          :isDesktop="isDesktop"
          @filterMirror="addRemoveItem($event.field, $event.valueSelect, $event.paramValue) ">
          </new-bdays-filter-mirror>
        </div>
      <new-bdays-search-name
        :placeholdersearchname="placeholdersearchname"
        :openFilter="openSearchName"
        @pesquisa="search = $event"
        @closeFilter="openSearchName = $event"
      />
      <!-- SEARCH FOR ORGANIZING -->
      <new-bdays-organizing-result 
        :openFilter="openOrdernar"
        :isDesktop="isDesktop"
        @closeFilter="openOrdernar = $event"
        @organizing="ordenField = $event.field, ordenaAZ = $event.az">
          <template slot="title-mobo"><slot name="title-order-result-mobo"></slot></template>
          <template slot="title"><slot name="title-order-result"></slot></template>
          <template slot="select-order-default"><slot name="select-order-result_select"></slot></template>
          <template slot="select-order-nameaz"><slot name="select-order-result_nameaz"></slot></template>
          <template slot="select-order-departament"><slot name="select-order-result_departament"></slot></template>
          <template slot="select-order-company"><slot name="select-order-result_company"></slot></template>
        </new-bdays-organizing-result>

      <div class="container-full-filter" v-if="isDesktop">
        <!-- SEARCH FOR MONTH -->
        <new-bdays-month
          :month="month"
          :isDesktop="isDesktop"
          :lang="lang"
          @searchMonth="addRemoveItem($event.field, $event.valueSelect) ">
          <template slot="title-month"><slot name="title-filter-month"></slot></template>
        </new-bdays-month>

        <!-- SEARCH FOR DEPARTAMENT -->
        <new-bdays-departament
          :Area="groupsListaAreas"
          :openFilter="moboFiltrarOpen"
          :showmore="showmore"
          :hidemore="hidemore"
          :isDesktop="isDesktop"
          @searchDepartament="addRemoveItem($event.field, $event.valueSelect)"
          >
          <template slot="title-departament"><slot name="title-filter-departament"></slot></template>
        </new-bdays-departament>

        <!-- SEARCH FOR COMPANY -->
        <new-bdays-company
          :Empresa="groupsListaEmpresas"
          :openFiltrar="moboFiltrarOpen"
          :isDesktop="isDesktop"
          @searchCompany="addRemoveItem($event.field, $event.valueSelect)"
          >
          <template slot="title-company"><slot name="title-filter-company"></slot></template>
        </new-bdays-company>
      </div>

      <!-- Label filtrar mobile -->
      <div class="container-title hidden-sm hidden-lg" v-bind:class="{ active: openFiltrar}" @click="moboFiltrarOpen">
        <svg class="hidden-lg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="17" viewBox="0 0 18 17">
          <image width="18" height="17" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAABNklEQVQ4jZXUOUteURSF4cch4ABiE7W2FTsLKzsRsUsjxFZFxEoLBxQRTTSEoJLC6WfY29haKFZ2FhYKIjjF4BDDgS18yOfl3tUc2Bzes/bei1PRsfgoQ58wgC6s4+ijq9UZkFoM4xeqsJ8Fqvyg3oAZrOEF29jNsl7OURPmMYoH/MYcMmfwHtSK7+jHDZaxkgUoB2rHT/TgErPYygMpBXViNc5DTGAvL+QN1B2QtpjDccnaK3Jy7lKOztEchX/4i7oibtJmk6MD9EXhCScBSzl6zQFJrq8TaAQ/8DWCdxwZqs/I2Xv9SaAzjOMeQ+jFaWQpt95evMBUDP0zprFRZFal1q/CxVJsbTBgjUVBSbf4hsmIRorADlqKgpLSxlKLYxGHL1hATVFQUorBZrT3HPfSL1Be+A8Dtj9ZPVYq6AAAAABJRU5ErkJggg=="/>
        </svg>
        <h2 class="hidden-lg"><slot name="title-mobile-filtrar_v2"></slot></h2>
      </div>
      <div class="container-full-filter-mobo" v-if="!isDesktop">
        <ul class="hidden-sm hidden-lg js-container-filter" v-bind:class="{ active: openFiltrar }">
          <li @click="moboFilterOpen" class="js-li-filter">
            <div class="title _month"><slot name="title-filter-month_mobo"></slot></div>
            <!-- SEARCH FOR MONTH -->
            <new-bdays-month
              :month="month"
              :isDesktop="isDesktop"
              :lang="lang"
              @searchMonth="addRemoveItem($event.field, $event.valueSelect) ">
            </new-bdays-month>
          </li>
          <li @click="moboFilterOpen" class="js-li-filter">
            <div class="title _departament"><slot name="title-filter-departament_mobo"></slot></div>
            <!-- SEARCH FOR DEPARTAMENT -->
            <new-bdays-departament
              :Area="groupsListaAreas"
              :showmore="showmore"
              :hidemore="hidemore"
              :isDesktop="isDesktop"
              @searchDepartament="addRemoveItem($event.field, $event.valueSelect)"
              >
            </new-bdays-departament>
          </li>
          <li @click="moboFilterOpen" class="js-li-filter">
            <div class="title _company"><slot name="title-filter-company_mobo"></slot></div>
            <!-- SEARCH FOR COMPANY -->
            <new-bdays-company
              :Empresa="groupsListaEmpresas"
              :isDesktop="isDesktop"
              @searchCompany="addRemoveItem($event.field, $event.valueSelect)"
              >
              <template slot="title-company"><slot name="title-filter-company"></slot></template>
            </new-bdays-company>
          </li>
        </ul>
      </div>
    </div>
    </div>
    <div class="col-12 col-lg-9">
      <new-bdays-item 
        v-for="(item, index) in filterNames"
        v-bind:key="index"
        :Mes="item.Mes"
        :Dia="item.Dia"
        :Nome="item.Nome"
        :Area="item.Area"
        :Empresa="item.Empresa"
        :Today="today"
        :UsuarioAD="item.UsuarioAD"
        :lang="lang"
      />
    </div>
  </div>
</div>
</template>
<script>
import './bdays-filter'
import orderBy from 'lodash/orderBy'
import API from './api'
import SearchName from './new-bdays-search-name.vue'
import OrganizingResult from './new-bdays-organizing-result.vue'
import FilterMonth from './new-bdays-month.vue'
import FilterDepartament from './new-bdays-departament.vue'
import FilterCompany from './new-bdays-company.vue'
import birthdayPerson from './new-bdays-item.vue'
import mirrorFilter from './new-bdays-filter-mirror.vue'
import filters from '../../../services/filters'

export default {
  name: 'new-bdays',
  components: {
    'new-bdays-search-name': SearchName,
    'new-bdays-organizing-result': OrganizingResult,
    'new-bdays-month': FilterMonth,
    'new-bdays-departament': FilterDepartament,
    'new-bdays-company': FilterCompany,
    'new-bdays-item': birthdayPerson,
    'new-bdays-filter-mirror': mirrorFilter
  },
  props: ['url', 'today', 'name', 'lang', 'month', 'placeholdersearchname', 'showmore', 'hidemore'],
  data() {
    return {
      search: '',
      ordenaAZ: '',
      ordenField: '',
      isLoader: false,
      openSearchName: false,
      openOrdernar: false,
      openFiltrar: false,
      isDesktop: window.innerWidth >= 992,
      groupsPeople: [],
      groupsListaAreas: [],
      groupsListaEmpresas: [],
      groupPeopleResult: []
    }
  },
  beforeMount() {
    const checkAuth = setInterval(() => {
      if (this.$msal && this.$msal.data) {
        this.getItems()
        clearInterval(checkAuth)
      }
    }, 100)

    this.groupPeopleResult = [{ Field: 'Mes', Values: [this.month] }]
  },
  methods: {
    getItems() {
      this.isLoader = true
      const accessToken = this.$msal.data.accessToken
      API.newbdays(this.url, accessToken).then(resp => {
        this.groupsPeople = resp.ListPeople.filter(filters.excludedBirthdaysFilter)
        this.groupsListaAreas = resp.ListaAreas
        this.groupsListaEmpresas = resp.ListaEmpresas
        this.isLoader = false
      })
    },
    /* eslint-disable */
    moboSearchOpen() {
      this.openSearchName === true ? this.openSearchName = false : this.openSearchName = true
      this.openOrdernar = false
      this.openFiltrar = false
    },
    moboOrdernarOpen() {
      this.openOrdernar === true ? this.openOrdernar = false : this.openOrdernar = true
      this.openSearchName = false
      this.openFiltrar = false
    },
    moboFiltrarOpen() {
      this.openFiltrar === true ? this.openFiltrar = false : this.openFiltrar = true
      this.openSearchName = false
      this.openOrdernar = false
    },
    moboFilterOpen() {
      event.target.closest('li').classList.toggle('active')
    },
    /* eslint-enable */
    addRemoveItem(paramField, paramValueSelect, mes) {
      const valueSelect = paramValueSelect
      const containerFilterResult = document.body.querySelectorAll('.js-itemlistfilter')
      const contansObjIndex = this.groupPeopleResult.findIndex(obj => obj.Field === paramField)
      let valueSelectStyle = paramValueSelect
      if (containerFilterResult) {
        if (mes) {
          valueSelectStyle = mes
          containerFilterResult.forEach(item => {
            if (item.innerText === valueSelectStyle) {
              item.classList.remove('select')
            }
          })
        } else {
          containerFilterResult.forEach(item => {
            for (const div of item.children) {
              if (div.innerText === valueSelectStyle) {
                div.classList.remove('select')
              }
            }
          })
        }
      }
      if (contansObjIndex === -1) {
        this.groupPeopleResult.push({ Field: paramField, Values: [] })
      }
      const fieldGroup = this.groupPeopleResult.find(item => item.Field === paramField)
      if (fieldGroup.Values.includes(valueSelect)) {
        const index = fieldGroup.Values.indexOf(valueSelect)
        if (index > -1) {
          fieldGroup.Values.splice(index, 1)
          event.target.classList.remove('select')
          if (fieldGroup.Values.length === 0) {
            this.groupPeopleResult.splice(contansObjIndex, 1)
          }
        }
        return
      }
      fieldGroup.Values.push(valueSelect)
      event.target.classList.add('select')
    }
  },
  computed: {
    filterNames() {
      return this.filterCriterios.filter((item) => {
        return item.Nome.match(this.search.toUpperCase())
      })
    },
    filterCriterios() {
      return orderBy(this.groupsPeople.flexFilter(this.groupPeopleResult), [this.ordenField], [this.ordenaAZ])
    }
  }
}
</script>