/* eslint-disable */

/**
 * Loads a given script asynchronously.
 * 
 * @param {String} url 
 * @param {Function} callback 
 */
function getScript(url, callback) {
  const existing = document.getElementsByTagName('script')[0]
  const target = document.createElement('script')

  target.src = url

  if (callback) {
    target.addEventListener('load', (event) => callback(null, event), false)
  }

  existing.parentNode.insertBefore(target, existing)
}

/**
 * Tells whether a particular element is visible in the viewport or not.
 * 
 * @param {Element} element 
 * @returns {Boolean}
 */
function inViewport(element) {
  const bounding = element.getBoundingClientRect()

  return bounding.top <= (window.innerHeight || document.documentElement.clientHeight)
}

/**
 * Create and return as pre-configured Yammer options.
 * 
 * @param {Element} container 
 * @return {Object}
 */
function getYammerOptions(container) {
  return {
    container: '#yammer-feed',
    network: container.getAttribute('data-network') || 'valtech.com',
    feedType: container.getAttribute('data-type') || '',
    feedId: container.getAttribute('data-group') ? parseInt(container.getAttribute('data-group'), 10) : '',
    config: {
      use_sso: true,
      header: false,
      footer: false,
      showOpenGraphPreview: false,
      defaultToCanonical: false,
      hideNetworkName: true,
      theme: 'light',
      defaultGroupId: container.getAttribute('data-group') ? parseInt(container.getAttribute('data-group'), 10) : '',
      promptText: container.getAttribute('data-prompt') || ''
    },
    objectProperties: container.getAttribute('data-preview') ? {
      type: 'page',
      fetch: true,
      private: false,
      ignore_canonical_url: true
    } : {}
  }
}

/**
 * Initialize the Yammer component.
 * 
 * @param {Element} container 
 */
function setYammer(container) {
  if (window.yam.inViewport || !inViewport(container)) {
    return
  }

  window.yam.inViewport = true

  window.yam.connect.embedFeed(getYammerOptions(container))
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector('#yammer-feed')

  if (!container) {
    return
  }

  getScript('https://c64.assets-yammer.com/assets/platform_embed.js', () => {
    const wrapper = document.querySelector('.wrapper')

    window.addEventListener('load', () => setYammer(container))
    window.addEventListener('resize', () => setYammer(container))

    if (wrapper) {
      wrapper.addEventListener('scroll', () => setYammer(container))
    }
  })
})
