import Axios from 'axios'
import 'url-search-params-polyfill'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/News'
  },
  news(urlBase, type, category, page) {
    const qs = new URLSearchParams(window.location.search)
    const typeFallback = qs.has('type') ? `type=${qs.get('type')}` : ''
    const filter = [
      type ? `type=${type}` : typeFallback,
      category ? `category=${category}` : '',
      page && page > 0 ? `page=${page}` : '',
      qs.has('pageSize') ? `pageSize=${qs.get('pageSize')}` : '',
    ].filter(p => p).join('&')
    return Axios.get(`${urlBase || this.options.urlBase}/GetNews?${filter}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.news()', error)
    })
  }
}

export default API
