<template>
  <button class="scroll-to-top" 
    :class="{'scroll-to-top__active': isActive, 'aLeft': pleft == true }" 
    @click="onClick" 
    :style="{ top: `${x}px`, left: `${y}px` }">
  </button>
</template>

<script>
let timeout = null
export default {
  name: 'ScrollToTop',
  props: {
    ShowIn: {
      default: 200,
      type: Number
    },
    pleft: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      isActive: false,
      ref: null,
      x: 0,
      y: 0
    }
  },
  created() {
    if (this.execute()) {
      this.activate()
    }
  },
  mounted() {
  },
  methods: {
    execute() {
      window.addEventListener('scroll', this.activate.bind(this), { passive: false })
      window.addEventListener('resize', this.activate.bind(this), { passive: false })
      const wrapper = document.getElementsByClassName('wrapper')[0]
      if (wrapper !== undefined) {
        wrapper.addEventListener('scroll', this.activate.bind(this), { passive: false })
      }
      return true
    },
    activate() {
      const ref = document.getElementById('vue-container')
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        this.x = window.innerHeight - 90
        this.y = window.innerWidth - 90
        this.isActive = ref.getBoundingClientRect().top < -this.ShowIn
      }, 100)
    },
    onClick(event) {
      event.preventDefault()
      this.isActive = false
      const wrapper = document.getElementsByClassName('wrapper')[0]
      if (wrapper !== undefined) {
        wrapper.scrollTop = 0
      } else {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>
