/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable one-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-cond-assign */
/* eslint-disable no-underscore-dangle */

// Todo/Work in Progress fix this for image optimization work
// import { lazySizes, lazySizesConfig } from 'lazysizes'


(function () {
  /* istanbul ignore next */
  if (!window.addEventListener) {
    return
  }

  const regWhite = /\s+/g
  const regSplitSet = /\s*\|\s+|\s+\|\s*/g
  const regSource = /^(.+?)(?:\s+\[\s*(.+?)\s*\])?$/
  const allowedBackgroundSize = {
    contain: 1,
    cover: 1
  }
  const proxyWidth = function (elem) {
    /* istanbul ignore next */
    const width = lazySizes.gW(elem, elem.parentNode)

    if (!elem._lazysizesWidth || width > elem._lazysizesWidth) {
      elem._lazysizesWidth = width
    }
    return elem._lazysizesWidth
  }
  const getBgSize = function (elem) {
    /* istanbul ignore next */
    let bgSize

    bgSize = (getComputedStyle(elem) || {
      getPropertyValue() {}
    }).getPropertyValue('background-size')

    if (!allowedBackgroundSize[bgSize] && allowedBackgroundSize[elem.style.backgroundSize]) {
      bgSize = elem.style.backgroundSize
    }

    return bgSize
  }
  const createPicture = function (sets, elem, img) {
    /* istanbul ignore next */
    const picture = document.createElement('picture')
    let sizes = elem.getAttribute(lazySizesConfig.sizesAttr)
    const ratio = elem.getAttribute('data-ratio')
    const optimumx = elem.getAttribute('data-optimumx')

    if (elem._lazybgset && elem._lazybgset.parentNode === elem) {
      elem.removeChild(elem._lazybgset)
    }

    Object.defineProperty(img, '_lazybgset', {
      value: elem,
      writable: true
    })
    Object.defineProperty(elem, '_lazybgset', {
      value: picture,
      writable: true
    })

    sets = sets.replace(regWhite, ' ').split(regSplitSet)

    picture.style.display = 'none'
    img.className = lazySizesConfig.lazyClass

    if (sets.length === 1 && !sizes) {
      sizes = 'auto'
    }

    sets.forEach((set) => {
      const source = document.createElement('source')

      if (sizes && sizes !== 'auto') {
        source.setAttribute('sizes', sizes)
      }

      if (set.match(regSource)) {
        source.setAttribute(lazySizesConfig.srcsetAttr, RegExp.$1)
        if (RegExp.$2) {
          source.setAttribute('media', lazySizesConfig.customMedia[RegExp.$2] || RegExp.$2)
        }
      }
      picture.appendChild(source)
    })

    if (sizes) {
      img.setAttribute(lazySizesConfig.sizesAttr, sizes)
      elem.removeAttribute(lazySizesConfig.sizesAttr)
      elem.removeAttribute('sizes')
    }
    if (optimumx) {
      img.setAttribute('data-optimumx', optimumx)
    }
    if (ratio) {
      img.setAttribute('data-ratio', ratio)
    }

    picture.appendChild(img)

    elem.appendChild(picture)
  }

  const proxyLoad = function (e) {
    /* istanbul ignore next */
    if (!e.target._lazybgset) {
      return
    }

    const image = e.target
    const elem = image._lazybgset
    const bg = image.currentSrc || image.src

    if (bg) {
      elem.style.backgroundImage = `url(${bg})`
    }

    if (image._lazybgsetLoading) {
      lazySizes.fire(elem, '_lazyloaded', {}, false, true)
      delete image._lazybgsetLoading
    }
  }

  addEventListener('lazybeforeunveil', (e) => {
    /* istanbul ignore next */
    var set,
      image,
      elem

    if (e.defaultPrevented || !(set = e.target.getAttribute('data-bgset'))) {
      return
    }

    elem = e.target
    image = document.createElement('img')

    image.alt = ''

    image._lazybgsetLoading = true
    e.detail.firesLoad = true

    createPicture(set, elem, image)

    setTimeout(() => {
      lazySizes.loader.unveil(image)

      lazySizes.rAF(() => {
        lazySizes.fire(image, '_lazyloaded', {}, true, true)
        if (image.complete) {
          proxyLoad({
            target: image
          })
        }
      })
    })
  })

  document.addEventListener('load', proxyLoad, true)

  window.addEventListener('lazybeforesizes', (e) => {
    if (e.target._lazybgset && e.detail.dataAttr) {
      const elem = e.target._lazybgset
      const bgSize = getBgSize(elem)

      if (allowedBackgroundSize[bgSize]) {
        e.target._lazysizesParentFit = bgSize

        lazySizes.rAF(() => {
          e.target.setAttribute('data-parent-fit', bgSize)
          if (e.target._lazysizesParentFit) {
            delete e.target._lazysizesParentFit
          }
        })
      }
    }
  }, true)

  document.documentElement.addEventListener('lazybeforesizes', (e) => {
    if (e.defaultPrevented || !e.target._lazybgset) {
      return
    }
    e.detail.width = proxyWidth(e.target._lazybgset)
  })
}())
