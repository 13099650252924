<template>
<div>
   <div v-if="isDesktop" class="filter-company filter-diver hidden-sm">
     <slot name="title-company"></slot>
        <ul>
            <li v-for="(itemEmpresa, index) in Empresa" :key="index">
            <div @click="addRemoveItem('Empresa', itemEmpresa.Empresa)">{{itemEmpresa.Empresa}}</div>
            </li>
        </ul>
    </div>
    <div v-else class="filter-company filter-diver">
        <ul>
            <li class="js-itemlistfilter" v-for="(itemEmpresa, index) in Empresa" :key="index">
            <div @click="addRemoveItem('Empresa', itemEmpresa.Empresa)">{{itemEmpresa.Empresa}}</div>
            </li>
        </ul>
    </div>
</div>
</template>
<script>

export default {
  name: 'new-bdays-company',
  props: ['Empresa', 'isDesktop'],
  data() {
    return {
      // ...
    }
  },
  methods: {
    addRemoveItem(paramField, paramValueSelect) {
      this.$emit('searchCompany', {
        field: paramField,
        valueSelect: paramValueSelect
      })
    }
  }
}
</script>