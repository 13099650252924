<template>
  <div :class="[this.groupclass]">
    <div v-for="option in options" class="radio-component" :key="option.value">
      <label :aria-label="ariaLabel">
        <input
        type="radio"
        :id="name"
        :name="name"
        :value="option.value"
        @change="onChange()"
        v-model="groupmodel"
        :aria-labelledby="ariaLabel">
        <slot name="input-box">
          <span class="input-box">
            <span class="input-box-circle"></span>
          </span>
        </slot>
        <span class="label" :aria-label="ariaLabel">{{option.label}}</span>
      </label>
    </div>
    <span class="error" :class="{'show': !valid}">
      {{ errorText }}
    </span>
  </div>
</template>

<script>
import Util from '../../../utils/utils'

export default {
  name: 'form-radio',

  props: {
    name: {
      type: String,
      default: null,
    },
    options: {
      type: Array
    },
    group: {
      type: String,
      default: ''
    },
    errorText: {
      type: String,
      default: ''
    },
    groupclass: {
      type: String,
      default: ''
    },
    value: {
      type: String
    },
    validation: {
      type: Object
    },
    validate: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      groupmodel: this.value
    }
  },

  methods: {
    onChange() {
      this.$parent.$emit('radio-change', {
        name: this.name,
        value: this.groupmodel,
        group: this.group
      })
    }
  },

  computed: {
    ariaLabel() {
      return this.name.trim()
    },
    valid() {
      if (!this.validation) {
        return true
      }

      if (this.validate) {
        return Util.get(this.validation.$valid, this.name)
      }

      if (Util.get(this.validation.$dirty, this.name)) {
        return Util.get(this.validation.$valid, this.name)
      }

      return true
    }
  }

}
</script>