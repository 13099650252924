export const mutations = {
  setCurrentTabItem(state, itemId) {
    state.currentTabItem = itemId
  },

  setTab(state, action) {
    state.currentTabItem = action
  }
}

export const getters = {
  tabExpand: state => itemId => state.currentTabItem === itemId,
  getTabId: state => state.currentTabItem,
  currentTabItem: state => state.currentTabItem
}

export default {
  state: {
    currentTabItem: 1
  },
  mutations,
  getters
}
