import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/intranet/wall'
  },
  wall(urlBase, pageId, pagesize, page) {
    return Axios.get(`${urlBase || this.options.urlBase}?pageId=${pageId}&pagesize=${pagesize}&page=${page}`).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.wall()', error)
    })
  },
  add(urlBase, formdata) {
    return Axios.post(`${urlBase || this.options.urlBase}/add`, formdata).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.wall.add()', error)
    })
  },
  delete(urlBase, id, pageId) {
    return Axios.get(`${urlBase || this.options.urlBase}/delete?id=${id}&pageId=${pageId}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.wall.delete()', error)
    })
  },
  approve(urlBase, id, pageId) {
    return Axios.get(`${urlBase || this.options.urlBase}/approve?id=${id}&pageId=${pageId}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.wall.approve()', error)
    })
  }
}

export default API
