export default {
  namespaced: true,
  state: {
    image: localStorage.getItem('msal.image') ? localStorage.getItem('msal.image') : '/assets/intranet/Images/Profile.svg',
    givename: null,
    fullname: null,
    profile: null
  },
  mutations: {
    changename(state, payload) {
      state.givename = payload.split(' ')[0]
      state.fullname = payload
    },
    changeimage(state, payload) {
      state.image = payload
      localStorage.setItem('msal.image', payload)
    },
    changeprofile(state, payload) {
      state.profile = payload
    }
  },
  actions: {
    changename({ commit }, payload) {
      commit('changename', payload)
    },
    changeimage({ commit }, payload) {
      commit('changeimage', payload)
    },
    changeprofile({ commit }, payload) {
      commit('changeprofile', payload)
    }
  },
  getters: {
    theimage: state => state.image,
    shortname: state => state.givename,
    fullname: state => state.fullname,
    profile: state => state.profile
  }
}
