<template>
   <div class="organizing-result filter-diver hidden-sm" v-bind:class="{ active: openFilter }">
        <div class="container-title" @click="moboOrdernarOpen">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="17" viewBox="0 0 18 17">
                <image width="18" height="17" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAABNklEQVQ4jZXUOUteURSF4cch4ABiE7W2FTsLKzsRsUsjxFZFxEoLBxQRTTSEoJLC6WfY29haKFZ2FhYKIjjF4BDDgS18yOfl3tUc2Bzes/bei1PRsfgoQ58wgC6s4+ijq9UZkFoM4xeqsJ8Fqvyg3oAZrOEF29jNsl7OURPmMYoH/MYcMmfwHtSK7+jHDZaxkgUoB2rHT/TgErPYygMpBXViNc5DTGAvL+QN1B2QtpjDccnaK3Jy7lKOztEchX/4i7oibtJmk6MD9EXhCScBSzl6zQFJrq8TaAQ/8DWCdxwZqs/I2Xv9SaAzjOMeQ+jFaWQpt95evMBUDP0zprFRZFal1q/CxVJsbTBgjUVBSbf4hsmIRorADlqKgpLSxlKLYxGHL1hATVFQUorBZrT3HPfSL1Be+A8Dtj9ZPVYq6AAAAABJRU5ErkJggg=="/>
            </svg>
            <h2 class="hidden-lg"><slot name="title-mobo"></slot> </h2>
        </div>

        <h4 v-if="isDesktop"><slot name="title"></slot></h4>
        <select @change="changeFilter" class="hidden-sm" v-if="isDesktop">
            <option value="" selected disabled><slot name="select-order-default"></slot></option>
            <option value="02"><slot name="select-order-nameaz"></slot></option>
            <option value="03"><slot name="select-order-departament"></slot></option>
            <option value="04"><slot name="select-order-company"></slot></option>
        </select>
        <ul class="hidden-lg js-container-organizing" v-else>
            <li @click="organizingFilter" title="02"><slot name="select-order-nameaz"></slot></li>
            <li @click="organizingFilter" title="03"><slot name="select-order-departament"></slot></li>
            <li @click="organizingFilter" title="04"><slot name="select-order-company"></slot></li>
        </ul>
    </div>
</template>
<script>

export default {
  name: 'new-bdays-organizing-result',
  props: ['openFilter', 'isDesktop'],
  data() {
    return {
      ordenField: '',
      ordenaAZ: ''

    }
  },
  methods: {
    moboOrdernarOpen() {
      this.openFilter = false
      this.$emit('closeFilter', this.openFilter)
    },
    organizingFilter() {
      this.changeFilter(event.target.title)
      const organizingContainer = document.body.querySelector('.js-container-organizing')
      const organizingContainerLi = organizingContainer.querySelectorAll('li')
      organizingContainerLi.forEach((element) => {
        element.classList.remove('active')
      })
      event.target.classList.add('active')
    },
    changeFilter(eventTitle) {
      switch (event.target.value || eventTitle) {
        case '01':
          this.ordenField = 'Dia'
          this.ordenaAZ = 'asc'
          this.$emit('organizing', {
            field: this.ordenField,
            az: this.ordenaAZ
          })
          break
        case '02':
          this.ordenField = 'Nome'
          this.ordenaAZ = 'asc'
          this.$emit('organizing', {
            field: this.ordenField,
            az: this.ordenaAZ
          })
          break
        case '03':
          this.ordenField = 'Area'
          this.ordenaAZ = 'asc'
          this.$emit('organizing', {
            field: this.ordenField,
            az: this.ordenaAZ
          })
          break
        default:
          this.ordenField = 'Empresa'
          this.ordenaAZ = 'asc'
          this.$emit('organizing', {
            field: this.ordenField,
            az: this.ordenaAZ
          })
          break
      }
    },
    filterOrganizing() {
        // ...
    }
  }
}
</script>