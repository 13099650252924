<template>
  
  <div class="phone-search">
    <div class="container-fluid">
      <div class="row justify-content-center">

        <div class="col-10 col-md-5 phone-search__searchbox">
          <input :placeholder="locpeople" v-model="keyword" @keyup.enter="getPeople" />
          <button @click="getPeople">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="22" height="22">
              <defs>
                <clipPath id="clip_0">
                  <rect x="-1424" y="-28" width="1600" height="3441" clip-rule="evenodd" />
                </clipPath>
              </defs>
              <g clip-path="url(#clip_0)">
                <path fill="rgb(255,255,255)" stroke="none" d="M17.4692 14.5254L21.394 18.4168C22.202 19.2259 22.202 20.5744 21.394 21.3835C20.9707 21.8074 20.432 22 19.8933 22C19.3546 22 18.8159 21.8074 18.3927 21.3835L14.5063 17.4921C13.0442 18.4168 11.3126 18.9562 9.46568 18.9562C4.23262 18.9562 0 14.718 0 9.47811C0 4.23818 4.23262 0 9.46568 0C14.6987 0 18.9314 4.23818 18.9314 9.47811C18.9314 11.3275 18.3927 13.0613 17.4692 14.5254ZM2.22213 9.43958C2.22213 13.4632 5.50023 16.6926 9.46568 16.6926C13.484 16.6926 16.7092 13.4632 16.7092 9.43958C16.7092 5.41598 13.484 2.18651 9.46568 2.18651C5.44736 2.18651 2.22213 5.41598 2.22213 9.43958Z"
                  fill-rule="evenodd" />
              </g>
            </svg>
          </button>

          <div v-if="isItemsLoading" class="loading__container">
            <div class="loading"><div></div><div></div><div></div></div>
          </div>

          <div class="phone-viewmore" v-if="!isItemsLoading"><a :href="`/directory?k=${keyword}`">{{ locviewall }}</a></div>
          <div class="phone-items" v-if="!isItemsLoading && items.length > 0">
            <div class="phone-item" v-for="item in items" :key="item.UserName">
              <div class="phone-item__profile" v-if="!item.isPhoneOnly">
                <div class="phone-item__photo">
                  <a :href="`/profile?id=${item.UserName.split('@')[0]}`">
                    <v-lazy-image :src="pictures[item.UserName] || '/assets/intranet/Images/Profile.svg'"/>
                  </a>
                </div>
                <div class="phone-item__info">
                  <div>
                    <a :href="`/profile?id=${item.UserName.split('@')[0]}`">{{ item.Name }}</a>
                  </div>
                  <div>{{ item.JobTitle }}</div>
                  <div>{{ item.Department }}</div>
                  <div>{{ item.Office }}</div>
                  <a v-if="item.WorkPhone" class="phone-item__number" :href="'tel://' + item.WorkPhone"><i class="fa fa-phone"></i> {{ item.WorkPhone }}</a>
                  <a v-if="item.CellPhone" class="phone-item__number" :href="'tel://' + item.CellPhone"><i class="fa fa-mobile"></i> {{ item.CellPhone }}</a>
                </div>
              </div>
              <div class="phone-item__profile" v-if="item.isPhoneOnly">
                <div class="phone-item__icon">
                  <i class="fa fa-phone"></i>
                </div>
                <div class="phone-item__info">
                  <div>
                    <b>{{ item.Name }}</b>
                  </div>
                  <div>{{ item.Description }}</div>
                  <div class="__cbmm" v-if="item.CBMMEmpresa || item.CBMMDepartamento || item.CBMMEscritorio">
                    <div class="-company" v-if="item.CBMMEmpresa"><i class="fa fa-industry"></i> {{ item.CBMMEmpresa }}</div>
                    <div class="-departament" v-if="item.CBMMDepartamento"><i class="fa fa-building"></i> {{ item.CBMMDepartamento }}</div>
                    <div class="-office" v-if="item.CBMMEscritorio"><i class="fa fa-map-marker"></i> {{ item.CBMMEscritorio }}</div>              
                  </div>
                  <a v-if="item.Phone1" class="phone-item__number" :href="'tel://' + item.Phone1"><i class="fa fa-phone"></i> {{ item.Phone1 }}</a>
                  <a v-if="item.Phone2" class="phone-item__number" :href="'tel://' + item.Phone2"><i class="fa fa-phone"></i> {{ item.Phone2 }}</a>
                </div>
              </div>
            </div>
            <div class="phone-toomany" v-if="!isItemsLoading && items.length >= limit">{{ loclimitreached }}, <a :href="`/directory?k=${keyword}`">{{ locviewall }}</a></div>
          </div>
        </div>

        <div class="col-10 col-md-3 offset-md-1 phone-search__item phone-search__nav">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import API from './api'

export default {
  name: 'phone-search',
  props: ['baseurl', 'locpeople', 'external', 'exclude', 'limit', 'locviewall', 'loclimitreached'],
  data() {
    return {
      isItemsLoading: false,
      items: [],
      keyword: '',
      pictures: {}
    }
  },
  methods: {
    getPeople() {
      if (!this.keyword || this.keyword.length < 2) {
        return
      }

      this.isItemsLoading = true

      API.phones(this.baseurl, this.keyword).then(phones => {
        phones = phones.map(p => ({
          isPhoneOnly: true,
          Name: p.DisplayNameOrName,
          Phone1: p.Number,
          Phone2: p.Number2,
          Description: p.Description,
          CBMMEmpresa: p.CBMMEmpresa,
          CBMMDepartamento: p.CBMMDepartamento,
          CBMMEscritorio: p.CBMMEscritorio
        }))

        this.$msalAPI.getDataAPI(`_api/search/query?querytext='PreferredName:"*${this.keyword}*" OR CBMMDepartamento:"*${this.keyword}*" OR CBMMCargo:"*${this.keyword}*" OR UserName:"*${this.keyword}*" OR CBMMEmpresa:"*${this.keyword}*"'&sourceid='b09a7990-05ea-4af9-81ef-edfab16c4e31'&selectproperties='CBMMNome,CBMMDepartamento,CBMMCargo,PictureUrl,WorkPhone,UserName,CBMMEmpresa,MobilePhone'&SortList='FirstName:ascending'&RefinementFilters='CBMMCheckRamal:(1*)'&RowLimit=${this.limit}`)
          .then(resp => {
            this.isItemsLoading = false
            this.items = resp.data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results.map((r) => {
              const cbmmNome = r.Cells.results.find(cell => cell.Key === 'CBMMNome')
              const cbmmDep = r.Cells.results.find(cell => cell.Key === 'CBMMDepartamento')
              const cbmmCargo = r.Cells.results.find(cell => cell.Key === 'CBMMCargo')
              const pictureUrl = r.Cells.results.find(cell => cell.Key === 'PictureUrl')
              const workPhone = r.Cells.results.find(cell => cell.Key === 'WorkPhone')
              const userName = r.Cells.results.find(cell => cell.Key === 'UserName')
              const cbmmEmpresa = r.Cells.results.find(cell => cell.Key === 'CBMMEmpresa')
              const mobilePhone = r.Cells.results.find(cell => cell.Key === 'MobilePhone')
              if (pictureUrl) {
                try {
                  this.$msalAPI.getFileAPI(`_api/web/GetFileByServerRelativeUrl('/User%20Photos/${pictureUrl.Value.split('Photos/')[1].split('?')[0].replace('MThumb', 'LThumb')}')/$value`, { encoding: null, responseType: 'arraybuffer' })
                    .then(result => {
                      const image = `data:image/jpeg;base64,${new Buffer(result.data).toString('base64')}`
                      this.$set(this.pictures, userName.Value, image)
                    })
                } catch (e) { /* */ }
              }

              return {
                Name: cbmmNome.Value,
                Department: cbmmDep.Value,
                JobTitle: cbmmCargo.Value,
                WorkPhone: workPhone.Value || '',
                UserName: userName.Value,
                Office: cbmmEmpresa.Value,
                CellPhone: mobilePhone.Value
              }
            })
            .filter(item => item.Name.substr(item.Name.length - 5).toLocaleLowerCase() !== '- adm' &&
              item.UserName.substr(3, 1) !== '-')
            .concat(phones)
            .sort((a, b) => {
              if (a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()) {
                return 1
              }
              return -1
            })
            .slice(0, this.limit)
          })
      })
    },
  }
}
</script>