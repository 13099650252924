<template>
  <div class="topnav-menu">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col col-auto topnav-menu__tabs topnav-menu__tabs--mobile">
          <button>{{ name }}</button>
        </div>
      </div>

      <div class="row justify-content-center">
        <div
          class="col-11 col-md-9 topnav-menu__item topnav-menu__item--static"
        >
          <ul>
            <li>
              <ul>
                <slot name="language"></slot>
                <li>
                  <a href="/profile">{{ locprofile }}</a>
                </li>
                <li>
                  <a href="#" @click="logoutUser">{{ loclogout }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col col-10 col-md-4 topnav-menu__searchbox">
          <input
            :placeholder="locsearch"
            @keyup.enter="goToResults"
            v-model="keyword"
          />
          <button @click="goToResults">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="22"
              height="22"
            >
              <defs>
                <clipPath id="clip_0">
                  <rect
                    x="-1424"
                    y="-28"
                    width="1600"
                    height="3441"
                    clip-rule="evenodd"
                  />
                </clipPath>
              </defs>
              <g clip-path="url(#clip_0)">
                <path
                  fill="rgb(255,255,255)"
                  stroke="none"
                  d="M17.4692 14.5254L21.394 18.4168C22.202 19.2259 22.202 20.5744 21.394 21.3835C20.9707 21.8074 20.432 22 19.8933 22C19.3546 22 18.8159 21.8074 18.3927 21.3835L14.5063 17.4921C13.0442 18.4168 11.3126 18.9562 9.46568 18.9562C4.23262 18.9562 0 14.718 0 9.47811C0 4.23818 4.23262 0 9.46568 0C14.6987 0 18.9314 4.23818 18.9314 9.47811C18.9314 11.3275 18.3927 13.0613 17.4692 14.5254ZM2.22213 9.43958C2.22213 13.4632 5.50023 16.6926 9.46568 16.6926C13.484 16.6926 16.7092 13.4632 16.7092 9.43958C16.7092 5.41598 13.484 2.18651 9.46568 2.18651C5.44736 2.18651 2.22213 5.41598 2.22213 9.43958Z"
                  fill-rule="evenodd"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col col-auto topnav-menu__tabs">
          <button
            @click="changeTab('nav')"
            :class="{ 'topnav-active': tab === 'nav' }"
          >
            {{ locnav }}
          </button>
          <button
            @click="changeTab('apps')"
            :class="{ 'topnav-active': tab === 'apps' }"
          >
            {{ locapps }}
          </button>
        </div>
      </div>
      <div class="row justify-content-center">
        <div
          class="col-11 col-md-10 topnav-menu__item topnav-menu__nav"
          id="topnav-links"
          v-if="tab === 'nav'"
        >
          <ul>
            <topnav-navigation-group
              v-for="group in navigation"
              :key="group.Name"
              :title="group.DisplayNameOrName"
              :items="group.Items"
              el-tag="li"
              :favorite-fn="favorite"
            />
          </ul>
        </div>
        <div
          id="topnav-applications"
          class="col-11 col-md-10 topnav-menu__item topnav-menu__ext"
          v-else-if="tab === 'apps'"
        >
          <ul>
            <li
              v-for="app in applications"
              :key="app.Name"
              class="tooltip-search"
            >
              <a
                :href="app.Link ? app.Link.Url : ''"
                :target="app.Link ? app.Link.Target : ''"
                class="topnav-system__link"
                @mouseover="descriptionshow($event)"
                @mouseleave="descriptionhide($event)"
              >
                <span
                  class="topnav-system__image"
                  :style="`background-image: url('${
                    app.Thumbnail ? app.Thumbnail.Src : ''
                  }')`"
                ></span>
                <a href="#" @click.prevent="favorite(app.Id, $event)">
                  <star-icon :filled="app.Favorite" />
                </a>
                {{ app.DisplayNameOrName }}
              </a>
              <div
                v-if="app.Description"
                class="tooptip-launcher"
                v-show="app.Description.Text"
              >
                {{ app.Description.Text }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col col-auto topnav-menu__tabs topnav-menu__tabs--mobile">
        <button>{{ locphonesearchtitle }}</button>
      </div>
    </div>

    <phone-search
      :baseurl="phonesurl"
      :locsearch="locsearch"
      :locpeople="locpeople"
      :external="external"
      :exclude="exclude"
      :limit="limit"
      :locviewall="locviewall"
      :loclimitreached="loclimitreached"
    >
      <slot name="phone-search"></slot>
    </phone-search>
  </div>
</template>
<script>
  import API from './api'

  export default {
    name: 'topnav-menu',
    props: [
      'baseurl',
      'name',
      'locsearch',
      'locprofile',
      'loclogout',
      'locpeople',
      'phonesurl',
      'locnav',
      'locapps',
      'external',
      'exclude',
      'limit',
      'locviewall',
      'loclimitreached',
      'locphonesearchtitle',
    ],
    data() {
      return {
        isItemsLoading: true,
        navigation: [],
        applications: [],
        returnUrl: window.location.href,
        tab: 'nav',
      }
    },
    mounted() {
      this.getMenu()
    },
    methods: {
      getMenu() {
        this.isItemsLoading = true
        const token =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15)
        API.menu(this.baseurl, token).then((resp) => {
          if (resp) {
            this.navigation = resp.Navigation
            this.applications = resp.Applications
          }

          this.isItemsLoading = false
        })
      },
      goToResults() {
        location.href = `/results?keyword=${this.keyword ? this.keyword : ''}`
      },
      changeTab(tab) {
        this.tab = tab
      },
      favorite(id, e) {
        e.stopPropagation()
        API.favorite(this.baseurl, id, this.$utils.token()).then((resp) => {
          this.navigation = resp.Navigation
          this.applications = resp.Applications
        })
        e.preventDefault()
      },

      logoutUser(e) {
        e.stopPropagation()
        localStorage.removeItem('msal.image')
        localStorage.removeItem('msal.image')
        this.$msal.signOut()
        e.stopPropagation()
      },
      descriptionshow(evt) {
        evt.target.closest('.tooltip-search').classList.add('open-tip')
      },
      descriptionhide(evt) {
        evt.target.closest('.tooltip-search').classList.remove('open-tip')
      },
    },
  }
</script>
