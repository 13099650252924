<template>
<div class="col-12 item-select" v-if="!isDesktop">
    <ul>
        <li class="groupItem" v-for="item in peopleResult" :key="index">
            <div class="titleGroup" v-if="item.Field === 'Mes'">Mês</div>
            <div class="titleGroup" v-else-if="item.Field === 'Area'">Departamento</div>
            <div class="titleGroup" v-else>{{item.Field}}</div>
            <div v-for="itemValue in item.Values" :key="index">
                <div @click="addRemoveItem(item.Field, itemValue, $utils.months(lang || 'en')[(itemValue - 1)].substr(0, 3))" class="item" v-if="typeof itemValue === 'number'">{{ $utils.months(lang || 'en')[(itemValue - 1)].substr(0, 3) }}</div>
                <div @click="addRemoveItem(item.Field, itemValue)" class="item" v-else>{{itemValue}}</div>
            </div>
        </li>
    </ul>
</div>
</template>
<script>

export default {
  name: 'new-bdays-filter-mirror',
  props: ['isDesktop', 'peopleResult'],
  data() {
    return {
      // ...
    }
  },
  methods: {
    addRemoveItem(paramField, paramValueSelect, paramValueMoths) {
      this.$emit('filterMirror', {
        field: paramField,
        valueSelect: paramValueSelect,
        paramValue: paramValueMoths
      })
    }
  }
}
</script>