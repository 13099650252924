<template>
  <div>
    <div id="KioskAuth" class="kiosk-auth" v-if="iskioskaccount === 'true'">
      <ul id="KioskAuthBreadcrumb" class="breadcrumb">
        <li id="KioskAuthBreadcrumbStep1TabLabel" :class="kioskAuthStepOne ? 'active' : ''"><a href="#"><span>1</span>{{kioskauthbreadcrumbstep1label}}</a></li>
        <li id="KioskAuthBreadcrumbStep2TabLabel" :class="kioskAuthStepTwo ? 'active' : ''"><a href="#"><span>2</span>{{kioskauthbreadcrumbstep2label}}</a></li>
        <li id="KioskAuthBreadcrumbStep3TabLabel" :class="kioskAuthStepThree ? 'active' : ''"><a href="#"><span>3</span>{{kioskauthbreadcrumbstep3label}}</a></li>
      </ul>
      <div>
        <div class="row">
          <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
            <div id="KioskAuthErrorMessage" class="form-error-message" style="display:none"></div>
          </div>
        </div>
        <div id="KioskAuthBreadcrumbStep1TabPanel" class="row breadcrumb__row" v-if="kioskAuthStepOne">
          <div class="col breadcrumb__col-left">
            <v-lazy-image :src="kioskauthbreadcrumbstep1imgsrc" />
          </div>
          <div class="col">
            <div v-html="kioskauthbreadcrumbstep1message"></div>
          </div>
        </div>
        <div id="KioskAuthBreadcrumbStep2TabPanel" v-if="kioskAuthStepTwo && profile && !isProfileLoading">
          <div class="row breadcrumb__row">
            <div class="col breadcrumb__col-left">
              <v-lazy-image :src="kioskauthbreadcrumbstep2imgsrc" />
            </div>
            <div class="col identityaccept">
              <div class="row">
                <div class="col">
                  <div class="profile">
                    <div class="picture">
                      <v-lazy-image :src="identityAcceptProfilePictureSrc" style="width: 100%; height: 100%;" />
                    </div>
                    <div class="data">
                      <div class="data__nome">
                        <b>{{ profile.DisplayName }}</b>
                      </div>
                      <div class="data__area">
                        {{ $utils.profileProp(profile, 'Department') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row data-row">
                <div class="col">
                  <div class="row" v-if="kioskAuthUserdataRequiredList.indexOf('CPF') !== -1">
                    <div class="col">
                      <div class="form-label" style="border:0px; font-size:16px; margin-bottom:2px;">{{cpffieldlabel}} <span id="CPFFieldError" class="form-mandatory" style="display:none;"></span></div>
                      <input type="text" id="CPF" name="CPF" :placeholder="cpffieldmask" @keypress="validateNumberAndMaxChars(3);" />
                    </div>
                  </div>
                  <div class="row" v-if="kioskAuthUserdataRequiredList.indexOf('BirthdateDay') !== -1">
                    <div class="col">
                      <div class="form-label" style="border:0px; font-size:16px; margin-bottom:2px;">{{birthdatedayfieldlabel}} <span id="BirthdateDayFieldError" class="form-mandatory" style="display:none;"></span></div>
                      <input type="text" id="BirthdateDay" name="BirthdateDay" :placeholder="birthdatedayfieldmask" @keypress="validateNumberAndMaxChars(2);" />
                    </div>
                  </div>
                  <div class="row" v-if="kioskAuthUserdataRequiredList.indexOf('BirthdateMonth') !== -1">
                    <div class="col">
                      <div class="form-label" style="border:0px; font-size:16px; margin-bottom:2px;">{{birthdatemonthfieldlabel}} <span id="BirthdateMonthFieldError" class="form-mandatory" style="display:none;"></span></div>
                      <input type="text" id="BirthdateMonth" name="BirthdateMonth" :placeholder="birthdatemonthfieldmask" @keypress="validateNumberAndMaxChars(2);" />
                    </div>
                  </div>
                  <div class="row" v-if="kioskAuthUserdataRequiredList.indexOf('BirthdateYear') !== -1">
                    <div class="col">
                      <div class="form-label" style="border:0px; font-size:16px; margin-bottom:2px;">{{birthdateyearfieldlabel}} <span id="BirthdateYearFieldError" class="form-mandatory" style="display:none;"></span></div>
                      <input type="text" id="BirthdateYear" name="BirthdateYear" :placeholder="birthdateyearfieldmask" @keypress="validateNumberAndMaxChars(4);" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center" style="margin-top: 15px;">
            <div class="col-auto">
              <input type="button" name="KioskAuthBackToStepOneButton" id="KioskAuthBackToStepOneButton" :value="backtosteponebuttonlabel" class="button" @click="kioskAuthBackToStepOne" />
            </div>
            <div class="col-auto">
              <input type="button" name="KioskAuthConfirmButton" id="KioskAuthConfirmButton" :value="confirmbuttonlabel" class="button" @click="kioskAuthConfirm" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="MessageFormSender" v-if="(iskioskaccount === 'false' || kioskAuthStepThree) && profile && !isProfileLoading">
      <div class="row">
        <div class="col">
          <div class="form-label">{{messagefieldlabel}} <span id="MessageFieldError" class="form-mandatory" style="display:none"></span></div>
          <textarea id="Message" name="Message"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-label">{{attachmentfieldlabel}}</div>
          <input type="File" id="File" name="File" />
        </div>
      </div>

      <div class="identityaccept">
        <div class="row">
          <div class="col">
            <div class="profile">
              <div class="picture">
                <v-lazy-image :src="identityAcceptProfilePictureSrc" style="width: 100%; height: 100%;" />
              </div>
              <div class="data">
                <div class="data__nome">
                  <b>{{ profile.DisplayName }}</b>
                </div>
                <div class="data__area">
                  {{ $utils.profileProp(profile, 'Department') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-label">{{identityacceptfieldlabel}} <span id="IdentityAcceptFieldError" class="form-mandatory" style="display:none"></span></div>
            <div style="font-size:18px;">
              <input id="IdentityAccept" name="IdentityAccept" type="checkbox" value="true">
              <input name="IdentityAccept" type="hidden" value="false">
              <label for="IdentityAccept">{{identityacceptfieldtext}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div id="FormErrorMessage" class="form-error-message" style="display:none"></div>
        </div>
      </div>

      <div>
        <div class="row justify-content-end">
          <div class="col-auto">
            <input type="button" name="Submit" id="Submit" :value="submitbuttonlabel" class="button" @click="send" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import API from './api'
  // import { eventBus } from '../../../eventbus'

  export default {
    name: 'talk-to-president',
    props: {
      urlpostbase: {
        type: String,
        required: true
      },
      tenant: {
        type: String,
        required: true
      },
      messagefieldlabel: {
        type: String,
        required: true
      },
      attachmentfieldlabel: {
        type: String,
        required: true
      },
      iskioskaccount: {
        type: String,
        required: true
      },
      identityacceptfieldlabel: {
        type: String,
        required: true
      },
      identityacceptfieldtext: {
        type: String,
        required: true
      },
      submitbuttonlabel: {
        type: String,
        required: true
      },
      kioskauthbreadcrumbstep1label: {
        type: String,
        required: true
      },
      kioskauthbreadcrumbstep2label: {
        type: String,
        required: true
      },
      kioskauthbreadcrumbstep3label: {
        type: String,
        required: true
      },
      kioskauthbreadcrumbstep1message: {
        type: String,
        required: true
      },
      kioskauthbreadcrumbstep1imgsrc: {
        type: String,
        required: true
      },
      kioskauthbreadcrumbstep2imgsrc: {
        type: String,
        required: true
      },
      cpffieldlabel: {
        type: String,
        required: true
      },
      birthdatedayfieldlabel: {
        type: String,
        required: true
      },
      birthdatemonthfieldlabel: {
        type: String,
        required: true
      },
      birthdateyearfieldlabel: {
        type: String,
        required: true
      },
      backtosteponebuttonlabel: {
        type: String,
        required: true
      },
      confirmbuttonlabel: {
        type: String,
        required: true
      },
      successredirecturl: {
        type: String,
        required: true
      },
      usernamenotfoundonsharepoint: {
        type: String,
        required: true
      },
      qtyuserdatatoask: {
        type: String,
        required: true
      },
      cpffieldmask: {
        type: String,
        required: true
      },
      birthdatedayfieldmask: {
        type: String,
        required: true
      },
      birthdatemonthfieldmask: {
        type: String,
        required: true
      },
      birthdateyearfieldmask: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isProfileLoading: true,
        username: null,
        profile: null,
        identityAcceptProfilePictureSrc: null,
        badge: '',
        cpf: null,
        birthdateDay: null,
        birthdateMonth: null,
        birthdateYear: null,
        kioskAuthStepOne: null,
        kioskAuthStepTwo: null,
        kioskAuthStepThree: null,
        kioskAuthUserdataRequiredList: []
      }
    },
    methods: {
      send() {
        document.getElementById('FormErrorMessage').style.display = 'none'

        if (this.validateFormData()) {
          const message = document.getElementById('Message').value
          const file = document.getElementById('File').files[0]

          API.send(this.urlpostbase, message, file, this.username, this.badge, this.cpf, this.birthdateDay, this.birthdateMonth, this.birthdateYear).then(resp => {
            if (resp.Success) {
              location.href = this.successredirecturl
            } else {
              document.getElementById('FormErrorMessage').style.display = 'block'
              document.getElementById('FormErrorMessage').innerText = resp.Message
            }
          })
        }
      },
      validateFormData() {
        let formDataValid = true

        if (this.validateIfFieldIsVisibleAndNull(document.getElementById('Message'), document.getElementById('MessageFieldError'))) {
          formDataValid = false
        }

        const IdentityAcceptUnchecked = !document.getElementById('IdentityAccept').checked
        document.getElementById('IdentityAcceptFieldError').style.display = (IdentityAcceptUnchecked ? 'inline' : 'none')
        if (IdentityAcceptUnchecked) {
          formDataValid = false
        }

        return formDataValid
      },
      kioskAuthBackToStepOne() {
        this.kioskAuthStepTwo = null

        document.getElementById('KioskAuthErrorMessage').innerHTML = ''
        document.getElementById('KioskAuthErrorMessage').style.display = 'none'

        this.cpf = null
        this.birthdateDay = null
        this.birthdateMonth = null
        this.birthdateYear = null

        this.kioskAuthUserdataRequiredList = []

        this.startStepOne()
      },
      kioskAuthConfirm() {
        if (this.validateKioskAuthFormData()) {
          this.cpf = document.getElementById('CPF') !== null ? document.getElementById('CPF').value : null
          this.birthdateDay = document.getElementById('BirthdateDay') !== null ? document.getElementById('BirthdateDay').value : null
          this.birthdateMonth = document.getElementById('BirthdateMonth') !== null ? document.getElementById('BirthdateMonth').value : null
          this.birthdateYear = document.getElementById('BirthdateYear') !== null ? document.getElementById('BirthdateYear').value : null

          document.getElementById('KioskAuthErrorMessage').style.display = 'none'

          API.checkAuthenticationBadge(this.urlpostbase, this.badge, this.cpf, this.birthdateDay, this.birthdateMonth, this.birthdateYear).then(resp => {
            if (resp.HasError || resp.Erro !== '') {
              document.getElementById('KioskAuthErrorMessage').style.display = 'block'
              document.getElementById('KioskAuthErrorMessage').innerHTML = resp.HasError ? resp.Message : resp.Erro
            } else {
              this.kioskAuthStepTwo = null
              this.kioskAuthStepThree = true
            }
          })
        }
      },
      validateKioskAuthFormData() {
        let formDataValid = true

        if (this.validateIfFieldIsVisibleAndNull(document.getElementById('CPF'), document.getElementById('CPFFieldError'))) {
          formDataValid = false
        }

        if (this.validateIfFieldIsVisibleAndNull(document.getElementById('BirthdateDay'), document.getElementById('BirthdateDayFieldError'))) {
          formDataValid = false
        }

        if (this.validateIfFieldIsVisibleAndNull(document.getElementById('BirthdateMonth'), document.getElementById('BirthdateMonthFieldError'))) {
          formDataValid = false
        }

        if (this.validateIfFieldIsVisibleAndNull(document.getElementById('BirthdateYear'), document.getElementById('BirthdateYearFieldError'))) {
          formDataValid = false
        }

        return formDataValid
      },
      validateIfFieldIsVisibleAndNull(field, fieldError) {
        let fieldIsVisibleAndNull = false
        if (field !== null) {
          fieldIsVisibleAndNull = (field.value === '')
          fieldError.style.display = (fieldIsVisibleAndNull ? 'inline' : 'none')
        }
        return fieldIsVisibleAndNull
      },
      getUserPicture(username) {
        return this.$msalAPI.getFileAPI(`_api/web/GetFileByServerRelativeUrl('/User%20Photos/Profile Pictures/${username.replace(/\./ig, '_')}_${this.$msal.account.userName.split('@')[1].replace(/\./ig, '_')}_LThumb.jpg')/$value`, { encoding: null, responseType: 'arraybuffer' })
          .then(result => {
            const image = `data:image/jpeg;base64,${new Buffer(result.data).toString('base64')}`
            return image
          }).catch((error) => {
            console.error('getUserPicture()', error)
            return '/assets/intranet/Images/Profile.svg'
          })
      },
      getUserProfile(username) {
        return this.$msalAPI.getDataAPI(`_api/sp.userprofiles.peoplemanager/getpropertiesfor(@v)?@v='i%3A0%23.f%7Cmembership%7C${username}@${this.tenant}'`)
          .then((profile) => {
            this.isProfileLoading = false
            return (profile.data.d['odata.null'] ? null : profile.data.d)
          }).catch((error) => {
            console.error('getUserProfile()', error)
            return null
          })
      },
      startStepOne() {
        this.badge = ''
        window.addEventListener('keypress', this.readBadge, false)
        this.kioskAuthStepOne = true
        window.focus()
      },
      startStepTwo() {
        this.kioskAuthStepTwo = true
        const authUserdataFieldsIDs = ['CPF', 'BirthdateDay', 'BirthdateMonth', 'BirthdateYear']
        const qtyUserDataToAsk = parseInt(this.qtyuserdatatoask, 10)
        const maxUserDataToAsk = (qtyUserDataToAsk < authUserdataFieldsIDs.length) ? qtyUserDataToAsk : authUserdataFieldsIDs.length
        let randomIndex = 0
        let randomFieldId = ''

        for (let i = 0; i < maxUserDataToAsk; i++) {
          randomIndex = (Math.random() * authUserdataFieldsIDs.length)
          randomFieldId = authUserdataFieldsIDs.splice(randomIndex, 1)[0]
          this.kioskAuthUserdataRequiredList.push(randomFieldId)
        }
      },
      readBadge(e) {
        e = e || event
        const charCode = e.charCode || e.keyCode
        if (charCode !== 13) { // IF Enter
          this.badge += String.fromCharCode(charCode)
          if (this.badge.length < 8) { // OR 8 chars
            return
          }
        } // Then get data from badge

        window.removeEventListener('keypress', this.readBadge, false)

        document.getElementById('KioskAuthErrorMessage').innerHTML = ''
        document.getElementById('KioskAuthErrorMessage').style.display = 'none'

        API.checkValidatedBadge(this.urlpostbase, this.badge).then(resp => {
          if (resp.HasError || resp.Erro !== '') {
            this.readBadgeError(resp.HasError ? resp.Message : resp.Erro)
          } else {
            this.username = resp.Usuario_AD
            this.getUserPicture(this.username).then(result => { this.identityAcceptProfilePictureSrc = result })
            this.getUserProfile(this.username).then(result => {
              if (result === null) {
                this.readBadgeError(this.usernamenotfoundonsharepoint)
              } else {
                this.profile = result
                this.kioskAuthStepOne = null
                this.startStepTwo()
              }
            })
          }
        })
      },
      readBadgeError(errorMessage) {
        document.getElementById('KioskAuthErrorMessage').style.display = 'block'
        document.getElementById('KioskAuthErrorMessage').innerHTML = errorMessage
        this.startStepOne()
      },
      validateNumberAndMaxChars(maxChars, e) {
        e = e || event
        const char = String.fromCharCode(e.charCode || e.keyCode)
        if (isNaN(char) || e.target.value.length >= maxChars) {
          e.preventDefault()
        }
      }
    },
    mounted() {
      if (this.iskioskaccount === 'true') {
        this.startStepOne()
      }
      else if (this.$msal && this.$msal.getAccount()) {
        this.username = this.$utils.qs('id') || this.$msal.account.userName.split('@')[0]
        this.getUserPicture(this.username).then(result => { this.identityAcceptProfilePictureSrc = result })
        this.getUserProfile(this.username).then(result => { this.profile = result })
      }
    }
  }
</script>