export function apply(text, mask) {
  const values = `${text}`.replace(/\s/g, '').split('')
  if (!values.length) {
    return ''
  }

  return values.reduce((result, value) => {
    return result.replace(/#/, value)
  }, mask).replace(/#.*/g, '')
}

export function clear(text, mask) {
  const pseudo = `\\${mask.replace(/#/g, '').split('').join('|\\')}`
  return `${text}`.replace(new RegExp(pseudo, 'g'), '')
}

export default {
  apply,
  clear
}
