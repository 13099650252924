<template>
  <div>
    <a class="like-container" @click="like()">
      <i class="fa" v-bind:class="{ 'fa-check': this.likes == 'true', 'fa-thumbs-up': this.likes == 'false'  }"></i>
      <span class="lbl-like">{{likesettings.LikeText}}</span>
    </a>
    <div class="like-status">
      <span v-if="this.likes == 'true' && this.countlike == 0">
        {{ likesettings.JustYouLikedText }}
      </span>
      <a v-else-if="this.likes == 'true' || this.countlike > 0" :id="idnoticia + '_status'" @click="showLikes()" style="cursor:pointer">
        <strong v-if="this.likes == 'true'">
          <span>{{likesettings.YouLikeStatus}}</span>
        </strong> 
        {{this.countlike}} 
        <span>{{likesettings.LikeStatus}}</span>
      </a>
      <span v-else>
        <strong v-if="this.likes == 'true'">
          <span>{{likesettings.YouLikeStatus}}</span>
        </strong> 
        {{this.countlike}} 
        <span>{{likesettings.LikeStatus}}</span>
      </span>
    </div>
  </div>
</template>

<script>

  import API from './api'
  import { eventBus } from '../../../eventbus'

  export default {
    name: 'like-button',
    props: {
      tenant: {
        type: String,
        required: true
      },
      numlike: {
        type: String
      },
      userlike: {
        type: String,
        required: true
      },
      idnoticia: {
        type: String,
        required: true
      },
      likesettings: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        countlike: 0,
        likes: null,
        postapi: this.likesettings.UrlPost,
        getlikesapi: this.likesettings.UrlGetLikes,
        getpersonapi: this.likesettings.UrlGetPerson,
      }
    },
    methods: {
      like() {
        this.apiPost()
      },
      apiPost() {
        /* eslint-disable */
        API.like(this.postapi, { idnoticia: this.idnoticia }).then(resp => {
          if(!resp.HasError) {
            eventBus.$emit('articleLiked', { numberLikes: resp.NumberLike, likes: this.likes, idNoticia: this.idnoticia })
          }else{
            alert(resp.Message)
            location.reload()
          }
        })
      },
      showLikes() {
        API.getLikes(this.getlikesapi, this.idnoticia).then(resp => {
          document.getElementById('popup-modal_wholiked_status').innerHTML = document.getElementById(this.idnoticia + '_status').innerHTML

          if (!resp) return

          let templateBase = '<div class="wholiked__profile"><div class="wholiked__profile__picture"><img src="{PICTURE}" style="width: 100%; height: 100%;"/></div><div class="wholiked__profile__data"><div class="wholiked__profile__data__nome"><b>{NOME}</b></div><div class="wholiked__profile__data__area">{AREA}</div></div></div>';

          document.getElementById('popup-modal_wholiked_profiles').innerHTML = ""
          resp.whoLiked.forEach(username => {
            this.getUserPicture(username)
              .then(result => {
                let templateRendered = templateBase.replace('{PICTURE}', result)

                this.getUserProfile(username).then(profile => {
                  templateRendered = templateRendered.replace('{NOME}', (!profile["odata.null"] ? profile.DisplayName : ""))
                  templateRendered = templateRendered.replace('{AREA}', (!profile["odata.null"] ? this.$utils.getProp(profile, 'Department') : ""))

                  document.getElementById('popup-modal_wholiked_profiles').innerHTML += templateRendered
                })
              })
          })

          document.getElementById('popup-modal_close').addEventListener('click', () => { document.getElementById('popup-modal').style.display = 'none' })

          this.showModal('popup-modal')
        });
      },
      showModal(idNoticia) {
        document.getElementById(idNoticia).style.display = 'block'
      },
      getUserPicture(username) {
        return this.$msalAPI.getFileAPI(`_api/web/GetFileByServerRelativeUrl('/User%20Photos/Profile Pictures/${username.replace(/\./ig, '_')}_${this.$msal.account.userName.split('@')[1].replace(/\./ig, '_')}_LThumb.jpg')/$value`, { encoding: null, responseType: 'arraybuffer' })
          .then(result => {
            const image = `data:image/jpeg;base64,${new Buffer(result.data).toString('base64')}`
            return image
          }).catch(() => {
            return '/assets/intranet/Images/Profile.svg'
          })
      },
      getUserProfile(username) {
        return API.profile(this.getpersonapi, username).then((result) => {
          let email = result != null && result.Email != null ? result.Email : `${username}@${this.tenant}`
          return this.$msalAPI.getDataAPI(`_api/sp.userprofiles.peoplemanager/getpropertiesfor(@v)?@v='i%3A0%23.f%7Cmembership%7C${email}'`)
                    .then((profile) => {
                      return profile.data.d
                    }).catch(() => {
                      return null
                    })
        })
      }
    },
    created() {
      this.countlike = (this.numlike - (this.userlike === 'true' ? 1 : 0))
      this.likes = this.userlike

      eventBus.$on('articleLiked', (data) => {
        if (data.idNoticia === this.idnoticia) {
          this.likes = data.likes === 'false' ? 'true' : 'false'
          this.countlike = (data.numberLikes - (this.likes === 'true' ? 1 : 0))
        }
      })
    }
  }
</script>