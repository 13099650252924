import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/intranet/peopleevents'
  },
  peopleevents(urlBase, pagesize, page) {
    return Axios.get(`${urlBase || this.options.urlBase}?pagesize=${pagesize}&page=${page}`).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.wall()', error)
    })
  }
}

export default API
