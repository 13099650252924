<template>
  
  <div class="directory">
    <div class="container-fluid">
      <div class="row">
        <div class="col directory__searchbox">
          <div v-if="error" class="directory__error">
            {{ error }}
          </div>
          <div class="directory__filters__container" v-if="!isRefinersLoading">
            <div class="directory__filters">
              <h3>{{ locfilterby }}</h3>
              <div class="directory__filters">
                <input :placeholder="locname" v-model="keyword" @keyup.enter="getPhones(false)" />
                <div class="directory__dropdown" v-for="refiner in refiners" :key="refiner.name">
                  <select v-model="form[refiner.name]" @change="getPeople([], false, true)">
                    <option>{{ names[refiner.name] }}</option>
                    <option v-for="entry in refiner.entries" :key="entry.RefinementName" :value="entry.RefinementToken">
                      {{ entry.RefinementName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="directory__filters">
              <h3>{{ locorderby }}</h3>
              <div class="directory__dropdown">
                <select v-model="sort" @change="getPhones(true)">
                  <option v-for="name in Object.keys(names)" :key="name" :value="name">{{names[name]}}</option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="isItemsLoading" class="loading__container">
            <div class="loading loading--invert"><div></div><div></div><div></div></div>
          </div>
          <div class="directory__container">

            <div class="refiners" v-if="(!isFiltersLoading || !isItemsLoading) && items.length > 0">

              <div class="refiner" v-for="refiner in filters" :key="refiner.name">
                <h3>{{ names[refiner.name] }}</h3>
                <div v-for="entry in refiner.entries.filter((r,i) => showMore.indexOf(refiner.name) > -1 || i <= 5)" :key="entry.RefinementName" class="refinement">
                  <div :class="`refine${form[refiner.name] === entry.RefinementToken ? ` selected` : ``}`" @click="refine(refiner.name, entry.RefinementToken)" :title="entry.RefinementName">{{ entry.RefinementName }}</div><div class="count">{{ entry.RefinementCount }}</div>
                </div>
                <div v-if="refiner.entries.length > 5">
                  <div class="refine nohover">
                    <div v-if="showMore.indexOf(refiner.name) === -1" class="more" @click="toggle(refiner.name)"><i class="fa fa-plus"></i> {{ locshowmore }}</div>
                    <div v-if="showMore.indexOf(refiner.name) > -1" class="more" @click="toggle(refiner.name)"><i class="fa fa-minus"></i> {{ locshowless }}</div>
                  </div>
                </div>
              </div>

              <div v-if="!isFiltersLoading && filters.length === 0">
                {{ locempty }}
              </div>

            </div>

            <div class="phone-items" v-if="!isItemsLoading && items.length > 0">
              <div class="phone-item" v-for="item in items" :key="item.UserName">
                <div class="phone-item__profile" v-if="!item.isPhoneOnly">
                  <div class="phone-item__photo">
                    <a :href="`/profile?id=${item.UserName.split('@')[0]}`">
                      <v-lazy-image :src="pictures[item.UserName] || '/assets/intranet/Images/Profile.svg'"/>
                    </a>
                  </div>
                  <div class="phone-item__info">
                    <div class="phone-item__name">
                      <a :href="`/profile?id=${item.UserName.split('@')[0]}`">{{ item.CBMMNome }}</a>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="phone-item__props" v-if="!item.isPhoneOnly">
                    <div v-if="item.CBMMCargo">{{ item.CBMMCargo }}</div>
                    <div v-if="item.UserName" class="phone-item__email"><a :href="`mailto:${item.UserName}`"><i class="fa fa-envelope"></i> {{ item.UserName }}</a></div>
                    <div v-if="item.CBMMDepartamento">{{ item.CBMMDepartamento }}</div>
                    <div v-if="item.CBMMEmpresa"><i class="fa fa-map-marker"></i> {{ item.CBMMEmpresa }}</div>
                  </div>
                  <div class="phone-item__phones">
                    <a v-if="item.WorkPhone" class="phone-item__number" :href="'tel://' + item.WorkPhone"><i class="fa fa-phone"></i> {{ item.WorkPhone }}</a>
                    <a v-if="item.CellPhone" class="phone-item__number" :href="'tel://' + item.CellPhone"><i class="fa fa-mobile"></i> {{ item.CellPhone }}</a>
                  </div>
                </div>
                <div class="phone-item__profile phone-item__phone" v-if="item.isPhoneOnly">
                  <div class="phone-item__info">
                    <div class="phone-item__name">
                      <b>{{ item.CBMMNome }}</b>
                    </div>
                    <div class="__cbmm" v-if="item.CBMMEmpresa || item.CBMMDepartamento || item.CBMMEscritorio">
                      <div class="-company" v-if="item.CBMMEmpresa"><i class="fa fa-industry"></i> {{ item.CBMMEmpresa }}</div>
                      <div class="-departament" v-if="item.CBMMDepartamento"><i class="fa fa-building"></i> {{ item.CBMMDepartamento }}</div>
                      <div class="-office" v-if="item.CBMMEscritorio"><i class="fa fa-map-marker"></i> {{ item.CBMMEscritorio }}</div>              
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>{{ item.Description }}</div>
                    <div class="phone-item__phones">
                      <a v-if="item.Phone1" class="phone-item__number" :href="'tel://' + item.Phone1"><i class="fa fa-phone"></i> {{ item.Phone1 }}</a>
                      <a v-if="item.Phone2" class="phone-item__number" :href="'tel://' + item.Phone2"><i class="fa fa-phone"></i> {{ item.Phone2 }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isItemsLoading && searchPerformed && items.length === 0" class="empty">
              {{ locempty }}
            </div>

          </div>
          <div v-if="!isItemsLoading && items.length === limit" class="toomany">
            {{ loctoomany }}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import API from './api'

export default {
  name: 'directory',
  props: [
    'baseurl',
    'locpeople',
    'external',
    'exclude',
    'refinernames',
    'limit',
    'loctoomany',
    'locempty',
    'locfilterby',
    'locorderby',
    'locshowmore',
    'locshowless',
    'locname'
  ],
  data() {
    return {
      isItemsLoading: false,
      items: [],
      keyword: '',
      pictures: {},
      isRefinersLoading: false,
      refiners: [],
      names: {},
      form: {},
      isFiltersLoading: false,
      filters: [],
      showMore: [],
      searchPerformed: false,
      sort: null,
      error: null
    }
  },
  mounted() {
    // This will convert language-based refinement names into an object
    this.refinernames.split(',').map(r => {
      const part = r.split(':')
      return Object.assign(this.names, {}, { [part[0]]: part[1] })
    })
    this.resetSelections()
    this.keyword = this.$utils.qs('k')
    if (this.keyword) {
      this.getPhones(false, true)
      return
    }
    this.getPeople([], true, false)
  },
  methods: {
    resetSelections() {
      this.form.CBMMCargo = this.names.CBMMCargo
      this.form.CBMMDepartamento = this.names.CBMMDepartamento
      this.form.CBMMEmpresa = this.names.CBMMEmpresa
      this.form.CBMMEscritorio = this.names.CBMMEscritorio
      this.form.CBMMNome = this.names.CBMMNome
      this.sort = 'CBMMNome'
      this.showMore = []
    },
    getPhones(reset, refinersOnly) {
      if (this.keyword) {
        API.phones(this.baseurl, this.keyword).then(phones => {
          phones = phones.map(p => ({
            isPhoneOnly: true,
            CBMMNome: p.DisplayNameOrName,
            Phone1: p.Number,
            Phone2: p.Number2,
            Description: p.Description,
            CBMMEmpresa: p.CBMMEmpresa,
            CBMMDepartamento: p.CBMMDepartamento,
            CBMMEscritorio: p.CBMMEscritorio
          }))
          this.getPeople(phones, refinersOnly, reset)
        })
      }
      else
      {
        this.getPeople([], refinersOnly, reset)
      }
    },
    toggle(name) {
      if (this.showMore.includes(name)) {
        this.showMore.splice(this.showMore.indexOf(name), 1)
      }
      this.showMore.push(name)
    },
    refine(name, token) {
      if (this.form[name] === token) { // filter toggled
        this.form[name] = this.names[name]
      } else {
        this.form[name] = token
      }
      this.getPhones(true, false)
    },
    getPeople(phones, refinersOnly, dontreset) {
      this.error = null
      if (!dontreset) {
        this.resetSelections()
      }
      const values = Object.keys(this.form).filter(
        f => this.form[f] !== this.names[f]).reduce(
          (obj, key) => {
            obj[key] = this.form[key]
            return obj
          }, {})

      values.CBMMCheckRamal = '1*'

      let refinements = ''

      if (refinersOnly) {
        this.isRefinersLoading = true
      }

      if (Object.keys(values).length > 1) {
        refinements = `and(${Object.keys(values).map(f => `${f}:(${values[f]})`).join(',')})`
      } else {
        refinements = Object.keys(values).map(f => `${f}:(${values[f]})`).join(',')
      }

      refinements = encodeURIComponent(refinements)

      if (refinersOnly) {
        this.$msalAPI.getDataAPI(`_api/search/query?querytext='*'&sourceid='b09a7990-05ea-4af9-81ef-edfab16c4e31'&selectproperties='UserName'&Refiners='CBMMDepartamento(filter=1000/0/*),CBMMCargo(filter=1000/0/*),CBMMEmpresa(filter=1000/0/*)'&RefinementFilters='CBMMCheckRamal:1*'&RowLimit=1${''}`)
          .then(resp => {
            this.isRefinersLoading = false
            if (resp.data.d.query.PrimaryQueryResult.RefinementResults) {
              this.refiners = resp.data.d.query.PrimaryQueryResult.RefinementResults.Refiners.results.map(r => ({
                name: r.Name,
                entries: r.Entries.results.sort((a, b) => {
                  if (a.RefinementValue.toLocaleLowerCase() > b.RefinementValue.toLocaleLowerCase()) {
                    return 1
                  }
                  return -1
                })
              }))
            } else {
              this.refiners = []
            }
          })
      }
      if (!refinersOnly || (refinersOnly && this.keyword)) {
        this.isItemsLoading = true
        this.isFiltersLoading = true
        this.searchPerformed = true
        this.$msalAPI.getDataAPI(`_api/search/query?querytext='${this.keyword ? `PreferredName:"*${this.keyword}*" OR CBMMDepartamento:"*${this.keyword}*" OR CBMMCargo:"*${this.keyword}*" OR UserName:"*${this.keyword}*" OR CBMMEmpresa:"*${this.keyword}*"` : '*'}'&sourceid='b09a7990-05ea-4af9-81ef-edfab16c4e31'&selectproperties='CBMMNome,CBMMDepartamento,CBMMCargo,PictureUrl,WorkPhone,UserName,CBMMEmpresa,MobilePhone'&Refiners='CBMMDepartamento(filter=1000/0/*),CBMMCargo(filter=1000/0/*),CBMMEmpresa(filter=1000/0/*)'&SortList='${this.sort}:ascending'&RefinementFilters='${refinements || 'CBMMCheckRamal:1*'}'&RowLimit=${this.limit}`)
          .then(resp => {
            if (resp.data.d.query.PrimaryQueryResult.RefinementResults) {
              this.filters = resp.data.d.query.PrimaryQueryResult.RefinementResults.Refiners.results.map(r => ({
                name: r.Name,
                entries: r.Entries.results
              }))
            } else {
              this.filters = []
            }
            this.isFiltersLoading = false

            this.items = resp.data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results.map((r) => {
              const cbmmNome = r.Cells.results.find(cell => cell.Key === 'CBMMNome')
              const cbmmDep = r.Cells.results.find(cell => cell.Key === 'CBMMDepartamento')
              const cbmmCargo = r.Cells.results.find(cell => cell.Key === 'CBMMCargo')
              const pictureUrl = r.Cells.results.find(cell => cell.Key === 'PictureUrl')
              const workPhone = r.Cells.results.find(cell => cell.Key === 'WorkPhone')
              const userName = r.Cells.results.find(cell => cell.Key === 'UserName')
              const cbmmEmpresa = r.Cells.results.find(cell => cell.Key === 'CBMMEmpresa')
              const mobilePhone = r.Cells.results.find(cell => cell.Key === 'MobilePhone')

              if (pictureUrl) {
                try {
                  this.$msalAPI.getFileAPI(`_api/web/GetFileByServerRelativeUrl('/User%20Photos/${pictureUrl.Value.split('Photos/')[1].split('?')[0].replace('MThumb', 'LThumb')}')/$value`, { encoding: null, responseType: 'arraybuffer' })
                  .then(result => {
                    const image = `data:image/jpeg;base64,${new Buffer(result.data).toString('base64')}`
                    this.$set(this.pictures, userName.Value, image)
                  })
                }
                catch (error) { /* */ }
              }
              return {
                CBMMNome: cbmmNome.Value,
                CBMMDepartamento: cbmmDep.Value,
                CBMMCargo: cbmmCargo.Value,
                WorkPhone: workPhone.Value || '',
                UserName: userName.Value,
                CBMMEmpresa: cbmmEmpresa.Value,
                CellPhone: mobilePhone.Value
              }
            })
            .filter(item => {
              try {
                return item.CBMMNome.substr(item.CBMMNome.length - 5).toLocaleLowerCase() !== '- adm' &&
                        item.UserName.substr(3, 1) !== '-' &&
                        item.UserName.indexOf('@comipa.com.br') < 0 // esconder contatos comipa
              } catch (e) {
                window.console.log('directory filter: ', e)
                return true
              }
            })
            .concat(phones || [])
            .sort((a, b) => {
              if (a[this.sort].toLocaleLowerCase() > b[this.sort].toLocaleLowerCase()) {
                return 1
              }
              return -1
            })
            .slice(0, this.limit)

            this.isItemsLoading = false
          })
          .catch(() => {
            this.error = `There was an error, make sure these fields are configured in SharePoint Profile: ${Object.keys(this.names).join(', ')}. Those fields needs to have data, be refinable and sortable.`
            this.isItemsLoading = false
          })
      }
    }
  }
}

</script>