import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/intranet/jobs'
  },
  jobs(urlBase) {
    return Axios.get(`${urlBase || this.options.urlBase}`).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.jobs()', error)
    })
  },
  apply(urlBase, formdata, id, title) {
    return Axios.post(`${urlBase || this.options.urlBase}/apply?id=${id}&title=${title}`, formdata).then(response => {
      return response.data
    }).catch((error) => {
      console.error('API.jobs()', error)
    })
  }
}

export default API
