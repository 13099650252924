const filters = {
  excludedBirthdaysFilter: (value) => {
    const excludedNames = ['RODRIGO CARLOS MOTA']
    if (excludedNames.indexOf(value.Nome) >= 0) {
      return false
    }
    return true
  }
}

export default filters
