<template>
<div class="pesquisa">
  <div class="page-title">
    <h1>{{pageTitle}}</h1>
    <v-lazy-image v-if="tooltipAnonymous != ''" src="/assets/intranet/Images/icone_enquete_anonima.svg" v-tooltip.top-center="tooltipAnonymous"/>
  </div>
  <div class="pesquisa-form">
    <div class="contact-form-header">
      <h2>{{formHeader}}</h2>
    </div>
    <i class="fa-header-poll fa fa-sort-down"></i>
    <form v-on:submit.stop="onSubmit" method="POST" v-if="this.statusPoll === false && this.statusPost === false">
      <div class="lin-space" v-for="(formItem, index) in formFields" :key="index">
        <div v-if="formItem.type === 'radio'">
          <label><span v-html="formItem.label"></span></label>
          <form-radio
            :name="formItem.name"
            :errorText="formItem.errorText"
            :options="formItem.options"
            groupclass="radio--group"
            :group="formItem.group"
            :validation="{ $dirty, $valid, $validators }"
            :validate="validate"
            v-model="formItem.value"
          ></form-radio>
          <form-input type="hidden" :id="`form.${index}`" :name="`form.${index}`" :value="formItem.label" isvalid="true" v-model="form[`${formItem.name}Hidden`]" />
        </div>

        <div v-else-if="formItem.type === 'checkbox'">
          <label><span v-html="formItem.label"></span></label>
          <form-checkbox
            :name="formItem.name"
            :errorText="formItem.errorText"
            :options="formItem.options"
            groupclass="check--group"
            :group="formItem.group"
            :selected="formItem.value"
            :validation="{ $dirty, $valid, $validators }"
            :validate="validate"
            v-model="formItem.value"
          ></form-checkbox>
           <form-input type="hidden" :id="`form.${index}`" :name="`form.${index}`" :value="formItem.label" isvalid="true" v-model="form[`${formItem.name}Hidden`]" />

        </div>

        <div v-else-if="formItem.type === 'textarea'">
          <form-textarea 
            :label="formItem.label"
            :name="'form.' + formItem.name"
            :errorText="formItem.errorText"
            :placeholder="formItem.placeholder"
            :validation="{ $dirty, $valid, $validators }"
            :validate="validate"
            :isValid="formItem.isValid"
            v-model="form[formItem.name]"
          ></form-textarea>
          <form-input type="hidden" :id="`form.${index}`" :name="`form.${index}`" :value="formItem.label" isvalid="true" v-model="form[`${formItem.name}Hidden`]" />
        </div>

        <div v-else>
          <form-input
            :label="formItem.label"
            :name="'form.' + formItem.name"
            :type="formItem.type"
            :errorText="formItem.errorText"
            :mask="formItem.mask"
            :clear-mask="formItem.clearMask"
            :placeholder="formItem.placeholder"
            :validation="{ $dirty, $valid, $validators }"
            :validate="validate"
            :isValid="formItem.isValid"
            v-model="form[formItem.name]"
          ></form-input> 
          <form-input type="hidden" :id="`form.${index}`" :name="`form.${index}`" :value="formItem.label" isvalid="true" v-model="form[`${formItem.name}Hidden`]" />  
        </div>
        
      </div>
      <div class="contact-form-footer">
        <button
          class="button button--default"
          :class="{'button--disabled': !isFormValid}"
          :disabled="!isFormValid"
          type="submit"
          id="contact-submit">{{submitLabel}}
        </button>
        <div class="post-error" v-show="this.statusError">
          <slot name="msg-error"></slot>
        </div>
        <div class="container-poll" v-if="tooltipAnonymous != ''">
          <v-lazy-image src="/assets/intranet/Images/icone_enquete_anonima.svg" />
          <slot name="anonymous-poll"></slot>
        </div>
      </div>
    </form>
    <div class="poll-true" v-if="this.statusPoll === true">
      <slot name="msg-tanks"></slot>
    </div>

    <div class="poll-sucess" v-if="this.statusPost === true">
      <v-lazy-image src="/assets/intranet/Images/icones_enquete_send-02.svg" />
      <slot name="msg-sucess"></slot>
    </div>
  </div>
</div>
</template>

<script>
  import Validators from '../../../utils/validators'
  import API from './api'

  export default {
    name: 'pesquisa',
    props: {
      formFields: {
        type: Array,
        default() { return [] }
      },
      postApi: {
        type: String,
        required: true
      },
      submitLabel: {
        type: String,
        default: ''
      },
      formHeader: {
        type: String,
        default: ''
      },
      pageTitle: {
        type: String,
        default: ''
      },
      tooltipAnonymous: {
        type: String,
        default: ''
      },
      statusPoll: {
        type: Boolean,
        required: true
      },
      postId: {
        type: String,
        required: true
      }
    },
    validators: {},
    data() {
      return {
        validate: false,
        statusPost: false,
        statusError: false,
        form: {}
      }
    },
    methods: {
      onSubmit(e) {
        e.preventDefault()
        this.validate = !this.isFormValid
        const data = this.form
        data.postId = this.postId

        API.submitPesquisa(this.postApi, data).then(resp => {
          if (resp.result === true) {
            this.statusPost = true
          } else {
            const self = this
            setTimeout(function () { self.statusError = false }, 5000)
          }
        })
      },
      setValidators() {
        const validatorsObject = {}

        this.formFields.forEach(formItem => {
          this.$set(this.form, formItem.name, formItem.value || '')
          validatorsObject[`form.${formItem.name}`] = this.getItemValidators(formItem.validators)
        })

        this.initGroupProperties(validatorsObject)
      },
      getItemValidators(itemValidators) {
        if (!itemValidators) {
          return
        }
        const objectValidators = {}
        itemValidators.forEach(validator => {
          objectValidators[validator] = Validators[validator]
        })
        /* eslint-disable */
        return objectValidators
        /* eslint-enable */
      }
    },
    computed: {
      isFormValid() {
        for (const key in this.$valid.form) {
          if (!this.$valid.form[key]) {
            return false
          }
        }
        return true
      }
    },
    created() {
      this.formFields.forEach((formItem) => {
        this.form[`${formItem.name}Hidden`] = encodeURIComponent(formItem.label)
      })

      this.$on('radio-change', function (payload) {
        this.form[payload.name] = payload.value
      }.bind(this))

      this.$on('checkbox-change', function (payload) {
        this.form[payload.name] = payload.value
      }.bind(this))

      this.setValidators()
    }
  }
</script>
