import Swiper from 'swiper'
import debounce from 'lodash/debounce'
import forEach from 'lodash/forEach'

const swipers = []
const query = '(max-width: 992px)'

//
// Helpers
//

// Here we don't use "toggle()" because:
// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11865865/
function toggle(element, className, force) {
  if (!element) {
    return
  }

  if (force) {
    element.classList.add(className)
  } else {
    element.classList.remove(className)
  }
}

//
// Tabs
//

function getActiveTabItem(tabs) {
  const hash = window.location.hash.replace(/#/g, '')
  const last = tabs.dataset.active
  const fromHash = tabs.querySelector(`[data-tab="${hash}"]`)
  const fromLast = tabs.querySelector(`[data-tab="${last}"]`)

  if (!fromLast && fromHash) {
    return fromHash
  }

  if (fromLast) {
    return fromLast
  }

  return null
}

function setDesktopTabAndContent(tabs) {
  const media = window.matchMedia(query)

  if (media.matches) {
    return
  }

  const active = getActiveTabItem(tabs)
  const first = tabs.querySelector('.tabs__item')
  const target = active != null ? active : first

  if (!active) {
    toggle(first, 'swiper-slide-active', true)
  }

  toggleTabAndContent(tabs, target)
}

function toggleByLocationHash(tabs) {
  const hash = window.location.hash.replace(/#/g, '')
  const header = tabs.querySelector('.tabs__header')
  const relative = tabs.querySelector(`[data-tab="${hash}"]`)

  if (!relative) {
    return
  }

  toggleTabAndContent(tabs, {
    dataset: {
      tab: hash
    }
  })

  if (!header.swiper) {
    return
  }

  const items = header.querySelectorAll('.tabs__header-wrapper > .tabs__item')
  const index = Array.prototype.indexOf.call(items, getActiveTabItem(tabs))

  header.swiper.slideToLoop(index)
}

function toggleTabAndContent(tabs, target) {
  const media = window.matchMedia(query)

  forEach(tabs.querySelectorAll('.tabs__content'), (content) => {
    toggle(content, 'tabs__content--visible', target.dataset.tab === content.dataset.tab)
  })

  if (media.matches) {
    return
  }

  forEach(tabs.querySelectorAll('.tabs__item'), (item) => {
    toggle(item, 'swiper-slide-active', item.dataset.tab === target.dataset.tab)
  })
}

function toggleSwiperCssClasses(tabs) {
  const media = window.matchMedia(query)
  const header = tabs.querySelector('.tabs__header')
  const wrapper = header.querySelector('.tabs__header-wrapper')
  const items = wrapper.querySelectorAll('.tabs__item')

  toggle(header, 'swiper-container', media.matches)
  toggle(wrapper, 'swiper-wrapper', media.matches)

  forEach(items, (item) => toggle(item, 'swiper-slide', media.matches))
}

function toggleSwiperComponents(tabs) {
  const media = window.matchMedia(query)

  if (!media.matches) {
    swipers.forEach(() => destroySwiper(swipers.pop()))
    return
  }

  const header = tabs.querySelector('.tabs__header')

  if (header.swiper) {
    return
  }

  const items = header.querySelectorAll('.tabs__header-wrapper > .tabs__item')
  const index = Array.prototype.indexOf.call(items, getActiveTabItem(tabs))

  swipers.push(new Swiper(header, {
    loop: true,
    speed: 400,
    centeredSlides: true,
    slidesPerView: items.length,
    initialSlide: index >= 0 ? index : null,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      992: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      576: {
        slidesPerView: 1
      }
    },
    on: {
      transitionEnd: () => {
        toggleTabAndContent(tabs, tabs.querySelector('.swiper-slide-active'))
      }
    }
  }))
}

function destroySwiper(swiper) {
  const wrapper = swiper.el.querySelector('.tabs__header-wrapper')
  const duplicates = wrapper.querySelectorAll('.swiper-slide-duplicate')

  forEach(duplicates, (duplicate) => {
    wrapper.removeChild(duplicate)
  })

  swiper.destroy()
}

//
// Initialize
//

document.addEventListener('DOMContentLoaded', () => {
  forEach(document.querySelectorAll('.tabs'), (root) => {
    const items = root.querySelectorAll('.tabs__item')

    setDesktopTabAndContent(root)
    toggleSwiperCssClasses(root)
    toggleSwiperComponents(root)

    forEach(items, (item) => item.addEventListener('click', () => {
      const media = window.matchMedia(query)

      if (!media.matches) {
        toggleTabAndContent(root, item)
      }
    }))
  })
})

window.addEventListener('resize', debounce(() => {
  forEach(document.querySelectorAll('.tabs'), (root) => {
    const active = root.querySelector('.swiper-slide-active')

    if (active) {
      root.dataset.active = active.dataset.tab
    }

    setDesktopTabAndContent(root)
    toggleSwiperCssClasses(root)
    toggleSwiperComponents(root)
  })
}))

window.addEventListener('hashchange', () => {
  forEach(document.querySelectorAll('.tabs'), (root) => {
    toggleByLocationHash(root)
  })
})
