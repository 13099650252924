import Swiper from 'swiper'

export default function featuredslider() {
  (() =>
    new Swiper('.featured-news', {
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: false,
      autoplay: {
        delay: 8000,
      },
      threshold: 10,
      pagination: {
        el: '.featured-news .swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.featured-news .swiper-button-next',
        prevEl: '.featured-news .swiper-button-prev',
      },
      on: {
        touchEnd: () => {
          setTimeout(() => {
            const sliderId = document.querySelector('.swiper-slide-active').getAttribute('data-swiper-slide-index')
            /* eslint-disable */
            if( typeof(dataLayer) === 'object' ) {
              try {
              dataLayer.push({ 'event':'show_slide', 'slide_number':sliderId })
              }catch (e) {
                console.warn('failed to push tag to dataLayer')
              }
            /* eslint-enable */
            }
          }, 300)
        }
      }
    })
  )()
}
