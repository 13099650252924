function SitecoreModeService()
{
  this.IsExperienceEditor = () => {
    if (typeof (Sitecore) === 'undefined')
        {
      return false
    }

    return !!(window.Sitecore && window.Sitecore.PageModes && window.Sitecore.PageModes.PageEditor)
  }
}

export default new SitecoreModeService()
