/* eslint-disable */
import 'es6-promise/auto'

import Vue from 'vue'
import store from './store'
import Router from 'vue-router'
import Validation from './mixins/validation'
import 'lazysizes'
import utils from './utils/utils'
import msalAPI from './utils/msalAPI'
import * as Msal from 'msal'
import Jobs from './components/02_containers/jobs/jobs.vue'
import Events from './components/02_containers/events/events.vue'
import { VLazyImagePlugin } from 'v-lazy-image'


import 'babel-polyfill'
import './plain/menu-open'
import './plain/files'
import './plain/yammer'
import './plain/tabs'
import './plain/prevent-doubleclick'
import featuredslider from './plain/featuredslider'
import './plain/favorites'
import 'intersection-observer'
import './plugins/lazysizes-bgset'
import './utils/lazysizes-bg-init'

document.addEventListener('DOMContentLoaded', () => {
  if (location.href.toLowerCase().indexOf('/login') > -1) {
    var checkForm = setInterval(() => {
      if(document.forms['LoginForm']) {
        clearInterval(checkForm);
        document.forms['LoginForm'].submit(); 
      }
    }, 50);
  }
});

import Components from './components'

console.trace = function() {}

Vue.use(Validation)
Vue.use(VLazyImagePlugin)
Components.forEach(component => {
  if (component.name)
    Vue.component(component.name, component)
  else
    Vue.component(component)
})

Vue.config.productionTip = false

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/jobs/:id', component: Jobs },
    { path: '/events/:id', component: Events }
  ]
})

Vue.use(Router)

if(document.querySelector('header') && location.href.toLowerCase().indexOf('sc_mode=') === -1) 
{

  Vue.use({
    install() {
      Vue.prototype.$msal = new Msal.UserAgentApplication({
        auth: {
          clientId: window.adalInfo.clientId,
          authority: `https://login.microsoftonline.com/${window.adalInfo.tenantId}/`,
          redirectUri: location.href.replace(location.hash, '')
        },
        cache: {
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
          cacheLocation: 'localStorage'
        }
      })
    }
  })
  
  Vue.use({
    install (vue, opts = {}) {
      vue.prototype.$utils = utils
      vue.prototype.$msalAPI = msalAPI
    }
  })

  new Vue({ router, el: 'header', store })
}

import SitecoreVueAdapter from './plugins/sc-vue-adapter'

SitecoreVueAdapter.InitialiseVueApp(() => {
  new Vue({
      router,
      el: '#vue-container',
      store,
      created() {
      window.$msal = this.$msal
    },
    mounted() {
      setTimeout(() => {
        featuredslider()
      }, 1000)    
    }
  })
})

const introButtons = {
  'en': { 
    skipLabel: '<strong>Skip Tutorial</strong>',
    doneLabel: '<strong>Done</strong>'
  },
  'pt': { 
    skipLabel: '<strong>Pular Tutorial</strong>',
    doneLabel: '<strong>Encerrar</strong>'
  }
}

if (window.matchMedia("(min-width: 1200px)").matches && location.href.toLowerCase().indexOf('/launcher') === -1 && location.href.toLowerCase().indexOf('/welcome') === -1) {

  document.addEventListener('DOMContentLoaded', () => {

    if (window.localStorage.getItem('intro_shown')) {
      document.querySelector("header").classList.remove('scroll')
      return
    }

    let introCheck = setInterval(() => { 
      if(document.querySelector("span[rel=profile]") !== null) {
        clearInterval(introCheck)

        document.querySelector("html > body .wrapper")
          .classList.add('wrapper--without-overflow')
        
        setTimeout(() => {
          window.scrollTo(0,0)
          
          introJs()
          .setOptions(introButtons[window.sitelang || 'en'])
          .setOptions({
            scrollTo: 'tooltip',
            exitOnOverlayClick: false,
            exitOnEsc: false,
            nextLabel: ' > ',
            prevLabel: ' < '
          })
          .onexit(function() {
            document.querySelector("header").classList.remove('scroll')
            document.querySelector("html > body .wrapper")
              .classList.remove('wrapper--without-overflow')

            window.localStorage.setItem('intro_shown', true)
          })
          .oncomplete(function() {
            document.querySelector("header").classList.remove('scroll')
            document.querySelector("html > body .wrapper")
              .classList.remove('wrapper--without-overflow')

            window.localStorage.setItem('intro_shown', true)
          })
          .start()
        }, 1000);
      }
    }, 50)
  })

} else {
  if(document.querySelector("header"))
    document.querySelector("header").classList.remove('scroll')
}

if (location.href.toLowerCase().indexOf('/launcher') > -1) {
  const logoLink = document.querySelector('.logo')
  const logoImage = document.querySelector('.logo img')
  logoLink.setAttribute('href', '/launcher')
  logoImage.setAttribute('src', logoImage.getAttribute('src').replace('blue', 'white'))
  if(performance.navigation.type == 2)
    location.reload(true);
  const header = document.querySelector('header').cloneNode(true)
  header.classList.add('header-clone')
  const footer = document.querySelector('footer')
  footer.parentNode.insertBefore(header, footer.nextSibling)
}

if (location.href.toLowerCase().indexOf('/welcome') > -1) {
  document.querySelector('.disclaimer__text input').onchange = (e) => {
    if(e.target.checked)
      document.querySelector('a.button.disabled').classList.remove('disabled')
    else
      document.querySelector('a.button').classList.add('disabled')
  }
}

document.querySelector('body > .wrapper > .content').onclick = (e) => {
  document.querySelector('html').classList.remove('topnav-open')
  document.querySelector('html').classList.remove('phone-open')
}
