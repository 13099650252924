import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/intranet/Gallery'
  },
  gallery(urlBase, id, searchText, initialDate, endDate) {
    return Axios.get(`${urlBase || this.options.urlBase}?id=${id}&searchText=${searchText || ''}&initialDate=${initialDate || ''}&endDate=${endDate || ''}`).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.gallery()', error)
    })
  }
}

export default API
