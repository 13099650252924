<template>
<div>
   <div class="filter-month filter-diver hidden-sm" v-if="isDesktop">
        <slot name="title-month"></slot>
        <ul>
            <li @click="addRemoveItem('Mes' ,1)" v-bind:class="{ select: month === 1 }">{{ $utils.months(lang || 'en')[(0)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes', 2)" v-bind:class="{ select: month === 2 }">{{ $utils.months(lang || 'en')[(1)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,3)" v-bind:class="{ select: month === 3 }">{{ $utils.months(lang || 'en')[(2)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,4)" v-bind:class="{ select: month === 4 }">{{ $utils.months(lang || 'en')[(3)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,5)" v-bind:class="{ select: month === 5 }">{{ $utils.months(lang || 'en')[(4)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,6)" v-bind:class="{ select: month === 6 }">{{ $utils.months(lang || 'en')[(5)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,7)" v-bind:class="{ select: month === 7 }">{{ $utils.months(lang || 'en')[(6)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,8)" v-bind:class="{ select: month === 8 }">{{ $utils.months(lang || 'en')[(7)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,9)" v-bind:class="{ select: month === 9 }">{{ $utils.months(lang || 'en')[(8)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,10)" v-bind:class="{ select: month === 10 }">{{ $utils.months(lang || 'en')[(9)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,11)" v-bind:class="{ select: month === 11 }">{{ $utils.months(lang || 'en')[(10)].substr(0, 3) }}</li>
            <li @click="addRemoveItem('Mes' ,12)" v-bind:class="{ select: month === 12 }">{{ $utils.months(lang || 'en')[(11)].substr(0, 3) }}</li>
        </ul>
    </div>
    <div class="filter-month filter-diver" v-else>
        <ul>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,1)" v-bind:class="{ select: month === 1 }">{{ $utils.months(lang || 'en')[(0)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes', 2)" v-bind:class="{ select: month === 2 }">{{ $utils.months(lang || 'en')[(1)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,3)" v-bind:class="{ select: month === 3 }">{{ $utils.months(lang || 'en')[(2)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,4)" v-bind:class="{ select: month === 4 }">{{ $utils.months(lang || 'en')[(3)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,5)" v-bind:class="{ select: month === 5 }">{{ $utils.months(lang || 'en')[(4)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,6)" v-bind:class="{ select: month === 6 }">{{ $utils.months(lang || 'en')[(5)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,7)" v-bind:class="{ select: month === 7 }">{{ $utils.months(lang || 'en')[(6)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,8)" v-bind:class="{ select: month === 8 }">{{ $utils.months(lang || 'en')[(7)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,9)" v-bind:class="{ select: month === 9 }">{{ $utils.months(lang || 'en')[(8)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,10)" v-bind:class="{ select: month === 10 }">{{ $utils.months(lang || 'en')[(9)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,11)" v-bind:class="{ select: month === 11 }">{{ $utils.months(lang || 'en')[(10)].substr(0, 3) }}</li>
            <li class="js-itemlistfilter" @click="addRemoveItem('Mes' ,12)" v-bind:class="{ select: month === 12 }">{{ $utils.months(lang || 'en')[(11)].substr(0, 3) }}</li>
        </ul>
        </div>
    </div>
</template>
<script>

export default {
  name: 'new-bdays-month',
  props: ['month', 'isDesktop', 'lang'],
  data() {
    return {
      // ...
    }
  },
  methods: {
    addRemoveItem(paramField, paramValueSelect) {
      this.$emit('searchMonth', {
        field: paramField,
        valueSelect: paramValueSelect
      })
    }
  }
}
</script>