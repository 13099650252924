<template>
  <div class="container lostandfound__wrapper">
    <div class="row">
      <div class="col lostandfound">
        <div v-if="addForm" class="container dc">
          <form method="post" enctype="multipart/form-data" novalidate>
            <h2>{{ locadd }}</h2>
            <div class="row">
              <div class="col">
                <div class="form-label">{{ loctitle }}</div>
                <span v-if="invalid.indexOf('title') > -1" class="invalid">*</span>
                <input name="Title" type="text" v-model="form.title" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-label">{{ locdesc }}</div>
                <span v-if="invalid.indexOf('description') > -1" class="invalid">*</span>
                <textarea name="Description" v-model="form.description"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-label">{{ loctype }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="lost">
                  <input v-model="form.type" type="radio" name="lost" id="type1" value="1" />
                  {{ loctypelost }}
                </label>
                <label for="found">
                  <input v-model="form.type" type="radio" name="found" id="type2" value="2" />
                  {{ loctypefound }}
                </label>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col">
                <div class="form-label">{{ lococurrencedate }}</div>
                <span v-if="invalid.indexOf('ocurrencedate') > -1" class="invalid">*</span>
                <input name="OcurrenceDate" type="text" v-model="form.ocurrencedate" />
              </div>
            </div> -->
            <div class="row">
              <div class="col">
                <div class="form-label">{{ lococurrencedate }}</div>
                <span v-if="invalid.indexOf('ocurrencedate') > -1" class="invalid">*</span>
                <datepicker 
                  v-model="form.ocurrencedate" 
                  :full-month-name="true"
                  :language="language"></datepicker>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-label">{{ locimage }}</div>
                <input type="file" ref="postedimages" name="PostedImages" multiple />
              </div>
            </div>
            <div class="lostandfound__actions--form">
              <button class="button" @click.prevent="saveAd()" :disabled="saving">{{ locsave }}</button>
              <a href="#" class="lostandfound__add" @click.prevent="cancelAddItem()">{{ loccancel }}</a>
            </div>
          </form>
        </div>
        <div class="lostandfound__actions" v-if="!addForm">
          <a href="#" class="lostandfound__add" @click.prevent="addItem"></a>
        </div>
        <div v-if="isItemsLoading" class="loading__container">
          <div class="loading loading--invert">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div
          v-if="!isItemsLoading && !addForm"
          :class="currentId ? 'lostandfound__detail' : 'lostandfound__container'"
        >
          <div
            class="lostandfounditem"
            v-for="item in items.filter(item => !currentId || item.Id === currentId)"
            :key="item.Id"
            :class="item.Type === 1 ? 'lostandfound__lost' : 'lostandfound__found'"
          >
            <div class="type" v-if="!currentId">{{ item.Type === 1 ? loctypelost.toUpperCase() : loctypefound.toUpperCase() }}</div>
            <div class="lostandfound__icon" v-if="!currentId"></div>
            <div v-if="!item.Approved" class="lostandfound__approval">
              <span v-if="currentId">{{ locapproval }}</span>
              <span v-if="!currentId" @click.prevent="goToJob(item.Id)" class="lostandfound__approval--link">{{ locapproval }}</span>
              <button
                v-if="currentId && editPermission"
                class="button"
                @click.prevent="approveAd(item.Id, pageid)"
              >{{ locapprove }}</button>
            </div>
            <a
              v-if="!currentId"
              class="lostandfound__title"
              href="#"
              @click.prevent="goToJob(item.Id)"
            >{{ item.Title }}</a>
            <div v-if="currentId" class="lostandfound__title">{{ item.Title }}</div>
            <div
              v-if="!currentId && item.Images.length && item.Images.filter(img => img.Url)[0] && item.Images.filter(img => img.Url)[0].Url"
              class="lostandfound__image"
              :style="`background-image: url('${baseurl}/${item.Images.filter(img => img.Url)[0].Url}')`"
            >
              <span>{{ item.Images.filter(img => img.Url).length }} {{ locpic }}{{ item.Images.filter(img => img.Url).length > 1 ? 's' : '' }}</span>
            </div>
            <div v-if="currentId && item.Images.length > 0" class="lostandfound__images">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div
                    v-for="image in item.Images"
                    :key="image.Url"
                    class="swiper-slide"
                  >
                  <v-lazy-image :src="`${baseurl}/${image.Url}`" alt=""/>
                    <!-- <v-lazy-image :src="`${baseurl}/${image.Url}`" /> -->
                  </div>
                </div>

                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>

                <!-- Add Arrows -->
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
              </div>
            </div>
            <div
              class="lostandfound__summary"
            >{{ item.Description.substr(0, currentId ? item.Description.length : 200) }}{{ !currentId && item.Description.length > 200 ? '&hellip;' : '' }}</div>
            <h4>{{ loccontact }}</h4>
            <div class="flex flex__center flex__space-between">
              <a class="lostandfound__profile" :href="'/profile?id=' + item.Account">
                <div class="lostandfound__photo">
                  <v-lazy-image :src="pictures[item.Account] || '/assets/intranet/Images/Profile.svg'" />
                </div>
                <div class="lostandfound__info" v-if="profiles[item.Account]">
                  <div>
                    <b>{{ profiles[item.Account].DisplayName }}</b>
                  </div>
                  <div>{{ $utils.profileProp(profiles[item.Account], 'Department') }}</div>
                  <div>{{ $utils.profileProp(profiles[item.Account], 'Office') }}</div>
                </div>
              </a>
              <div class="btn-back">
                <button class="button button--default" @click="goBack()">{{ locall }}</button>
              </div>
            </div>
            
            <div class="lostandfound__delete" v-if="currentId && editPermission">
              <a href="#" @click.prevent="deleteAd(item.Id, pageid)">{{ locdelete }}</a>
            </div>
          </div>
        </div>
        <div
          v-if="!isItemsLoading && !addForm && items.length === 0"
          class="lostandfound__empty"
        >{{ locempty }}</div>
      </div>
    </div>
  </div>
</template>
<script>

import API from './api'
// import forEach from 'lodash/forEach'
import Masonry from 'masonry-layout'
import Swiper from 'swiper'
import Datepicker from 'vuejs-datepicker'

const moment = require('moment')

const Language = function (language, months, monthsAbbr, days) {
  this.language = language
  this.months = months
  this.monthsAbbr = monthsAbbr
  this.days = days
  this.rtl = false
  this.ymd = false
  this.yearSuffix = ''
}
const pt = new Language(
  'Brazilian',
  ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
)

const enUs = new Language(
  'English',
  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
)

export default {
  name: 'lost-and-found',
  props: [
    'baseurl',
    'lang',
    'locadd',
    'loccontact',
    'loccancel',
    'locdelete',
    'locall',
    'locsave',
    'loctitle',
    'locdesc',
    'loctype',
    'loctypelost',
    'loctypefound',
    'lococurrencedate',
    'locpic',
    'locimage',
    'locconfirm',
    'locempty',
    'locapproval',
    'locapprove',
    'tenant',
    'canedit',
    'pageid'
  ],
  data() {
    return {
      profiles: {},
      pictures: {},
      items: [],
      isItemsLoading: false,
      currentId: 0,
      applied: false,
      invalidFile: false,
      addForm: false,
      form: {
        type: '1',
        title: null,
        description: null,
        ocurrencedate: null
      },
      language: pt,
      invalid: [],
      saving: false
    }
  },
  components: {
    Datepicker
  },
  mounted() {
    this.language = this.lang === 'pt' ? pt : enUs
    this.loadItems()
  },
  watch: {
    $route(to) {
      this.currentId = to.query && to.query.id ? parseInt(to.query.id, 10) : 0
      this.addForm = to.query && to.query.add
    }
  },
  updated() {
    // masonry
    if (this.currentId && (!this.$route.query || !this.$route.query.id)) {
      this.currentId = null
    }

    if (window.classifiedsBoard) {
      window.classifiedsBoard.destroy()
    }

    if (window.classifiedSwiper) {
      window.classifiedSwiper.destroy(true, true)
    }

    if (!this.currentId && document.querySelector('.lostandfound__container')) {
      window.classifiedsBoard = new Masonry('.lostandfound__container', {})
    }

    if (
      this.currentId &&
      document.querySelector('.lostandfound__detail .swiper-wrapper')
    ) {
      window.classifiedSwiper = new Swiper(
        '.lostandfound__detail .swiper-container',
        {
          slidesPerView: 'auto',
          watchSlidesVisibility: true,
          centeredSlides:
            this.items.find(i => i.ID === this.currentId) &&
            this.items
              .find(i => i.ID === this.currentId)
              .Images.filter(i => i.Url).length <= 1,
          pagination: {
            el: '.lostandfound__detail .swiper-pagination'
          },
          navigation: {
            nextEl: '.lostandfound__detail .swiper-button-next',
            prevEl: '.lostandfound__detail .swiper-button-prev'
          }
        }
      )
    }
  },
  computed: {
    editPermission() {
      return this.canedit === 'true'
    }
  },
  methods: {
    loadItems() {
      this.isItemsLoading = true
      this.addForm = this.$route.query && this.$route.query.adds
      API.get(this.baseurl, this.canedit).then(resp => {
        this.isItemsLoading = false
        this.items = resp.map(item => {
          item.Account = item.Author.split('\\')[1]
          return item
        })

        this.currentId = this.$route.query ? parseInt(this.$route.query.id, 10) : 0
        this.items.forEach(item => {
          if (item.AuthorEmail) {
            this.$msalAPI.getDataAPI(
                `_api/sp.userprofiles.peoplemanager/getpropertiesfor(@v)?@v='i%3A0%23.f%7Cmembership%7C${item.AuthorEmail}'`
              )
              .then(profile =>
                this.$set(this.profiles, item.Account, profile.data.d)
              )
          }
          /* eslint-disable */
          if (!this.pictures.hasOwnProperty(item.Account))
          /* eslint-enable */
          {
            this.$msalAPI.getFileAPI(`_api/web/GetFileByServerRelativeUrl('/User%20Photos/Profile Pictures/${item.Author.split('\\')[1].replace(/\./gi, '_')}_${this.$msal.account.userName.split('@')[1]
                .replace(/\./gi, '_')}_LThumb.jpg')/$value`,
              { encoding: null, responseType: 'arraybuffer' }
            )
            .then(resp2 => {
              const image = `data:image/jpeg;base64,${new Buffer(
                resp2.data
              ).toString('base64')}`
              this.$set(this.pictures, item.Account, image)
            })
            .catch(err => {
              this.$set(this.pictures, item.Account, null)
              window.console.log('LostAndFound - _api/web/GetFileByServerRelativeUrl', err)
            })
          }
        })
      })
    },
    goToJob(id) {
      window.scrollTo(0, 0)
      this.currentId = parseInt(id, 10)
      this.$router.push({ query: { id } })
    },
    goBack() {
      this.$router.push({ query: null })
    },
    saveAd() {
      const formData = new FormData()

      if (!this.form.title) {
        this.invalid.push('title')
      } else {
        this.invalid = this.invalid.filter(i => i !== 'title')
      }

      if (!this.form.description) {
        this.invalid.push('description')
      } else {
        this.invalid = this.invalid.filter(i => i !== 'description')
      }

      if (!this.form.ocurrencedate) {
        this.invalid.push('ocurrencedate')
      } else {
        this.invalid = this.invalid.filter(i => i !== 'ocurrencedate')
      }
      const files = this.$refs.postedimages.files
      for (let i = 0; i < files.length; i++) {
        formData.append('PostedImages', files[i])
      }
      formData.append('Title', this.form.title)
      formData.append('Description', this.form.description)
      formData.append('OcurrenceDate', moment(this.form.ocurrencedate).format('YYYY-MM-DDTHH:mm:ss'))
      formData.append('Type', this.form.type)
      formData.append('PageId', this.pageid)

      if (this.invalid.length === 0) {
        this.saving = true

        API.add(this.baseurl, formData)
          .then(resp => {
            this.saving = false
            this.$router.push({ query: { id: resp } })
            this.loadItems()
          })
          .catch(err => {
            this.saving = false
            window.console.log('Lost and found - saveAd', err)
          })
      }
    },
    addItem() {
      this.invalid = []
      this.form = {
        type: '1',
        title: null,
        description: null,
        ocurrenceDate: null
      }
      this.$router.push({ query: { add: true } })
    },
    cancelAddItem() {
      this.$router.push({ query: null })
    },
    deleteAd(id) {
      /* eslint-disable */
      if (window.confirm(this.locconfirm)) {
      /* eslint-enable */
        API.delete(this.baseurl, id, this.pageid).then(() => {
          this.loadItems()
          this.$router.push({ query: null })
        })
      }
    },
    approveAd(id) {
      API.approve(this.baseurl, id, this.pageid).then(() => {
        this.loadItems()
        this.$router.push({ query: { id } })
      })
    }
  }
}
</script>