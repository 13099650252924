<template>
  <div class="form-element">
    <div class="input-group">
      <div class="input-group--label">
          <label :name="name" v-html="label"></label>
        </div>
        <div class="input-group-elements t-block">
          <textarea
            :name="name"
            :id="name"
            v-model="inputValue"
            :placeholder="this.inputPlaceholder"
            :value="value"
            :aria-label="ariaLabel"
            :aria-labelledby="ariaLabel"
            v-on:input="onInput"
            :class="{ 'error': !valid }"
          ></textarea>
          <span class="error" :class="{'show': !valid}">
            {{ errorText }}
          </span>
        </div>
    </div>
  </div>
</template>

<script>
  import Util from '../../../utils/utils'
  
  export default {
    name: 'form-textarea',
    props: {
      name: {
        type: String,
      },
      label: {
        type: String,
      },
      errorText: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      validation: {
        type: Object
      },
      validate: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        inputValue: this.value,
        inputName: this.name,
        inputPlaceholder: this.placeholder
      }
    },
    methods: {
      onInput(event) {
        this.$emit('input', event.target.value)
      },
    },
    computed: {
      ariaLabel() {
        return this.name.trim()
      },
      valid() {
        if (!this.validation) {
          return true
        }

        if (this.validate) {
          return Util.get(this.validation.$valid, this.name)
        }

        if (Util.get(this.validation.$dirty, this.name)) {
          return Util.get(this.validation.$valid, this.name)
        }

        return true
      }
    }
  }
</script>
