<template>

  <div class="container events__wrapper">

    <div class="row">
      <div class="col events">
        <div v-if="isItemsLoading" class="loading__container">
          <div class="loading loading--invert"><div></div><div></div><div></div></div>
        </div>
        <div v-if="!isItemsLoading && items.length > 0" :class="currentId ? 'events__detail' : 'events__container'">

          <div class="event" v-for="item in items.filter(item => !currentId || item.ID === currentId)" :key="item.ID">
            <div class="events__date">
              <div class="events__month">{{ $utils.months(lang || 'en')[item.month - 1].substr(0, 3) }}</div>
              <div class="events__day">{{ (item.day + "").padStart(2, '0') }}</div>
            </div>
            <div class="events__info">
              <div v-if="item.Name" class="events__title">{{ item.Name }}</div>
              <div v-if="item.StartDate || item.EndDate">
                <h4>{{ locdate }}</h4>
                <div><span v-if="item.StartDate">{{ moment(item.StartDate).format(format) }}</span><span v-if="item.EndDate">&nbsp;<i class="fa fa-arrows-h"></i> {{ moment(item.EndDate).format(format) }}</span></div>
              </div>
              <div v-if="item.Location">
                <h4>{{ locplace }}</h4>
                <div>{{ item.Location }}</div>
              </div>
              <div v-if="item.Audience">
                <h4>{{ locaudience }}</h4>
                <div>{{ item.Audience }}</div>
              </div>
              <div v-html="item.Description" v-if="currentId" class="events__content"></div>
            </div>
            <div class="btn-back" v-if="!currentId">
              <a href="#" class="button button--default" @click.prevent="goToJob(item.ID)">{{ locdetails }}</a>
            </div>
            <div class="btn-back" v-else>
              <a href="#" class="button button--default" @click.prevent="goBack()">{{ locback }}</a>
            </div>
          </div>

        </div>
        <div class="events__empty" v-if="!isItemsLoading && items.length === 0">
          {{ locempty }}
        </div>
      </div>

    </div>
  </div>

</template>

<script>

/* eslint-disable */

import API from "./api"
import forEach from "lodash/forEach"
import moment from 'moment'
import 'moment/locale/pt-br'

export default {
  name: "events",
  props: ["baseurl", "locdate", "locplace", "locaudience", "locdetails", "locback", "locempty", "lang"],
  data: function() {
    return {
      items: [],
      isItemsLoading: false,
      currentId: this.$route.query ? this.$route.query.id : null,
      applied: false,
      invalidFile: false,
      moment,
      format: this.lang === 'pt' ? 'DD [de] MMMM, YYYY - HH:mm' : 'MMMM DD, YYYY - hh:mm A'
    };
  },
  mounted: function() {
    
    this.isItemsLoading = true
    moment.locale(!this.lang ? "en" : (this.lang === 'pt' ? 'pt-br' : this.lang))

    API.events(this.baseurl, this.datasource).then(resp => {
      
      this.isItemsLoading = false
      
      let items = resp
        .map(item => {
          var date = new Date(item.StartDate)
          var today = new Date()
          item.month = date.getMonth() + 1
          item.day = date.getDate()
          return item
        })
        
      this.items = items
    })
    
  },
  watch:{
    $route (to, from){
      this.currentId = to.query ? to.query.id : null
    }
  },
  methods: {
    goToJob(id) {
      this.currentId = id
      window.scrollTo(0,0)
      this.$router.push({ query: { id }})
    },
    goBack() {
      window.history.back();
    }
  }

}

</script>