import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/Polls'
  },
  poll(urlBase, id) {
    return Axios.get(`${urlBase || this.options.urlBase}/GetPolls?id=${id}`).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.poll()', error)
    })
  },
  vote(urlBase, id, vote) {
    return Axios.get(`${urlBase || this.options.urlBase}/VotePoll?id=${id}&vote=${vote}`).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.vote()', error)
    })
  }
}

export default API
