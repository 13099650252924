/**
 * v-lazy-image v1.2.2
 * (c) 2018 Alex Jover Morales <alexjovermorales@gmail.com>
 * @license MIT
 */

(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
	typeof define === 'function' && define.amd ? define(['exports'], factory) :
	(factory((global.VLazyImage = {})));
}(this, (function (exports) { 'use strict';

var VLazyImageComponent = {
  props: {
    src: {
      type: String,
      required: true
    },
    srcPlaceholder: {
      type: String,
      default: ""
    },
    srcset: {
      type: String
    },
    intersectionOptions: {
      type: Object,
      default: function () { return ({}); }
    }
  },
  data: function () { return ({ observer: null, intersected: false, loaded: false }); },
  computed: {
    srcImage: function srcImage() {
      return this.intersected ? this.src : this.srcPlaceholder;
    },
    srcsetImage: function srcsetImage() {
      return this.intersected && this.srcset ? this.srcset : false;
    }
  },
  render: function render(h) {
    return h("img", {
      attrs: { src: this.srcImage, srcset: this.srcsetImage },
      class: {
        "v-lazy-image": true,
        "v-lazy-image-loaded": this.loaded
      }
    });
  },
  mounted: function mounted() {
    var this$1 = this;

    this.$el.addEventListener("load", function (ev) {
      if (this$1.$el.getAttribute('src') !== this$1.srcPlaceholder) {
        this$1.loaded = true;
        this$1.$emit("load");
      }
    });

    this.observer = new IntersectionObserver(function (entries) {
      var image = entries[0];
      if (image.isIntersecting) {
        this$1.intersected = true;
        this$1.observer.disconnect();
        this$1.$emit("intersect");
      }
    }, this.intersectionOptions);

    this.observer.observe(this.$el);
  },
  destroyed: function destroyed() {
    this.observer.disconnect();
  }
};

var VLazyImagePlugin = {
  install: function (Vue, opts) {
    Vue.component("VLazyImage", VLazyImageComponent);
  }
};

exports['default'] = VLazyImageComponent;
exports.VLazyImagePlugin = VLazyImagePlugin;

Object.defineProperty(exports, '__esModule', { value: true });

})));
