import SitecoreModeService from '../services/sitecore-mode-service'

export default {
  sitecoreModeService: SitecoreModeService,
  keyAttributeName: 'key',
  tempKeyAttributeName: 'data-phkey',

  InitialiseVueApp(initialiseVueApp) {
    this.BuildSitecorePlaceholderMap()
    initialiseVueApp()
    this.EnsureSitecorePlaceholderKeys()
  },

  BuildSitecorePlaceholderMap() {
    if (this.sitecoreModeService.IsExperienceEditor()) {
      const pagePlaceholders = document.querySelectorAll(`code[${this.keyAttributeName}]`)

      this.CopyElementsAttribute(pagePlaceholders, this.keyAttributeName, this.tempKeyAttributeName)
    }
  },

  EnsureSitecorePlaceholderKeys() {
    if (this.sitecoreModeService.IsExperienceEditor()) {
      const pagePlaceholders = document.querySelectorAll(`code[${this.tempKeyAttributeName}]`)

      this.CopyElementsAttribute(pagePlaceholders, this.tempKeyAttributeName, this.keyAttributeName, true)
    }
  },

  CopyElementsAttribute(elements, fromAttributeName, toAttributeName, removeFromAttribute = false) {
    elements.forEach(placeholder => {
      placeholder.setAttribute(toAttributeName, placeholder.getAttribute(fromAttributeName) || '')

      if (removeFromAttribute) {
        placeholder.removeAttribute(fromAttributeName)
      }
    })
  }
}
