import Axios from 'axios'

const API = {
  options: {
    url: '/assets/intranet/api/sitecore/profile/getperson'
  },
  profile(url, username) {
    return Axios.get(`${url || this.options.url}?username=${username}`).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.profile()', error)
    })
  }
}

export default API
