<template>
  <div class="form-element">
    <div class="checkbox-wrapper">
      <label class="form-checkbox label" v-for="option in options" :class="{'disabled': disabled}" :key="option.name">
        <i>label</i>
        <input
          type="checkbox"
          :value="option.value"
          :disabled="disabled"
          :checked="isChecked(option.value)"
          :id="name"
          @change="onChange(option.value)"
          :v-model="isChecked(option.value)"
          tabindex="0"
          :aria-labelledby="ariaLabel">
        <span class="input-label" v-html="option.label" @click="targetclick" :aria-label="ariaLabel"></span>
        <span class="checkmark"></span>
      </label> 
    </div>
    <span class="error" :class="{'show': !valid}">
      {{ errorText }}
    </span>
  </div>

</template>
<script>
  import Util from '../../../utils/utils'
  
  export default {
    name: 'form-checkbox',
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      selected: {
        type: Array,
        default: []
      },
      errorText: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean
      },
      group: {
        type: String
      },
      options: {
        type: Array
      },
      validation: {
        type: Object
      },
      validate: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      this.$root.$on('clear-form', function (group) {
        if (this.checkboxGroup === group) {
          this.checked = false
        }
      }.bind(this))

      this.$root.$on('clear-form-checkbox', function (group) {
        if (this.checkboxGroup === group) {
          this.checked = false
        }
      }.bind(this))
    },
    data() {
      return {
        checked: this.selected,
        uid: `${this.name}-${Math.floor(Math.random() * (10)) + 1}`,
        checkboxGroup: this.group
      }
    },
    methods: {
      isChecked(value) {
        return this.checked.includes(value)
      },
      targetclick(event) {
        const include = ['a']
        const tag = event ? event.target.tagName.toLowerCase() : ''

        if (include.includes(tag)) {
          this.$root.$emit('remote-checkbox-target', { target: this.target })
        }
      },
      onChange(value) {
        this.toogleSelectedData(value)

        this.$parent.$emit('checkbox-change', {
          name: this.name,
          value: this.checked
        })
      },
      toogleSelectedData(value) {
        if (this.checked.includes(value)) {
          this.checked = this.checked.filter(item => item !== value)
          return
        }
        this.checked.push(value)
      }
    },
    computed: {
      ariaLabel() {
        return this.name.trim()
      },
      valid() {
        if (!this.validation) {
          return true
        }

        if (this.validate) {
          return Util.get(this.validation.$valid, this.name)
        }

        if (Util.get(this.validation.$dirty, this.name)) {
          return Util.get(this.validation.$valid, this.name)
        }

        return true
      }
    }
  }
</script>
