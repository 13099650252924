import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/intranet/Like',
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    }
  },
  submitPesquisa(urlBase, data) {
    const fd = new FormData()
    ParseJsonFormData(Object.values(data), Object.keys(data), fd)
    return Axios.post(urlBase || this.options.urlBase, fd, { headers: this.options.headers }).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.submitPesquisa()', error)
    })
  }
}

export default API
function ParseJsonFormData(data, fields, fd) {
  for (let i = 0; i < data.length; i++) {
    fd.append(fields[i], data[i])
  }
}

