<template>
  
<div class="container newscenter">
  
    <div class="row">

      <div class="col-12 col-lg-auto news-filter">
        
        <ul>
          <li><h4>{{ loctype }}</h4></li>
          <li v-for="type in types" :key="type.Name" :class="(selectedType && type.Name === selectedType) ? 'active type' : 'type'" @click="changeType(type.Name)">
            {{ type.DisplayName || type.Name }}
          </li>
          <li><h4>{{ loccategory }}</h4></li>
          <li v-for="cat in categories" :key="cat.Name" :class="selectedCategory && cat.Name === selectedCategory ? 'active type' : 'cat'" @click="changeCategory(cat.Name)">
            {{ cat.DisplayName || cat.Name }}
          </li>
        </ul>

      </div>

      <div class="col news-items" v-if="items.length > 0">

        <a :class="`row ${item.Type ? typeClasses[item.Type.Name] : ''}`" :href="item.Url" v-for="item in items" :key="item.Id">
          <div class="col news-item">
            <div class="news-item__photo" v-if="item.Thumbnail">
              <v-lazy-image :src="item.ThumbnailSrcMobile"/>
            </div>
            <div class="news-item__info">
              <div class="news__tag">
                <span class="tag" v-if="item.Category" :style="`border-color: ${item.Category.Color}`">
                  <span class="tag__bullet" :style="`color: ${item.Category.Color}`" v-if="item.Type.Name != 'News'">
                    <i class="fa fa-circle" v-if="item.Type.Name === 'Management'"></i>
                    <i class="fa fa-star" v-if="item.Type.Name === 'Announcements'"></i>
                  </span>
                  {{ item.Category.DisplayName || item.Category.item }}
                </span>
              </div>
              <h2>{{ item.Title }}</h2>
              <p>{{ item.Summary }}</p>
              <div class="news-item__date">{{ item.PublishedDateUI }}</div>
            </div>
          </div>
        </a>

        <div class="row news-actions" v-if="!lastPage">
          <button @click="loadMore" class="button">{{ locmore }}</button>
        </div>

      </div>

      <div class="col news-items" v-if="!isItemsLoading && items.length === 0">
        {{ locempty }}
      </div>

      <div class="col news-items" v-if="isItemsLoading">
        <div class="loading loading--invert"><div></div><div></div><div></div></div>
      </div>

    </div>

</div>

</template>
<script>
import API from './api'
import 'url-search-params-polyfill'

const qs = new URLSearchParams(window.location.search)

export default {
  name: 'news',
  props: [
    'baseurl',
    'locempty',
    'loctype',
    'loccategory',
    'locmore'
  ],
  data() {
    return {
      isItemsLoading: false,
      items: [],
      types: [],
      categories: [],
      selectedType: qs.get('type'),
      selectedCategory: qs.get('category'),
      page: 1,
      typeClasses: {
        News: 'latestnews',
        Management: 'management',
        Announcements: 'updates',
      },
      lastPage: false
    }
  },
  mounted() {
    this.getNews()
  },
  methods: {
    changeType(name) {
      this.page = 1
      this.selectedType = this.selectedType !== name ? name : null
      this.getNews()
    },
    changeCategory(name) {
      this.page = 1
      this.selectedCategory = this.selectedCategory !== name ? name : null
      this.getNews()
    },
    loadMore() {
      this.page += 1
      this.getNews(true)
    },
    getNews(append) {
      this.isItemsLoading = true

      API.news(this.baseurl, this.selectedType, this.selectedCategory, this.page).then(resp => {
        if (append) {
          this.items = this.items.concat(resp.items)
        }
        else {
          this.items = resp.items
        }

        this.isItemsLoading = false
        this.types = resp.types
        this.categories = resp.categories
        this.lastPage = resp.items.length < (qs.has('pageSize') ? qs.get('pageSize') : 10)
      })
    }
  }
}

</script>