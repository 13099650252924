<template>

<div class="container classifieds__wrapper">

  <div class="row">
    <div class="col classifieds">
      <div class="classifieds__actions" v-if="!addForm">
        <a href="#" class="button classifieds__add" @click.prevent="addItem">{{ locadd }}</a>
      </div>
      <div v-if="addForm" class="container dc">
        <form method="post" enctype="multipart/form-data" novalidate>
          <h2>{{ locadd }}</h2>
          <div class="row">
            <div class="col">
              <div class="form-label">{{ loctitle }}</div><span v-if="invalid.indexOf('title') > -1" class="invalid">*</span>
              <input name="title" type="text" v-model="form.title" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-label">{{ locdesc }}</div><span v-if="invalid.indexOf('summary') > -1" class="invalid">*</span>
              <textarea name="message" v-model="form.summary"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-label">{{ loctype }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="adtype1"><input v-model="form.type" type="radio" name="adtype" id="adtype1" value="1" /> {{ loctypesell }}</label>
              <label for="adtype2"><input v-model="form.type" type="radio" name="adtype" id="adtype2" value="2" /> {{ loctypedonation }}</label>
              <label for="adtype3"><input v-model="form.type" type="radio" name="adtype" id="adtype3" value="3" /> {{ loctypenoprice }}</label>
            </div>
          </div>
          <div class="row" v-if="form.type === '1'">
            <div class="col col-auto">
              <div class="form-label">{{ locprice }}</div><span v-if="invalid.indexOf('price') > -1" class="invalid">*</span>
              <input name="price" type="text" v-model="form.price" />
            </div>
            <div class="col">
              <div class="form-label">{{ loccurrency }} </div>
              <select name="price" type="text" v-model="form.currency">
                <option v-for="curr in currencies.split('|').filter(c => c)" :key="curr">{{ curr }}</option>
              </select>
            </div>
          </div>
          <div class="row" ref="files">
            <div class="col col-12 col-md-6 col-lg-4">
              <div class="form-label">{{ locimage }} 1</div>
              <input type="file" ref="upload1" name="file1" @change="onAddFile" :accept="getAcceptFiles">
              <span class="error-in-file"></span>
            </div>
            <div class="col col-12 col-md-6 col-lg-4">
              <div class="form-label">{{ locimage }} 2</div>
              <input type="file" ref="upload2" name="file2" @change="onAddFile" :accept="getAcceptFiles">
              <span class="error-in-file"></span>
            </div>
            <div class="col col-12 col-md-6 col-lg-4">
              <div class="form-label">{{ locimage }} 3</div>
              <input type="file" ref="upload3" name="file3" @change="onAddFile" :accept="getAcceptFiles">
              <span class="error-in-file"></span>
            </div>
            <div class="col col-12 col-md-6 col-lg-4">
              <div class="form-label">{{ locimage }} 4</div>
              <input type="file" ref="upload4" name="file4" @change="onAddFile" :accept="getAcceptFiles">
              <span class="error-in-file"></span>
            </div>
            <div class="col col-12 col-md-6 col-lg-4">
              <div class="form-label">{{ locimage }} 5</div>
              <input type="file" ref="upload5" name="file5" @change="onAddFile" :accept="getAcceptFiles">
              <span class="error-in-file"></span>
            </div>
            <div class="col col-12 col-md-6 col-lg-4">
              <div class="form-label">{{ locimage }} 6</div>
              <input type="file" ref="upload6" name="file6" @change="onAddFile" :accept="getAcceptFiles">
              <span class="error-in-file"></span>
            </div>
            <div class="col col-12">
              <label class="msg-files">{{msgMaxSize}} {{maxSize}}MB</label>
              <label class="msg-files">{{msgTypes}}</label>
            </div>
          </div>
          <div class="classifieds__actions--form">
            <button class="button" @click.prevent="saveAd()" :disabled="saving">{{ locsave }}</button>
            <a href="#" class="classifieds__add" @click.prevent="cancelAddItem()">{{ loccancel }}</a>
          </div>
        </form>
      </div>
      <div v-if="isItemsLoading" class="loading__container">
        <div class="loading loading--invert"><div></div><div></div><div></div></div>
      </div>
      <div v-if="!isItemsLoading && !addForm" :class="currentId ? 'classifieds__detail' : 'classifieds__container'">
        <div class="classified" v-for="item in items.filter(item => !currentId || item.ID === currentId)" :key="item.ID">
          <div v-if="!item.Approved" class="classifieds__approval">
            {{ locapproval }}
            <button v-if="currentId && canedit" class="button" @click.prevent="approveAd(item.ID, pageid)">{{ locapprove }}</button>
          </div>
          <a v-if="!currentId" class="classifieds__title" href="#" @click.prevent="goToJob(item.ID)">{{ item.Name }}</a>
          <div v-if="currentId" class="classifieds__title">{{ item.Name }}</div>
          <div v-if="!currentId && item.Images.filter(img => img.Url)[0] && item.Images.filter(img => img.Url)[0].Url" class="classifieds__image" :style="`background-image: url('${baseurl}/${item.Images.filter(img => img.Url)[0].Url}')`">
            <span>{{ item.Images.filter(img => img.Url).length }} {{ locpic }}{{ item.Images.filter(img => img.Url).length !== 1 ? 's' : '' }}</span>
          </div>
          <div v-if="currentId && item.Images.length > 0" class="classifieds__images">
            
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div v-for="image in item.Images.filter(img => img.Url)" :key="image.Url" class="swiper-slide">
                  <v-lazy-image :src="`${baseurl}/${image.Url}`"/>
                </div>
              </div>
              
              <!-- Add Pagination -->
              <div class="swiper-pagination"></div>
              
              <!-- Add Arrows -->
              <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>

            </div>

          </div>
          <div v-if="item.Type === 1" class="classifieds__price">{{ item.Currency || 'R$' }} {{ (!item.Currency || item.Currency === 'R$') ? item.Price.toFixed(2).replace('.', ',') : item.Price.toFixed(2) }}</div>
          <div v-if="item.Type === 3" class="classifieds__price">{{ locnoprice }}</div>
          <div v-if="item.Type === 2" class="classifieds__price classifieds__donation">{{ locdonation }}</div>
          <div class="classifieds__summary">{{ item.Summary.substr(0, currentId ? item.Summary.length : 200) }}{{ !currentId && item.Summary.length > 200 ? '&hellip;' : '' }}</div>
          <h4>{{ loccontact }}</h4>
          <a class="classifieds__profile" :href="'/profile?id=' + item.Account">
            <div class="classifieds__photo" v-if="pictures[item.Account]">
              <v-lazy-image :src="pictures[item.Account]"/>
            </div>
            <div class="classifieds__info" v-if="profiles[item.Account]">
              <div><b>{{ profiles[item.Account].DisplayName }}</b></div>
              <div>{{ $utils.profileProp(profiles[item.Account], 'Department') }}</div>
              <div>{{ $utils.profileProp(profiles[item.Account], 'Office') }}</div>
            </div>
          </a>
          <div class="btn-back"><button class="button button--default" @click="goBack()">{{ locall }}</button></div>
          <div class="classifieds__delete" v-if="currentId && (item.CanEdit || canedit)">
            <a href="#" @click.prevent="deleteAd(item.ID, pageid)">{{ locdelete }}</a>
          </div>
        </div>
      </div>
      <div v-if="!isItemsLoading && !addForm && items.length === 0" class="classifieds__empty">
        {{ locempty }}
      </div>
    </div>
  </div>
</div>

</template>
<script>

/* eslint-disable */

import API from "./api"
import { forEach, each, some, filter, find, includes } from "lodash"
import Masonry from 'masonry-layout'
import Swiper from 'swiper'

export default {
  name: "classifieds",
  props: [
    "baseurl", "lang", "locnoprice", "locdonation", "locpic", "locadd", "loccontact", "loccancel", 
    "locdelete", "locall", "locsave", "loctitle", "locdesc", "loctype", "locprice", "loccurrency", 
    "loctypesell", "loctypedonation", "loctypenoprice", "locimage", "locconfirm", "locempty", "locapproval",
    "locapprove", "currencies", "tenant", "canedit", "pageid", "maxSize", "msgFileSizeError", "msgFileFormatError", 
    "msgTypes", "msgMaxSize"],
  data: function() {
    return {
      profiles: {},
      pictures: {},
      items: [],
      isItemsLoading: false,
      currentId: 0,
      applied: false,
      invalidFile: false,
      addForm: false,
      form: {
        type: "1",
        title: null,
        summary: null,
        price: null,
        currency: this.lang === 'pt' ? 'R$' : '$'
      },
      invalid: [],
      saving: false,
      acceptFiles: ["image/jpeg", "image/jpg", "image/png"],
      errors: []
    };
  },
  computed: {
    maxSizeFile() {
      var size = this.maxSize ? parseInt(this.maxSize) : 8
      return 1048576 * size;
    },
    getAcceptFiles() {
      return this.acceptFiles.toString()
    }
  },
  mounted: function() {
    this.loadItems()
  },
  watch:{
    $route (to, from){
      this.currentId = to.query && to.query.id ? parseInt(to.query.id, 10) : 0
      this.addForm = to.query && to.query.add
    },
    errors(errors) {
      var wrapper = this.$refs.files
      var files = wrapper.querySelectorAll('input[type="file"]')

      each(files, (file) => {
        var name = file.name
        var theError = find(errors, { field: name });

        if (theError) {
          var el = file.parentElement
          var error = el.querySelector('.error-in-file')
          error.innerText = theError.message
        } else {
          var el = file.parentElement
          var error = el.querySelector('.error-in-file')
          error.innerText = ''
        }
      })
    }
  },
  
  updated: function() {

    // masonry
    if(this.currentId && (!this.$route.query || !this.$route.query.id))
      this.currentId = null

    if(window.classifiedsBoard)
      window.classifiedsBoard.destroy()

    if(window.classifiedSwiper)
      window.classifiedSwiper.destroy( true, true );

    if(!this.currentId && document.querySelector('.classifieds__container'))
      window.classifiedsBoard = new Masonry('.classifieds__container', { });
    
    if(this.currentId && document.querySelector('.classifieds__detail .swiper-wrapper')) {
      window.classifiedSwiper = new Swiper('.classifieds__detail .swiper-container', {
        slidesPerView: 'auto',
        watchSlidesVisibility: true,
        centeredSlides: this.items.find(i => i.ID === this.currentId) && this.items.find(i => i.ID === this.currentId).Images.filter(i => i.Url).length <= 1,
        pagination: {
          el: '.classifieds__detail .swiper-pagination'
        },
        navigation: {
          nextEl: '.classifieds__detail .swiper-button-next',
          prevEl: '.classifieds__detail .swiper-button-prev',
        }
      })
    }
  },

  methods: {
    onAddFile() {
      var { files } = event.target
      var file = files[0]

      if (file && !some(this.errors, { field: event.target.name })) {
        if (file.size > this.maxSizeFile) {
          this.errors.push({
            field: event.target.name,
            element: event.target,
            message: this.msgFileSizeError
          })
          return
        }

        if (!includes(this.acceptFiles, file.type)) {
          this.errors.push({
            field: event.target.name,
            element: event.target,
            message: this.msgFileFormatError
          })
          return
        }
      } else {
        this.errors = filter(this.errors, (error) => {
          return error.field !== event.target.name
        })
      }
    },
    loadItems() {
      this.isItemsLoading = true
      this.addForm = this.$route.query && this.$route.query.add

      API.classifieds(this.baseurl, this.pageid).then(resp => {

        this.isItemsLoading = false
        this.items = resp.map(item => {
          item.Account = item.Author.Name.split("\\")[1]
          return item
        })

        this.currentId = this.$route.query ? parseInt(this.$route.query.id, 10) : 0
        this.items.forEach(item => {
          let email = item.Author.Email || `${item.Account}@${this.tenant}`
          this.$msalAPI.getDataAPI(`_api/sp.userprofiles.peoplemanager/getpropertiesfor(@v)?@v='i%3A0%23.f%7Cmembership%7C${email}'`)
            .then(profile => this.$set(this.profiles, item.Account, profile.data.d))

            if(!this.pictures.hasOwnProperty(item.Account))
              this.$msalAPI.getFileAPI(`_api/web/GetFileByServerRelativeUrl('/User%20Photos/Profile Pictures/${item.Account.replace(/\./ig, "_")}_${this.$msal.account.userName.split('@')[1].replace(/\./ig, "_")}_LThumb.jpg')/$value`, { encoding: null, responseType: "arraybuffer" })
                .then(resp => {
                  const image = `data:image/jpeg;base64,${new Buffer(resp.data).toString('base64')}`
                  this.$set(this.pictures, item.Account, image)
                }).catch(err => {
                  this.$set(this.pictures, item.Account, null)
                })
          })
      })
    },
    goToJob(id) {
      window.scrollTo(0,0)
      this.currentId = parseInt(id, 10)
      this.$router.push({ query: { id }})
    },
    goBack() {
      this.$router.push({ query: null })
    },
    saveAd() {
      if (this.errors.length) return;

      const formData = new FormData()

      let file1 = this.$refs.upload1.files[0]
      let file2 = this.$refs.upload2.files[0]
      let file3 = this.$refs.upload3.files[0]
      let file4 = this.$refs.upload4.files[0]
      let file5 = this.$refs.upload5.files[0]
      let file6 = this.$refs.upload6.files[0]
    
      if(!this.form.title)
        this.invalid.push('title')
      else
        this.invalid = this.invalid.filter(i => i !== 'title')

      if(!this.form.summary)
        this.invalid.push('summary')
      else
        this.invalid = this.invalid.filter(i => i !== 'summary')

      if(this.form.type === "1" && !this.form.price)
        this.invalid.push('price')
      else
        this.invalid = this.invalid.filter(i => i !== 'price')

      if(file1)
        formData.append('file1', file1, file1.name)
      
      if(file2)
        formData.append('file2', file2, file2.name)

      if(file3)
        formData.append('file3', file3, file3.name)

      if(file4)
        formData.append('file4', file4, file4.name)

      if(file5)
        formData.append('file5', file5, file5.name)

      if(file6)
        formData.append('file6', file6, file6.name)

      formData.append('title', this.form.title)
      formData.append('currency', this.form.currency)
      formData.append('price', parseFloat((this.form.price || '').replace(',','.'), 10) || 0)
      formData.append('type', this.form.type)
      formData.append('summary', this.form.summary)

      if(this.invalid.length === 0) {
        
        this.saving = true

        API.add(this.baseurl, formData).then(resp => {
          this.saving = false

          if (resp.error) {
            window.alert(resp.error)
          } else {
            this.$router.push({ query: { id: resp.Id }})
            this.loadItems()
          }

        }).catch(err => {
          this.saving = false
        })
        
      }
    },
    addItem() {
      this.invalid = []
      this.form = {
        type: "1",
        title: null,
        summary: null,
        price: null,
        currency: this.lang === 'pt' ? 'R$' : '$'
      }
      this.$router.push({ query: { add: true }})
    },
    cancelAddItem() {
      this.$router.push({ query: null })
    },
    deleteAd(id) {
      if(confirm(this.locconfirm))
        API.delete(this.baseurl, id, this.pageid).then(() => {
          this.loadItems()
          this.$router.push({ query: null })
        })
    },
    approveAd(id) {
      API.approve(this.baseurl, id, this.pageid).then(() => {
        this.loadItems()
        this.$router.push({ query: { id } })
      })
    }
  }

}

</script>