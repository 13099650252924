import Axios from 'axios'

const API = {
  options: {
    url: '/assets/intranet/api/sitecore/intranet/search'
  },
  search(url, keyword) {
    return Axios.get(`${(url || this.options.url)}?filter=${keyword}`, { headers: { 'Cache-Control': 'no-cache, no-store' } }).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.search()', error)
    })
  }
}

export default API
