<template>
  <div class="container-fluid peopleevents widget carousel-peopleevents" v-bind:class="{ 'peopleevents-widget': iswidget }">
    <div class="container title__container">
      <div class="row justify-content-center">
          <div class="col title-people">
              <slot name="title"></slot>
              <p v-if="locintro">{{locintro}}</p>
              <hr class="line line--ligher">
          </div>
      </div>
  </div>
    <div class="row">
      <div class="col peopleevents-container">
        <div class="container-wrapper" v-if="iswidget">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in items" :key="item.ID">
                <div class="title-colaboradores">
                  <v-lazy-image :src="item.EventType.EventIcon" />
                  <h2 :style="'color: ' + item.EventType.EventColor + ';'">{{ item.EventType.EventDescription }}</h2>
                </div>
                <div class="peopleevents__item">
                  <div class="carousel-peopleevents-item__quote">
                    <p>{{ item.Description }}</p>
                    <div class="peopleevents__image--box">
                      <v-lazy-image class="peopleevents__image img-swiper" :src="item.Image" :style="'border: 5px solid ' + item.EventType.EventColor + ';'" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination d-none"></div>

            <!-- Add Arrows -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          <div class="peopleevents__more" v-if="items.length > 0">
            <a :href="urlButton" target="_self" class="button">{{ locmore }}</a>
          </div>
        </div>

        <div v-else>
          <div class="peopleevents__container flexalign-mosa">
            <div class="peopleevents__container__item" v-for="item in items" :key="item.ID">
              <div class="title-colaboradores">
                <v-lazy-image :src="item.EventType.EventIcon" />
                <h2 :style="'color: ' + item.EventType.EventColor + ';'">{{ item.EventType.EventDescription }}</h2>
              </div>
              <div class="peopleevents__item no-widget">
                <div class="carousel-peopleevents-item__quote">
                  <p>{{ item.Description }}</p>                  
                  <div class="peopleevents__image--box">
                    <v-lazy-image class="peopleevents__image" :src="item.Image" :style="'border: 5px solid ' + item.EventType.EventColor + ';'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isItemsLoading && items.length === 0" class="peopleevents__empty">
            {{ locempty }}
          </div>
          <div v-if="isItemsLoading" class="loading__container">
            <div class="loading loading--invert"><div></div><div></div><div></div></div>
          </div>
          <div class="peopleevents__more" v-if="moreItems">
            <button class="button" @click="loadMore()">{{ locmore }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import API from "./api"
import forEach from "lodash/forEach"
import Swiper from 'swiper'

export default {
  name: "peopleevents",
  props: [
    'baseurl', 'tenant', 'locempty', 'locmore', 'size', 'widget', 'urlButton', 'locintro'
  ],
  data: function() {
    return {
      profiles: {},
      pictures: {},
      iswidget: this.widget === 'true',
      items: [],
      isItemsLoading: false,
      pagesize: parseInt(this.size, 10),
      page: 1,
      moreItems: false,
      masonry: ''
    }
  },
  created() {
    this.loadItems();
  },
  updated() {
    this.loadPresentetionType();
  },
  methods: {

    loadItems() {
      this.isItemsLoading = true
      this.addForm = this.$route.query && this.$route.query.add
      
      API.peopleevents(this.baseurl, this.pagesize || 3, this.page).then(resp => {
        this.isItemsLoading = false

        if (!resp || !resp.Itens) return
        this.moreItems = resp.MoreItems

        this.items = resp.Itens
      })
    },
    loadMore() {
      this.page = this.page + 1
      this.loadItems()
    },
    loadPresentetionType() {
      if(this.iswidget) {
        window.wallSwiper = new Swiper('.carousel-peopleevents .swiper-container', {
          slidesPerView: 1,
          centeredSlides: true,
          loop: false,
          observer: true,
          threshold: 10,
          pagination: {
            el: '.carousel-peopleevents .swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.carousel-peopleevents .swiper-button-next',
            prevEl: '.carousel-peopleevents .swiper-button-prev',
          }
        })

        return
      }
    }
  },
  computed: {
    classObject() {
      return {
        masonry: this.items.length > 3 ? 'flexalign-mosa' : 'flexalign-hor'
        }
    }
  }
}
</script>