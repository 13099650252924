<template>
  <div class="bdays__container col" :id="name">
    <div class="container-fluid carousel-birthdays">
      <div v-if="month">
        <h3><span>{{ $utils.months(lang || 'en')[parseInt(month, 10) - 1] }}</span></h3>
      </div>
      <div v-if="groups.length === 0">
        <div class="row justify-content-center"><div class="col-auto"><div class="loading"><div></div><div></div><div></div></div></div></div>
      </div>
        <div v-else class="row align-items-start">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div v-for="(group, index) in groups" :key="index" class="swiper-slide">
                <div v-for="(item, index) in group" :key="index" class="carousel-birthdays-item">
                  <div class="carousel-birthdays-item__profile">
                    <div class="carousel-birthdays-item__photo">
                      <div class="carousel-birthdays-item__date" v-if="item.today">
                        <div class="carousel-birthdays-item__day">
                          <v-lazy-image src="/assets/intranet/Images/cake.svg" />
                        </div>
                        <div class="carousel-birthdays-item__month">
                          {{ today }}
                        </div>
                      </div>
                      <div class="carousel-birthdays-item__date" v-else>
                        <div class="carousel-birthdays-item__day">
                          {{ (item.day + "").padStart(2, '0') }}
                        </div>
                        <div class="carousel-birthdays-item__month">
                          {{ $utils.months(lang || 'en')[item.month].substr(0, 3) }}
                        </div>
                      </div>
                      <v-lazy-image :src="pictures[item.UsuarioAD] || '/assets/intranet/Images/Profile.svg'" />
                    </div>
                    <div class="carousel-birthdays-item__info">
                      <div>
                        <a v-if="item.UsuarioAD" :href="'./profile?id=' + item.UsuarioAD"><b>{{ item.Nome }}</b></a>
                        <b v-else>{{ item.Nome }}</b>
                      </div>
                      <div class="carousel-birthdays-item__secondary">{{ item.Area }}</div>
                      <div class="carousel-birthdays-item__secondary">{{ item.Empresa }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination d-none"></div>

            <!-- Add Arrows -->
            <div class="swiper-button-next">
              <svg width="17px" height="32px" viewBox="0 0 17 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                <desc>Created with Sketch.</desc>
                <defs></defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="HOME-3" transform="translate(-1517.000000, -2237.000000)" fill="#FFFFFF">
                    <g id="ANIVERSARIANTES" transform="translate(0.000000, 1950.000000)">
                      <path d="M1531.94735,318.643003 L1517.3456,303.843128 C1516.8848,303.376132 1516.8848,302.624138 1517.3456,302.157142 L1531.94735,287.357267 C1532.41315,286.884271 1533.17283,286.881271 1533.64363,287.346267 C1534.11443,287.812263 1534.11942,288.571256 1533.65462,289.043252 L1519.88552,303.000135 L1533.65462,316.957017 C1534.11942,317.429013 1534.11443,318.188007 1533.64363,318.654003 C1533.40973,318.885001 1533.10586,319 1532.80099,319 C1532.49112,319 1532.18225,318.881001 1531.94735,318.643003 Z"
                        id="Icon" transform="translate(1525.500000, 303.000000) scale(-1, 1) translate(-1525.500000, -303.000000) "></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="swiper-button-prev">
              <svg width="17px" height="32px" viewBox="0 0 17 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 50.2 (55047) - http://www.bohemiancoding.com/sketch -->
                <desc>Created with Sketch.</desc>
                <defs></defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="HOME-3" transform="translate(-66.000000, -2237.000000)" fill="#FFFFFF">
                    <g id="ANIVERSARIANTES" transform="translate(0.000000, 1950.000000)">
                      <path d="M80.9473528,318.643003 L66.3456021,303.843128 C65.8847993,303.376132 65.8847993,302.624138 66.3456021,302.157142 L80.9473528,287.357267 C81.4131535,286.884271 82.1728284,286.881271 82.6436269,287.346267 C83.1144254,287.812263 83.1194232,288.571256 82.6546222,289.043252 L68.8855151,303.000135 L82.6546222,316.957017 C83.1194232,317.429013 83.1144254,318.188007 82.6436269,318.654003 C82.409727,318.885001 82.105857,319 81.8009875,319 C81.4911201,319 81.1822523,318.881001 80.9473528,318.643003 Z"
                        id="Icon"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from './api'
import Swiper from 'swiper'
import filters from '../../../services/filters'

export default {
  name: 'bdays',
  props: ['url', 'today', 'name', 'lang', 'month'],
  data() {
    return {
      groups: [],
      pictures: {},
      isDesktop: window.innerWidth >= 992
    }
  },
  mounted() {
    const checkAuth = setInterval(() => {
      if (this.$msal && this.$msal.getAccount()) {
        this.getItems()
        clearInterval(checkAuth)
      }
    }, 100)
  },
  updated() {
    const initialSlide = this.isDesktop ? 2 : 6
    window.bdaySwiper = new Swiper('.carousel-birthdays .swiper-container', {
      initialSlide: this.month ? 0 : initialSlide,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: false,
      threshold: 10,
      pagination: {
        el: '.carousel-birthdays .swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.carousel-birthdays .swiper-button-next',
        prevEl: '.carousel-birthdays .swiper-button-prev',
      }
    })
  },
  methods: {
    getItems() {
      API.bdays(this.url).then(resp => {
        this.groups = this.organizeGroups(resp)

        resp.forEach(p => {
          if (p.UsuarioAD && !this.pictures[p.UsuarioAD]) {
            this.getUserPicture(p.UsuarioAD)
          }
        })
      })
    },
    organizeGroups(data) {
      const groupSize = this.isDesktop ? 9 : 3
      return data.filter(filters.excludedBirthdaysFilter).map(item => {
        const parts = item.Aniversario.split('T')[0].split('-')
        const date = new Date(Date.UTC(parts[0], parseInt(parts[1], 10) - 1, parts[2], 0, 0, 0))
        const today = new Date()
        item.month = date.getUTCMonth()
        item.day = date.getUTCDate()
        item.today = item.month === today.getMonth() && item.day === today.getDate()
        return item
      })
      .map(
        (item, index) => {
          if (index % groupSize === 0) {
            return data.slice(index, index + groupSize)
          }
          return null
        }
      )
      .filter(item => item)
    },
    getUserPicture(name) {
      this.$msalAPI.getFileAPI(`_api/web/GetFileByServerRelativeUrl('/User%20Photos/Profile Pictures/${name.replace(/\./ig, '_')}_${this.$msal.account.userName.split('@')[1].replace(/\./ig, '_')}_LThumb.jpg')/$value`, { encoding: null, responseType: 'arraybuffer' })
        .then(result => {
          const image = `data:image/jpeg;base64,${new Buffer(result.data).toString('base64')}`
          this.$set(this.pictures, name, image)
        }).catch(() => {
          this.$set(this.pictures, name, null)
        })
    }
  }
}
</script>