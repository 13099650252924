<template>
  <form :action="action" method="POST">
    <form-radio v-for="input in formFields"
      :name="input.radio.name"
      :value="input.radio.value"
      :label="input.radio.label"
      :selected="input.radio.selected"
      :disabled="input.radio.disabled"
    ></form-radio>
  </form>
</template>

<script>
export default {
  name: 'form-submit',

  props: {
    action: {
      type: String,
      default: null,
    },
    formFields: {
      type: Array,
      default() { return {} }
    }
  },

  data() {
    return {
      form: {
        radio: {
          name: '',
          value: ''
        }
      }
    }
  },

  created() {
    this.$on('radio-change', (payload) => {
      this.form.radio.name = payload.name
      this.form.radio.value = payload.value
    })
  }
}
</script>