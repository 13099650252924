<template>
  <div class="container-fluid rss">
    <div class="row" v-for="item in items" :key="item.guid">
      <div class="col news-item">
        <div class="news-item__photo">
          <div class="news-item__info">
            <span class="tag"></span>
            <h2>
              <a target="_blank" :href="item.link">{{ item.title }}</a>
            </h2>
            <p>{{ item.contentSnippet }}</p>
            <div class="news-item__date">{{ formatDate(item.isoDate) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../../../../../../node_modules/rss-parser/dist/rss-parser.min'

export default {
  name: 'clippings',
  props: ['url', 'lang'],
  data() {
    return {
      items: []
    }
  },
  mounted() {
    const parser = new window.RSSParser()
    parser.parseURL(this.url).then(resp => {
      this.items = resp.items
    })
  },
  methods: {
    formatDate(date) {
      const d = new Date(date)
      return `${d.getDate()} ${this.$utils.months(this.lang)[d.getMonth()].substr(0, 3)} ${d.getFullYear()}`
    }
  }
}

</script>