<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    v-if="filled"
  >
    <defs>
      <clipPath id="clip_0">
        <rect
          x="-1198"
          y="-35"
          width="1600"
          height="3441"
          clip-rule="evenodd"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#clip_0)">
      <path
        fill="rgb(255,178,5)"
        stroke="none"
        transform="translate(0.0445557 0.720947)"
        d="M3.05742 16L3.23779 14.5553L3.76167 10.2934L0.953298 7.16929L0 6.11286L1.36553 5.84198L5.37628 5.05643L7.34299 1.28217L8.00428 0L8.66559 1.28217L10.6237 5.05643L14.643 5.84198L16 6.11286L15.0467 7.16929L12.2469 10.2844L12.7708 14.5553L12.9426 16L11.6972 15.377L8.00428 13.535L4.31131 15.377L3.05742 16L3.05742 16Z"
      />
    </g>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    v-else
  >
    <defs>
      <clipPath id="clip_0">
        <rect
          x="-1199"
          y="-25"
          width="1600"
          height="3441"
          clip-rule="evenodd"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#clip_0)">
      <path
        :fill="filled ? 'rgb(255,178,5)' : 'rgb(35,172,216)'"
        stroke="none"
        transform="translate(0.0263672 0.141968)"
        d="M3.23779 14.5553L3.05742 16L4.31131 15.377L8.00428 13.535L11.6972 15.377L12.9426 16L12.7708 14.5553L12.2469 10.2844L15.0467 7.16929L16 6.11286L14.643 5.84198L10.6237 5.05643L8.66559 1.28217L8.00428 0L7.34299 1.28217L5.37628 5.05643L1.36553 5.84198L0 6.11286L0.953298 7.16929L3.76167 10.2934L3.23779 14.5553ZM7.61307 12.343L4.29062 14L4.76525 10.1661L4.81684 9.72203L4.52794 9.38628L2 6.58123L5.61135 5.87725L6.03439 5.79061L6.24075 5.38989L8.00515 2L9.76956 5.38989L9.97592 5.79061L10.399 5.87725L14 6.58123L11.4824 9.38628L11.1832 9.72203L11.2451 10.1661L11.7197 14L8.39724 12.343L8.00515 12.148L7.61307 12.343Z"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'star-icon',
    props: {
      filled: { type: Boolean, default: false },
    },
  }
</script>
