<template>
  <component :is="elTag">
    <b v-if="title">{{ title }}</b>
    <ul>
      <topnav-anchor
        v-for="item in items"
        :key="item.Name"
        el-tag="li"
        :id="item.Id"
        :title="item.DisplayNameOrName"
        :url="item.Link ? item.Link.Url : ''"
        :target="item.Link ? item.Link.Target : ''"
        :is-new="item.IsNew"
        :is-favorite="item.Favorite"
        :children="item.Items"
        :favorite-fn="favoriteFn"
        @open="handleSubmenuOpen"
        ref="submenus"
      />
    </ul>
  </component>
</template>

<script>
  export default {
    name: 'topnav-navigation-group',
    props: {
      elTag: { type: String, default: 'div' },
      title: { type: String },
      items: { type: Array, default: () => [] },
      favoriteFn: { type: Function },
    },
    methods: {
      favorite(id, evt) {
        this.$emit('favorite', id, evt)
        if (this.favoriteFn) this.favoriteFn(id, evt)
      },
      handleSubmenuOpen(childId) {
        const submenusToClose = this.items
          .filter((item) => item.Id !== childId)
          .map((item) => item.Id)
        this.closeSubmenus(submenusToClose)
      },
      closeAll() {
        this.closeSubmenus(this.items.map((item) => item.Id))
      },
      closeSubmenus(submenusIds) {
        this.$refs.submenus
          .filter(
            (menu) => menu.expandSubmenu && submenusIds.indexOf(menu.id) >= 0
          )
          .forEach((menu) => {
            menu.toggleSubmenu(false)
          })
      },
    },
  }
</script>
