//
// Initialize
//

document.querySelector('body').addEventListener('click', (e) => {
  if (e.target.getAttribute('rel') && e.target.getAttribute('rel') === 'navigation') {
    document.querySelector('html').classList.remove('phone-open')
    document.querySelector('html').classList.toggle('topnav-open')
  }
  if (e.target.getAttribute('rel') && e.target.getAttribute('rel') === 'phones') {
    document.querySelector('html').classList.remove('topnav-open')
    document.querySelector('html').classList.toggle('phone-open')
  }
})
