import Axios from 'axios'

const API = {
  options: {
    urlBase: '/assets/intranet/api/sitecore/intranet/Like',
    urlBaseGetLikes: '/assets/intranet/api/sitecore/intranet/GetLikes',
    urlGetPerson: '/assets/intranet/api/sitecore/profile/getperson'
  },
  like(urlBase, idnoticia) {
    return Axios.post(urlBase || this.options.urlBase, idnoticia).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.like()', error)
    })
  },
  getLikes(urlBaseGetLike, itemId) {
    return Axios.get(`${urlBaseGetLike || this.options.urlBaseGetLikes}?itemId=${itemId}`).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.getLikes()', error)
    })
  },
  profile(url, username) {
    return Axios.get(`${url || this.options.urlGetPerson}?username=${username}`).then((response) => {
      return response.data
    }).catch((error) => {
      console.error('API.profile()', error)
    })
  }
}

export default API
