<template> 
  <div class="container profile">
    <div class="row" v-if="!changePhotoMode">
      <div class="col" v-if="profile && !isProfileLoading">
        <div class="profile__container">
          <div class="profile__edit" v-if="!$utils.qs('id')">
            <label v-if="!uploading" for="file" class="button">
              <i class="fa fa-camera"></i> {{ locedit || 'Editar Foto' }}
              <input id="file" type="file" accept=".jpeg, .jpg, .png" hidden @change="onChangePhoto"/>
            </label>
            <div v-else class="loading loading--invert"><div></div><div></div><div></div></div>
          </div>
          <div class="profile__header">
            <div class="profile__sidebar">
              <div class="profile__picture">
                <v-lazy-image :src="photo" />
              </div>
            </div>
            <div>
              <h1>
                {{ profile.DisplayName }}
              </h1>
              <h2>{{ profile.Title }}</h2>
              <h3>
                <span>{{ $utils.profileProp(profile, 'Department') }}</span>
                <span>{{ $utils.profileProp(profile, 'SPS-Location') }}</span>
              </h3>
              <h3>
                <span v-if="extendedProfile"><b>{{ extendedProfile.Empresa }}</b></span>
              </h3>
              <div class="profile__lists">
                <div class="profile__list">
                  <ul>
                    <li v-if="$utils.profileProp(profile, 'UserName')">
                      <span><i class="fa fa-envelope"></i> <a :href="`mailto:${$utils.profileProp(profile, 'UserName')}`">{{ $utils.profileProp(profile, 'UserName') }}</a></span>
                    </li>
                    <li>
                      <span v-if="$utils.profileProp(profile, 'WorkPhone')">
                        <i class="fa fa-phone"></i> 
                        <a :href="`tel:${$utils.profileProp(profile, 'WorkPhone')}`">
                          {{ $utils.profileProp(profile, 'WorkPhone') }}
                        </a>
                      </span>
                      &nbsp;
                      <span v-if="$utils.profileProp(profile, 'CellPhone')">
                        <i class="fa fa-mobile" style="font-size: 18px"></i> 
                        <a :href="`tel:${$utils.profileProp(profile, 'CellPhone')}`">{{ $utils.profileProp(profile, 'CellPhone') }}</a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="profile__details">
            <div class="profile__section" v-if="$utils.profileProp(profile, 'AboutMe')">
              <i class="fa fa-at"></i>
              <h2>About me</h2>
              <span v-html="$utils.profileProp(profile, 'AboutMe')"></span>
            </div>
            <div class="profile__section" v-if="$utils.profileProp(profile, 'SPS-Skills')">
              <i class="fa fa-briefcase"></i>
              <h2>Experience</h2>
              <span v-html="$utils.profileProp(profile, 'SPS-Skills')"></span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!profile && !isProfileLoading">
        User not found.
      </div>
    </div>
    <div class="row" v-else>
      Change photo
    </div>
  </div>

</template>

<script>

import API from './api'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'profile',
  props: [
    'locaboutme',
    'locexperience',
    'locedit',
    'tenant',
    'url'
  ],
  data() {
    return {
      isProfileLoading: true,
      profile: null,
      extendedProfile: null,
      returnUrl: encodeURIComponent(window.location.href),
      changePhotoMode: false,
      uploading: false,
      photo: null
    }
  },
  mounted() {
    if (this.$msal && this.$msal.getAccount()) {
      const username = this.$utils.qs('id') || this.$msal.account.userName.split('@')[0]
      API.profile(this.url, username).then(profile => {
        this.extendedProfile = profile
        const email = (profile != null && profile.Email != null) ? profile.Email : `${username}@${this.tenant}`
        this.$msalAPI.getDataAPI(`_api/sp.userprofiles.peoplemanager/getpropertiesfor(@v)?@v='i%3A0%23.f%7Cmembership%7C${email}'`)
          .then(resp => {
            this.isProfileLoading = false

            if (resp.data.d['odata.null']) return

            this.changeprofile(resp.data.d)

            if (username) {
              if (resp.data.d.PictureUrl) {
                const uri = `_api/web/GetFileByServerRelativeUrl('/User%20Photos/${resp.data.d.PictureUrl.split('User%20Photos/')[1].split('?')[0].replace('MThumb', 'LThumb')}')/$value`
                this.$msalAPI.getFileAPI(uri)
                  .then(result => {
                    this.photo = `data:image/jpeg;base64,${new Buffer(result.data).toString('base64')}`
                  })
              } else {
                this.photo = '/assets/intranet/Images/Profile.svg'
              }
            } else {
              this.photo = this.theimage
            }
            this.profile = resp.data.d
          })
      })
    }
  },
  computed: {
    ...mapGetters('profile', [
      'theimage'
    ])
  },
  methods: {
    ...mapActions('profile', [
      'change',
      'changeimage',
      'changeprofile'
    ]),
    onChangePhoto() {
      const self = this
      this.uploading = true
      const headers = { accept: 'application/json;odata=verbose' }
      const api = '_api/sp.userprofiles.peoplemanager/setmyprofilepicture'
      const file = document.querySelector('input[type=file]').files[0]

      this.createImg(true, file).then((imgbuffer) => {
        this.$msalAPI.postFileAPI(api, imgbuffer, headers).then(() => {
          this.createImg(false, file)
          .then((imgdataurl) => {
            self.photo = imgdataurl
            self.changeimage(imgdataurl)
            self.uploading = false
          })
        }).catch(() => {
          window.console.warn('error: Invalid image.')
          self.uploading = false
        })
      })
    },
    createImg(buffer, file) {
      return new Promise((resolve) => {
        const reader = new FileReader()

        reader.onload = (event) => {
          return resolve(event.target.result)
        }

        if (buffer) {
          reader.readAsArrayBuffer(file)
        } else {
          reader.readAsDataURL(file)
        }
      })
    }
  }
}
</script>
