//
// Initialize
//

document.querySelector('body').addEventListener('click', (e) => {
  if (e.target && e.target.classList && e.target.classList.contains('file')) {
    e.stopPropagation()
    e.target.classList.add('active')
    document.querySelector('html').classList.add('open-term')
    e.preventDefault()
  }
  if (e.target.getAttribute('data-type') && e.target.getAttribute('data-type') === 'cancel') {
    e.stopPropagation()
    document.querySelector('.file.active').classList.remove('active')
    document.querySelector('html').classList.remove('open-term')
    e.preventDefault()
  }
})
