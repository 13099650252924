<template>
<div>
   <div v-if="isDesktop" class="filter-departament filter-diver hidden-sm">
        <slot name="title-departament"></slot>
        <ul v-bind:class="{ active: vdepartament }">
            <li v-for="(itemDepartamento, index) in Area" :key="index">
            <div @click="addRemoveItem('Area', itemDepartamento.Area)">{{itemDepartamento.Area}}</div> 
            </li>
        </ul>
        <span @click="showDepartaments"> {{ buttonMoreDepartament }} </span>
    </div>

    <div v-else class="filter-departament filter-diver">
        <ul v-bind:class="{ active: vdepartament }">
            <li class="js-itemlistfilter" v-for="(itemDepartamento, index) in Area" :key="index">
            <div @click="addRemoveItem('Area', itemDepartamento.Area)">{{itemDepartamento.Area}}</div> 
            </li>
        </ul>
        <span @click="showDepartaments"> {{ buttonMoreDepartament }} </span>
    </div>
</div>
</template>
<script>

export default {
  name: 'new-bdays-departament',
  props: ['isDesktop', 'Area', 'showmore', 'hidemore'],
  data() {
    return {
      buttonMoreDepartament: this.showmore,
      vdepartament: false
    }
  },
  methods: {
    showDepartaments() {
      if (this.vdepartament === true) {
        this.vdepartament = false
        this.buttonMoreDepartament = this.showmore
      } else {
        this.vdepartament = true
        this.buttonMoreDepartament = this.hidemore
      }
    },
    addRemoveItem(paramField, paramValueSelect) {
      this.$emit('searchDepartament', {
        field: paramField,
        valueSelect: paramValueSelect
      })
    }
  }
}
</script>