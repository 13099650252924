<template>
  <div v-if="token || isLoginPage">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'msal-component',
  computed: {
    ...mapGetters('msalStore', [
      'token'
    ]),
    isLoginPage() {
      return window.location.href.toLowerCase().indexOf('/login') > -1
    }
  },
  mounted() {
    if (!this.isLoginPage) {
      this.updateMSAL()
    }
  },
  methods: {
    ...mapActions('msalStore', [
      'changeToken'
    ]),
    updateMSAL() {
      if (this.$msal.getAccount()) {
        const tokenRequest = {
          scopes: [`${window.adalInfo.mysite}/.default`]
        }
        this.$msal.acquireTokenSilent(tokenRequest)
          .then(response => {
            this.changeToken(response.accessToken)
            window.localStorage.setItem('msal-token', response.accessToken)
          })
          .catch(() => {
            this.$msal.acquireTokenRedirect(tokenRequest)
              .then(response => {
                this.changeToken(response.accessToken)
                window.localStorage.setItem('msal-token', response.accessToken)
              })
              .catch(acquireTokenPopupError => {
                window.console.log('acquireTokenRedirect Error', acquireTokenPopupError)
              })
          })
      } else {
        this.signIn()
      }
    },
    async signIn() {
      await this.$msal
        .loginRedirect({})
        .catch(error => {
          window.console.error(`error during authentication: ${error}`)
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>