/* eslint-disable */

import 'url-search-params-polyfill'

import _get from 'lodash/get'
import _set from 'lodash/set'
import _reduce from 'lodash/reduce'
import _find from 'lodash/find'
import _forEach from 'lodash/forEach'
import _isEqual from 'lodash/isEqual'

const utils = {
  /**
   * Validate if string is an email
   * @param {String} value
   * @returns {boolean}
   */
  isValiedEmail(value) {
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(value)
  },

  /**
   *
   * ate if the string is a valid postal code (Canada)
   * @param {String} value
   * @returns {boolean}
   */
  isValidPostalCode(value, strict = false) {
    const soft = /^(?![A-Za-z]{2,})([A-Za-z]\d?(?![A-Za-z]{2,})){1,3}$/
    const hard = /^[A-Za-z]\d[A-Za-z]\s\d[A-Za-z]\d$/

    if (strict) {
      return hard.test(value)
    }

    return value ? soft.test(value) : true
  },

  /**
   * Validate if string is a valid password (min 8 chars, numbers, letters, or symbols)
   * @param {String} value
   * @returns {boolean}
   */
  isValidPassword(value) {
    // eslint-disable-next-line
    const regex = /^(?=.*?[a-zA-Z])(?=.*?[0-9#?!@$%^&*-]).{8,}$/
    return regex.test(value)
  },

  /**
   * Check if string is empty
   * @param string
   * @returns {boolean}
   */
  isEmptyString(string) {
    return string.replace(/ /g, '').length === 0
  },

  /**
   * Get cookie by name
   * @param {String} name
   * @returns {*}
   */
  getCookie(name) {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=')
      return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
  },

  /**
   * Set cookie
   * @param {String} name
   * @param {String} value
   * @param {Number} days
   * @param {String} path
   */
  setCookie(name, value, days = 7, path = '/') {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=${path}`
  },

  /**
   * Check if esc key was pressed
   * @param e
   * @returns {boolean}
   */
  isEscKey(e) {
    return e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27
  },

  /**
   * Will toggle the css class
   * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11865865/
   * @param {Element} element
   * @param {String} className
   * @param {Boolean} force
   */
  toggleClass(element, className, force) {
    if (!element) {
      return
    }

    if (force) {
      element.classList.add(className)
    } else {
      element.classList.remove(className)
    }
  },
  
  profileProp(properties, name) {
    if (properties && properties.UserProfileProperties) {
      return properties.UserProfileProperties.results
        .filter(prop => prop.Key.toLowerCase() === name.toLowerCase())
        .map(res => res.Value)
          .join()
    }
  },

  getProp(properties, name) {
    const data = properties['UserProfileProperties']

    if (!data) {
      return null
    }

    return data.results.filter(prop => prop.Key.toLowerCase() === name.toLowerCase())
      .map(res => res.Value)
      .join()
  },

  qs(name, source) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]"); var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(source || location.search); 
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  },
  
  months(lang) {
    return lang === 'en' 
      ? ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      : ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  },

  token() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  },
  /**
   * Will apply mask to given text
   * Example: (XX) XXXX-XXXX
   *
   * @param {T} text
   * @param {String} mask
   * @returns {String}
   */
  applyMask(text, mask) {
    const textVals = `${text}`.replace(/\s/g, '').replace(/X/g, '?').split('')
    const maskVals = mask.match(/X/g)

    if (maskVals && textVals.length !== maskVals.length) {
      mask = text.toString()
        .replace(/ /g, '')
        .replace(/./g, 'X')
        .replace(/(\S)(?=(\S{3})+\b)/g, '$1 ')
        .replace(/a$/, '')
        .trim()
      // return text
    }

    let result = mask

    textVals.forEach((value) => {
      result = result.replace(/X/, value)
    })

    result = result.replace(/\?/g, 'X')

    return result
  },

  /**
   * Remove the provided text mask
   * @param {T} text
   * @param {String} mask
   * @returns {String}
   */
  clearMask(text, mask) {
    let reg = ''

    reg = mask.replace(/X/g, '').split('').join('|\\')
    if (reg === '') {
      reg = ' '
    }
    reg = `\\${reg}`
    reg = new RegExp(reg, 'g')

    return `${text}`.replace(reg, '')
  },

  /**
   * Validate if number is a valid between interval
   * @param {String} value
   * @param {Number} valueMin
   * @param {Number} valueMax
   * @returns {boolean}
   */
  isValidNumber(value, min, max, decimal) {
    let isValid = true
    let isValidMin = true
    let isValidMax = true
    let val = value
    const lang = document.documentElement.lang

    if (lang === 'fr-FR') {
      const vvalue = `${value}`
      const regex = /^\d{1,}[,]?\d{0,2}$/
      if (!(regex).test(val)) {
        isValid = false
        return { valid: isValid, min: isValidMin, max: isValidMax }
      }
      value = vvalue.replace(',', '.')
    } else if (lang === 'en') {
      const regex = /^\d{1,}[.]?\d{0,2}$/
      if (!(regex).test(val)) {
        isValid = false
        return { valid: isValid, min: isValidMin, max: isValidMax }
      }
    }

    let mmin = `${min}`
    mmin = mmin.replace(',', '.')
    let mmax = `${max}`
    mmax = mmax.replace(',', '.')

    if (typeof value === 'string') {
      val = parseFloat(value.trim())
    }

    if (isNaN(val)) {
      isValid = false
    } else if (!decimal && !(/^\d{1,}$/).test(val)) {
      isValid = false
    } else if (!(/^\d{1,}[.]?\d{0,2}$/).test(val)) {
      isValid = false
    } else if (mmin >= 0 && mmax >= 0) {
      if (val < parseFloat(mmin)) {
        isValidMin = false
        isValid = false
      }
      if (val > parseFloat(mmax)) {
        isValidMax = false
        isValid = false
      }
    }

    return { valid: isValid, min: isValidMin, max: isValidMax }
  },

  /**
   * Check if all fields in a form are valid and not-empty
   * @param {Object} - form
   * @returns {boolean} - is form valid
     */
    isFormValid(form) {
      // Assume form valid by default
      let isValid = true
  
      // If any field empty, or invalid, then form is invalid
      // below is IE support for missing Object.values(this.form)
      const formObject = Object.keys(form).map((field) => {
        return form[field]
      })
  
      formObject.forEach((field) => {
        if (field.type !== 'number' && field.value.trim().length === 0 || !field.isValid) {
          isValid = false
        }
  
        if (field.type === 'number' && field.amountMin && field.amountMax) {
          isValid = this.isValidNumber(field.value, field.amountMin, field.amountMax)
        }
      })
  
      return isValid
    },

  valueHelper(value) {
    const m = value.replace(' ', '')
    .replace(',', '')
    .replace('.', '')
    .replace(' ', '')
    .replace('$', '')
    .replace(')', '')
    .replace('(', '-')
    .replace(' ', '')
    .trim()
    return parseFloat(m)
  },

  get(object, path, surrogate) {
    return _get(object, path, surrogate)
  },

  set(object, path, value) {
    return _set(object, path, value)
  },

  reduce(collection, iteratee, accumulator) {
    return _reduce(collection, iteratee, accumulator)
  },

  find(collection, predicate) {
    return _find(collection, predicate)
  },

  forEach(collection, iteratee) {
    return _forEach(collection, iteratee)
  },

  isEqual(value, ...others) {
    return !!others.find((other) => {
      return _isEqual(value, other)
    })
  },
  cropText(text, maxSize, stringAfter){
    return text.length > maxSize ? text.substr(0, maxSize) + (stringAfter || '') : text
  }
}

export default utils
