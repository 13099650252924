<template>
<div class="form-element">
    <div class="input-group" v-if="type !== 'hidden'">
        <div class="input-group--label">
          <label v-html="label" :aria-label="ariaLabel">&nbsp;</label>
        </div>
        <div class="input-group-elements">
          <span v-if="prefield" class="prefield" v-html="prefield"></span>
          <input
            type="text"
            class="inputText"
            :class="{ 'error': !valid, 'secondary': secondary }"
            :name="name"
            :id="name"
            :disabled="disabled"
            :placeholder="placeholder"
            :aria-label="ariaLabel"
            :aria-labelledby="ariaLabel"
            :value="setMask(value)"
            v-on:keypress="isNumber"
            :size="fieldsize"
            :maxlength="maxLength"
            v-on:input="onInput"
          />
          <span class="input-tooltip" :class="{'show': this.showTooltip}" v-html="this.dynamicTooltip"></span>
          <span class="placehholder-icon" :class="{'show': valid}">
            <svg class='icon is-medium iconregular'><use xmlns:xlink='http://www.w3.org/1999/xlink' :xlink:href="'#' + placeholderIcon"></use></svg>
            <svg class='icon is-medium errornumber'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href="#icon-exclamation"></use></svg>
          </span>
          <span class="placehholder-error" :class="{'show': !valid}">
            <svg class='icon is-medium'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='#icon-exclamation'></use></svg>
          </span>
        </div>
      <span class="error" :class="{'show': !valid, 'secondary': secondary}">
        {{ errorText }}
      </span>
    </div>
  </div>
</template>

<script>
  import Mask from '../../../utils/mask'
  import Util from '../../../utils/utils'

  export default {
    name: 'form-input',
    props: {
      name: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: 'text'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: ''
      },
      errorText: {
        type: String,
        default: ''
      },
      maxlength: {
        type: String
      },
      value: {
        type: String
      },
      placeholder: {
        type: String
      },
      required: {
        type: Boolean
      },
      mask: {
        type: String
      },
      clearMask: {
        type: Boolean,
        default: true
      },
      validation: {
        type: Object
      },
      validate: {
        type: Boolean,
        default: false
      }
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    computed: {
      valid() {
        if (!this.validation) {
          return true
        }

        if (this.validate) {
          return Util.get(this.validation.$valid, this.name)
        }

        if (Util.get(this.validation.$dirty, this.name)) {
          return Util.get(this.validation.$valid, this.name)
        }

        return true
      }
    },
    methods: {
      /* eslint-disable */
      setMask(value) {
        if (this.mask && this.clearMask) {
          return Mask.apply(value, this.mask)
        }
        if (this.mask && !this.clearMask) {
          return Mask.apply(Mask.clear(value, this.mask), this.mask)
        }
        return value
      },
      isNumber(event) {
        if (this.type === 'number' && !(/\d{1,}/g).test(event.key)) {
          event.preventDefault();
          return;
        }
      },
      onInput(event) {
        if (this.mask && this.clearMask) {
          this.$emit('input', Mask.clear(event.target.value, this.mask))
          return
        }
        this.$emit('input', event.target.value)
      }
      /* eslint-enable */
    }
  }
</script>
